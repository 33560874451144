import React, { useState, useEffect } from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdEmail,
  MdCategory,
  MdDashboard,
  MdCardMembership,
   
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import Memberships from 'views/admin/memberships';
import Dashboard from 'views/admin/default';
import Categories from 'views/admin/categories';
import CategoryDetails from 'views/admin/categories/CategoryDetails';
import LinkedInCallback from 'views/auth/LinkedInCallback';
import AdDetails from 'views/ads/adDetails';
import Shop from 'views/shop';
import UpdateShop from 'views/shop/update';
import DashboardShop from 'views/shop/dashboard';
import Shops from 'views/admin/shops';
import CreateShop from 'views/shop/create';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import Confirmation from 'views/auth/Confirmation';
import Licence from 'views/infos/Licence';
import Contact from 'views/infos/contact';
import TermOfUse from 'views/infos/TermOfUse';
import Mentions from 'views/infos/Mentions';
import ConfirmRequest from 'views/auth/ConfirmRequest';
import PasswordReset from 'views/auth/PasswordReset';
import ForgotPassword from 'views/auth/ForgotPassword';
import RegisterCentered from 'views/register/Register';
import RegisterForm from 'views/register/RegisterForm';
import UpdateUser from 'views/register/UpdateUser';
import CreateAd from 'views/ads/createAd';
import UpdateAd from 'views/ads/updateAd';
import RecentAds from 'views/ads/recentAds';
import Search from 'views/search';
import Order from 'views/checkout/order';
import ShopOrder from 'views/shop/order';
import Success from 'views/checkout/paypal/success';
import Cancel from 'views/checkout/paypal/cancel';
import SuccessCMI from 'views/checkout/cmi/success';
import CancelCMI from 'views/checkout/cmi/cancel';
import SuccessShop from 'views/shop/paypal/success';
import CancelShop from 'views/shop/paypal/cancel';
import SuccessShopCMI from 'views/shop/cmi/success';
import Chat from 'views/admin/chat';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { t } from "helpers/TransWrapper";
const MAX_ADS_ALLOWED = 10; 
const userToken = JSON.parse(localStorage.getItem('user-token'));



let combinedRoutes ;
const routes = [

  {
    name: t('Marketplace'),
    layout: '/app',
    path: '/home',
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" mt={1} />
    ),
    component: NFTMarketplace,
  },

  {
    name: t(`Détails de l'annonce`) ,
    layout: '/ads',
    path: '/:id',

    component: AdDetails,
  },
  {
    name: ({ match }) => {
      // Assuming you have access to the shop name from the URL params
      const shopName = match.params.name;
      // Logic to format or process the shopName if needed
      return `Shop - ${shopName}`;
    },
    layout: '/shop',
    path: '/shop/:name',
    component: Shop,
  },
  {
    name: t('Boutiques Jindoo'),
    layout: '/app',
    path: '/shops',
    icon: (
      <Icon
        as={MdOutlineShoppingCart }
        width="20px"
        height="20px"
        color="inherit"
        mt={1}
      />
    ),
    component: Shops,
    badge : 'new'
  },
  {
    name: t('Ajoutées récemment'),
    layout: '/ad',
    path: '/recentAds',

    component: RecentAds,
  },
  {
    name: t('Recherche avancée'),
    layout: '/search',
    path: '/',

    component: Search,
  },
  {
    name: t('Recherche avancée'),
    layout: '/search',
    path: '?text=:text',

    component: Search,
  },
  {
    name: 'Conditions générales de vente',
    layout: '/infos',
    path: '/licence',

    component: Licence,
  },
  {
    name: 'Contact',
    layout: '/infos',
    path: '/contact',

    component: Contact,
  },
  {
    name: `Termes d'utilisation`,
    layout: '/infos',
    path: '/termofuse',

    component: TermOfUse,
  },
  {
    name: `Mentions Légales`,
    layout: '/infos',
    path: '/mentions',

    component: Mentions,
  },

  {
    name: t('Catégories'),
    layout: '/app',
    path: '/categories',
    icon: (
      <Icon as={MdCategory} width="20px" height="20px" color="inherit" mt={1} />
    ),
    component: Categories,
  },
  {
    name: t('Catégories'),
    layout: '/categories',
    path: '/category/:id',
    component: CategoryDetails,
  },
  {
    
    layout: '/auth',
    path: '/linkedIn-callback',
    component: LinkedInCallback,
  },
];
  

const adminRoutes = [      
  {
  name: t('Dashboard Jindoo'),
  layout: '/app',
  path: '/dashboard',
  icon: (
    <Icon
      as={MdDashboard}
      width="20px"
      height="20px"
      color="inherit"
      mt={1}
    />
  ),
  component: Dashboard,
},
  {
    name: t('Profile'),
    layout: '/app',
    path: '/profile',
    icon: (
      <Icon
        as={MdPerson}
        width="20px"
        height="20px"
        color="inherit"
        mt={1}
      />
    ),
    component: Profile,
  },

  {
    name: t('Abonnements'),
    layout: '/app',
    path: '/memberships',
    icon: (
      <Icon
        as={MdCardMembership}
        width="20px"
        height="20px"
        color="inherit"
        mt={1}
      />
    ),
    component: Memberships,
    badge : 'new'
  },


  {
    name: t('Modifier mon profile'),
    component: UpdateUser,
    layout: '/register',
    path: '/updateUser',
  },
  {
    name: t('Modifier ma boutique'),
    component: UpdateShop,
    layout: '/shop',
    path: '/update/:name',
  },
  {
    name: t('Tableau de bord Boutique'),
    component: DashboardShop,
    layout: '/shop',
    path: '/dashboard/:name',
  },
  {
  
    name: 'Créer ma boutique',

    path: '/create',
    layout: '/shop',
    component: CreateShop,
  
},
  {
    name: t('Annonce') ,
    component: CreateAd,
    layout: '/ad',
    path: '/createAd',
  },
  {
    name: t('Modifier Annonce'),
    component: UpdateAd,
    layout: '/ad',
    path: '/updateAd/:id',
  },
  {
    name: t('Commande') ,
    component: Order,
    layout: '/ad',
    path: '/order/:id',
  },
  {
    name: t('Commande Magasin') ,
    component: ShopOrder,
    layout: '/ad',
    path: '/shoporder/:id',
  },
  {
    
    component: Success,
    layout: '/ad',
    path: '/paypal/success',
  },
  {
    
    component: Cancel,
    layout: '/ad',
    path: '/paypal/cancel',
  },
  {
    
    component: SuccessCMI,
    layout: '/ad',
    path: '/payzone/success',
  },
  {
    
    component: CancelCMI,
    layout: '/ad',
    path: '/payzone/cancel',
  },
  {
    
    component: SuccessShop,
    layout: '/shop',
    path: '/paypal/success',
  },
  {
    
    component: SuccessShopCMI,
    layout: '/shop',
    path: '/cmi/success',
  },
  {
    
    component: CancelShop,
    layout: '/shop',
    path: '/paypal/cancel',
  },
  
  {
    name: t('Boite de messagerie'),
    icon: (
      <Icon
        as={MdEmail}
        width="20px"
        height="20px"
        color="inherit"
        mt={1}
      />
    ),
    component: Chat,
    layout: '/chat',
    path: '/chat',
  },
]
const regularUserRoutes = [    
  {
    name: t('Profile'),
    layout: '/app',
    path: '/profile',
    icon: (
      <Icon
        as={MdPerson}
        width="20px"
        height="20px"
        color="inherit"
        mt={1}
      />
    ),
    component: Profile,
  },

  {
    name: t('Abonnements'),
    layout: '/app',
    path: '/memberships',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
        mt={1}
      />
    ),
    component: Memberships,
    badge : 'new'
  },


  {
    name: t('Modifier mon profile'),
    component: UpdateUser,
    layout: '/register',
    path: '/updateUser',
  },

  {
    name: t('Modifier ma boutique'),
    component: UpdateShop,
    layout: '/shop',
    path: '/update/:name',
  },
  {
  
    name: t('Créer ma boutique'),

    path: '/create',
    layout: '/shop',
    component: CreateShop,
  
},
  {
    name: t('Tableau de bord Boutique'),
    component: DashboardShop,
    layout: '/shop',
    path: '/dashboard/:name',
  },
  {
    name: t('Annonce'),
    component: CreateAd,
    layout: '/ad',
    path: '/createAd',
  },
  {
    name: t('Modifier Annonce'),
    component: UpdateAd,
    layout: '/ad',
    path: '/updateAd/:id',
  },
  {
    name: t('Commande') ,
    component: Order,
    layout: '/ad',
    path: '/order/:id',
  },
  {
    name: t('Commande Magasin') ,
    component: ShopOrder,
    layout: '/ad',
    path: '/shoporder/:id',
  },
  {
    
    component: Success,
    layout: '/ad',
    path: '/paypal/success',
  },
  {
    
    component: Cancel,
    layout: '/ad',
    path: '/paypal/cancel',
  },
  {
    
    component: SuccessCMI,
    layout: '/ad',
    path: '/payzone/success',
  },
  {
    
    component: CancelCMI,
    layout: '/ad',
    path: '/payzone/cancel',
  },
  {
    
    component: SuccessShop,
    layout: '/shop',
    path: '/paypal/success',
  },
  {
    
    component: SuccessShopCMI,
    layout: '/shop',
    path: '/cmi/success',
  },
  {
    
    component: CancelShop,
    layout: '/shop',
    path: '/paypal/cancel',
  },
  {
    name: t('Boite de messagerie'),
    icon: (
      <Icon
        as={MdEmail}
        width="20px"
        height="20px"
        color="inherit"
        mt={1}
      />
    ),
    component: Chat,
    layout: '/chat',
    path: '/chat',
  },]
const guestRoutes = [{


    name: t('Connexion'),
    layout: '/auth',
    path: '/sign-in',
    icon: (
      <Icon
        as={MdLock}
        width="20px"
        height="20px"
        color="inherit"
        mt={1}
      />
    ),
    component: SignInCentered,
  },

  {
    name: 'Confirmation inscription',

    path: '/confirm/:token',
    layout: '/register',
    component: Confirmation,
  },
  {
    name: 'Confirmation request',
    layout: '/register',

    path: '/confirmRequest',

    component: ConfirmRequest,
  },
  {
    name: 'Password Reset',
    layout: '/register',

    path: '/reset-password/:token',

    component: PasswordReset,
  },
  {
    name: 'Mot de passe perdu',
    layout: '/register',

    path: '/forgot-password',

    component: ForgotPassword,
  },
  {
    component: RegisterForm,
    layout: '/register',
    path: '/registerForm',
  },]

const proRoutes=[{
  
    name: 'Créer ma boutique',

    path: '/create',
    layout: '/shop',
    component: CreateShop,
  
}]

if (userToken) {
  if (userToken.isAdmin && userToken.ProPart === 'pro') {
    combinedRoutes = [...routes, ...adminRoutes, ...proRoutes];
  } else if (userToken.isAdmin) {
    combinedRoutes = [...routes, ...adminRoutes];
  } else {
    combinedRoutes = [...routes, ...regularUserRoutes];
    // Check if the user is a 'pro' user and add 'proRoutes'
    if (userToken.ProPart === 'pro') {
      combinedRoutes = [...combinedRoutes, ...proRoutes];
    }
  }
} else {
  combinedRoutes = [...routes, ...guestRoutes];
}
export default combinedRoutes;