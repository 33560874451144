// chakra imports
import {
    Icon,
    Flex,
    Text,
    useColorModeValue,
    Avatar,
    AvatarBadge,
    Box
  } from '@chakra-ui/react';
  import { MdUpgrade } from 'react-icons/md';
  import React, { useEffect, useState } from 'react';
  import axios from 'axios';
  import { t } from "helpers/TransWrapper";

  export function ConversationsMobile(props) {
    const { Click, friendId, conversation, textColor, modeText,status,notifUsers } = props;
    const [user, setUser] = useState(null);
    const [lastMessage, setLastMessage] = useState('');
    const [userStatus, setUserStatus] = useState('offline'); // Initialize with 'offline'
  
  
    useEffect(() => {
      const getUser = async () => {
        try {
          const res = await axios(
            `${process.env.REACT_APP_API}/users/${friendId}`
          );
          setUser(res.data);
          const friendStatusInfo = notifUsers.find(
            user => user.userId === friendId
          );
          setUserStatus(
            friendStatusInfo ? friendStatusInfo.status : 'Hors ligne'
          );
          console.log("USER STATUS",userStatus,"FRIEND ID ",friendId );
        } catch (err) {}
      };
  
      getUser();
    }, [friendId,notifUsers]);
  
    useEffect(() => {
      const getMessages = async () => {
        try {
          const messages = await axios.get(
            ` ${process.env.REACT_APP_API}/messages/` + conversation?._id,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          const messagesConv = messages.data;
  
  
         
         const lastMessageConv = messagesConv.length > 0 ? messagesConv[messagesConv.length - 1].text : null;
           setLastMessage(lastMessageConv);
  
        } catch (err) {}
      };
      getMessages();
    }, [conversation]);
  
  
    return (
      <>
        <Box onClick={Click} cursor="pointer">
          <Flex justify="center" align="center" mr={3}>
            <Avatar
              src={user?.profilePicture}
              color="white"
              bg="#11047A"
              size="lg"
              w="60px"
              h="60px"
            >        <AvatarBadge boxSize="0.8em" bg={userStatus ==='connected' ? "green.500" : "gray.500"} />
  </Avatar>
          </Flex>
          <Flex flexDirection="column" alignItems="start">
          {user ? (
            <Box textAlign="center">    
            <Text
              color={textColor}
              fontWeight={modeText}
              fontSize={{ base: 'sm', md: 'md' }}
            >
              {user.firstName} {user.lastName}
            </Text>
     
            </Box>
        
          ) : (
            <Text
              mt={2}
              fontWeight="bold"
              color={textColor}
              fontSize={{ base: 'sm', md: 'md' }}
            >
              {t('Loading ...')}
            </Text>
          )}
        </Flex>
        </Box>
      </>
    );
  }
  