import React , {useState} from "react";
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';

// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  Input,
  Button
} from "@chakra-ui/react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdOutlinePerson,
  MdOutlineCardTravel,
  MdOutlineLightbulb,
  MdOutlineSettings,
} from "react-icons/md";
import { t } from "helpers/TransWrapper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Banner(props) {
  const { ...rest } = props;
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const deleteText = useColorModeValue("red.700", "red.300");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "navy.700");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const [isOpen, setIsOpen] = useState(false);

  const [confirmationName, setConfirmationName] = useState('');
  const [user, setUser] = useState('');
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const onClose = () => {
    setIsOpen(false);
    setConfirmationName('');
    setDeletionConfirmed(false);
  };
  const updateUser = async (id) => {
    
  
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/users/update/${id}`,
        {
          shop: "",
          promoted: false,
          memberShip: "",
          billingCycle: "",
          orderId: "",
          ProPart: 'part',
          memberShipPeriod: {
            startDate: "",
            endDate: ""
          },
          credit: "",
          nbrAds: "",
          nbrAdmins: ""
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return (response.data);
    } catch (error) {
      console.error('Error updating User:', error);
    }
  }

  const handleChangeSubs = () => {
    history.push('/admin/memberships');
      };

  
  const handleDeleteMembership = async () => {    
    if (confirmationName.toLowerCase() === currentUser.firstName.toLowerCase()) {
      try {
        const updatedUser = await updateUser(currentUser?._id);
        if (currentUser?.idShop) {
          const response = await axios.delete(
            `${process.env.REACT_APP_API}/shops/shop/${currentUser?.idShop}`, // Replace with your delete shop API endpoint
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            }
          );
        }
      
        localStorage.setItem('user-token', JSON.stringify(updatedUser));
        toast.success(`L'abonnement a été supprimée avec succès`);
        setIsOpen(false);
        setDeletionConfirmed(true);
      } catch (error) {
        toast.error('Une erreur s\'est produite lors de la suppression');

      }
      
      

    }
    else {
      toast.error('Le prenom ne correspond pas. La suppression est annulée.');
    }
    
      };
  return (
    <Menu w='200px' isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        align='center'
        justifyContent='center'
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w='37px'
        h='37px'
        lineHeight='100%'
        onClick={onOpen1}
        borderRadius='10px'
        {...rest}>
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px' />
      </MenuButton>
      <MenuList
        
        minW='unset'
        
        border='transparent'
        
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius='20px'
        p='15px'>
 
        <MenuItem
         onClick={handleChangeSubs}
        bg={bgList}
        
          transition='0.2s linear'
          color={textColor}
          _hover={textHover}
          p='0px'
          borderRadius='8px'
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}>
          <Flex align='center'>
            <Icon as={MdOutlineSettings} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Changer ma formule
            </Text>
          </Flex>
        </MenuItem>
 
 {currentUser.memberShip !== 'Gratuit' &&      <MenuItem
        
        bg={bgList}
        mt={2}
         transition='0.2s linear'
         onClick={() => setIsOpen(true)}
         color={deleteText}
         _hover={textHover}
         p='0px'
         borderRadius='8px'
         _active={{
           bg: "transparent",
         }}
         _focus={{
           bg: "transparent",
         }}>
         <Flex  align='center'>
           <Icon fontWeight={600} as={MdOutlineSettings} h='16px' w='16px' me='8px' />
           <Text fontWeight={600} fontSize='sm' >
             {t('Supprimer mon abonnement')} 
           </Text>
         </Flex>
       </MenuItem>}
       <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Supprimer mon abonnement')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              {t('Êtes-vous sûr de vouloir supprimer votre abonnement ? cela entrainera la  suppression automatique de votre boutique. Cette action est irréversible.')}
            </Text>
            <InputGroup>
              <Input
              color={textColor}
                placeholder={`Tapez "${currentUser.firstName}" pour confirmer`}
                value={confirmationName}
                onChange={(e) => setConfirmationName(e.target.value)}
              />
            </InputGroup>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              {t('Annuler')}
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteMembership}
              isDisabled={!confirmationName || deletionConfirmed}
            >
              {deletionConfirmed ? 'Supprimée' : 'Supprimer'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </MenuList>
    </Menu>
  );
}
