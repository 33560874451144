import {
  Box,
  Grid,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
  IconButton,
  useBreakpointValue,
  Image,
  Avatar,
  Button,
  Center,
  Divider,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  MdCameraEnhance,
  MdCategory,
  MdPerson,
  MdSearch,
  MdHomeFilled,
  MdDelete,
  MdImage,
  MdCheck,
  MdOutlineClear,
} from 'react-icons/md';
import Card from 'components/card/Card.js';
import { NavLink, useHistory } from 'react-router-dom';
// Custom components
import { Helmet } from 'react-helmet';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Banner from 'views/ads/adDetails/components/Banner';
import BannerShop from 'views/ads/adDetails/components/BannerShop';
import banner from 'assets/img/auth/buySell.jpg';
import bannerSeller from 'assets/img/auth/sell.jpg';
import bannerBuyer from 'assets/img/auth/buy.jpg';
import Carrousel from 'views/ads/adDetails/components/Carrousel';
import General from 'views/admin/profile/components/General';
import Notifications from 'views/admin/profile/components/Notifications';
import Projects from 'views/admin/profile/components/Projects';
import Storage from 'views/admin/profile/components/Storage';
import Description from 'views/ads/adDetails/components/Description';
import DescriptionModal from 'views/ads/adDetails/components/DescriptionModal';
import AdAsList from 'views/ads/adDetails/components/AdAsList';
import HistoryItem from 'views/ads/adDetails/components/HistoryItem';
import { useParams } from 'react-router-dom';
import { useColorMode } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
// Assets
import Nft1 from 'assets/img/nfts/Nft1.png';
import Nft2 from 'assets/img/nfts/Nft2.png';
import Nft3 from 'assets/img/nfts/Nft3.png';
import Nft4 from 'assets/img/nfts/Nft4.png';
import Nft5 from 'assets/img/nfts/Nft5.png';
import Nft6 from 'assets/img/nfts/Nft6.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import avatar from 'assets/img/avatars/avatar4.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import NFT from 'components/card/NFT';
import { t } from 'helpers/TransWrapper';
import { useMediaQuery } from '@chakra-ui/react';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from 'react-icons/hi';
import bannerShop from 'assets/img/shopBanner.png';

function AdDetails() {
  const { id } = useParams();
  const [count, setCount] = useState([]);
  const [SelectedAd, setSelectedAd] = useState([]);
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [currentAd, setAd] = useState([]);
  const [adsCount, setAdsCount] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [customFieldsValues, setCustomFieldsValues] = useState([]);
  const [subCustomFieldsValues, setSubCustomFieldsValues] = useState([]);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const bg = useColorModeValue('white', 'navy.800');
  const packColor = useColorModeValue('navy', 'white');
  const [isLoading, setIsLoading] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [subscriptions, setSubscriptions] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [categories, setCategories] = useState([]);

  const history = useHistory();
  const settings = {
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [user, setUser] = useState('');
  const [shop, setShop] = useState('');
  const [owner, setOwner] = useState('');
  const [slider, setSlider] = React.useState();
  const top = useBreakpointValue({ base: '50%', md: '50%' });
  const side = useBreakpointValue({ base: '0%', md: '0px' });
  const getAdById = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/ad/${id}`
      );
      setSelectedAd(response.data);

      history.push(`/ads/${id}`);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/ad/${id}`
        );
        const currentAd = response.data;
        setAd(response.data);

        // Move the logic inside this block to ensure categories are available
        if (categories.length > 0) {
          const category = categories.find(
            cat => cat.label === currentAd.categoryLabel
          );

          console.log('category selected:', category);
          setSelectedCategoryId(category ? category._id : null);

          try {
            const res = await axios.get(
              `${process.env.REACT_APP_API}/subscriptions/category/${category._id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            setSubscriptions(res.data);
          } catch (error) {
            console.error(error);
            // Handle the error as needed (e.g., show an error message to the user)
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id, categories]);

  const makeOrder = async (id, adId, promo, reposts, price) => {
    try {
      const response = await axios.post(
        'https://jindoo.ma:6001/orders/order',
        {
          idSubscription: id,
          idUser: user._id,
          price: price,
          idAd: adId,
          promo: promo,
          reposts: reposts,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('order', response.data.order.generatedId); // Check the response from the server

      // Assuming the order was created successfully, redirect to the cart page
      history.push(`/ad/order/${response.data.order.generatedId}`);
    } catch (error) {
      // Handle any errors that occur during the HTTP request
      console.error('An error occurred:', error);
    }
  };

  const fetchData = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/ads/ad/${id}`)
        .then(response => {
          console.log(response.data);
          setAd(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchCustomFieldsValues = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/customFieldsValues/get/ad/${id}`)
        .then(response => {
          setCustomFieldsValues(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCustomFieldsValues();
  }, [id]);
  const fetchSubCustomFieldsValues = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/subCustomFieldsValues/get/ad/${id}`)
        .then(response => {
          setSubCustomFieldsValues(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchSubCustomFieldsValues();
  }, [id]);

  const fetchUserAdsCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${currentAd.userId}/count`,
        {}
      );
      setCount(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserAdsCount();
  }, [currentAd.status]);
  const [userAds, setUserAds] = useState([]);

  const fetchUserAds = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/ads/${currentAd.userId}`, {})
        .then(response => {
          const userAds = response.data;
          const newUserAds = userAds.filter(userAd => userAd._id !== id);
          setUserAds(newUserAds);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUserAds();
  }, [currentAd.userId, currentAd.status]);
  const fetchUser = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/users/${currentAd.userId}`)
        .then(response => {
          if (response.data || response.data.length > 0) {
            setUser(response.data);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUser();
  }, [currentAd.userId]);
  const fetchShopById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/${currentAd.idShop}`
      );
      const shopData = response.data;
      const adsResponse = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/${shopData._id}/ads/count`
      );
      const count = adsResponse.data.adsCount;
      setAdsCount(count);
      console.log('count', adsCount);

      console.log('shopData', shopData);
      if (shopData || shopData.length > 0) {
        setShop(shopData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchShopById();
  }, [currentAd.idShop]);
  useEffect(() => {
    const fetchOwner = async () => {
      try {
        if (shop && shop.owner) {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/users/${shop.owner}`
          );
          const owner = response.data;
          setOwner(owner);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOwner();
  }, [shop]);
  console.log(user.UserType);
  //   let ImgData;
  // for (let i = 0; i < currentAd.adPictures.length; i++) {
  //    ImgData = currentAd.adPictures[i];

  // }
  //
  const [dayIndices, setDayIndices] = useState([]); // State for day indices
  const [repostIndices, setRepostIndices] = useState([]); // State for repost indices
  const [priceIndices, setPriceIndices] = useState([]); // State for price indices

  useEffect(() => {
    // Initialize dayIndices and repostIndices based on subscriptions length
    setDayIndices(new Array(subscriptions.length).fill(0));
    setRepostIndices(new Array(subscriptions.length).fill(0));
    setPriceIndices(new Array(subscriptions.length).fill(0));
  }, [subscriptions]); // State for repost indices

  const handleAdd = index => {
    const updatedSubscription = subscriptions[index];

    if (
      updatedSubscription &&
      updatedSubscription.days &&
      updatedSubscription.reposts &&
      updatedSubscription.price &&
      updatedSubscription.price
    ) {
      const daysArray = updatedSubscription.days; // Array of days
      const repostsArray = updatedSubscription.reposts; // Array of reposts
      const priceArray = Object.keys(updatedSubscription.price); // Array of price indices

      let newIndex = (dayIndices[index] + 1) % daysArray.length; // Calculate the new index for days
      let newRepostIndex = (repostIndices[index] + 1) % repostsArray.length; // Calculate the new index for reposts
      let newPriceIndex = (priceIndices[index] + 1) % priceArray.length; // Calculate the new index for price

      setDayIndices(prevIndices => {
        const newDayIndices = [...prevIndices];
        newDayIndices[index] = newIndex; // Update the current day index for the specific subscription
        return newDayIndices;
      });

      setRepostIndices(prevIndices => {
        const newRepostIndices = [...prevIndices];
        newRepostIndices[index] = newRepostIndex; // Update the current repost index for the specific subscription
        return newRepostIndices;
      });

      setPriceIndices(prevIndices => {
        const newPriceIndices = [...prevIndices];
        newPriceIndices[index] = newPriceIndex; // Update the current price index for the specific subscription
        return newPriceIndices;
      });
    }
  };

  const handleSubtract = index => {
    const updatedSubscription = subscriptions[index];

    if (
      updatedSubscription &&
      updatedSubscription.days &&
      updatedSubscription.reposts &&
      updatedSubscription.price &&
      updatedSubscription.price
    ) {
      const daysArray = updatedSubscription.days; // Array of days
      const repostsArray = updatedSubscription.reposts; // Array of reposts
      const priceArray = Object.keys(updatedSubscription.price); // Array of price indices

      let newIndex =
        (dayIndices[index] - 1 + daysArray.length) % daysArray.length; // Calculate the new index for days
      let newRepostIndex =
        (repostIndices[index] - 1 + repostsArray.length) % repostsArray.length; // Calculate the new index for reposts
      let newPriceIndex =
        (priceIndices[index] - 1 + priceArray.length) % priceArray.length; // Calculate the new index for price

      setDayIndices(prevIndices => {
        const newDayIndices = [...prevIndices];
        newDayIndices[index] = newIndex; // Update the current day index for the specific subscription
        return newDayIndices;
      });

      setRepostIndices(prevIndices => {
        const newRepostIndices = [...prevIndices];
        newRepostIndices[index] = newRepostIndex; // Update the current repost index for the specific subscription
        return newRepostIndices;
      });

      setPriceIndices(prevIndices => {
        const newPriceIndices = [...prevIndices];
        newPriceIndices[index] = newPriceIndex; // Update the current price index for the specific subscription
        return newPriceIndices;
      });
    }
  };
  console.log(customFieldsValues);

  const date = currentAd.validatedAt
    ? new Date(currentAd.validatedAt)
    : new Date(currentAd.createdAt);
  const formattedDate = date.toLocaleDateString();

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const items = Array.from({ length: 5 });

  const { colorMode } = useColorMode();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const bgColor = colorMode === 'dark' ? 'navy.900' : 'gray.100';
  const bgBar = colorMode === 'dark' ? 'purple.500' : 'navy.900';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (picture, index) => {
    if (!isLoading) {
      console.log('picture');
      console.log(`picture: ${picture}`);

      setSelectedImage(picture);

      setIsModalOpen(true);
    }
  };
  const reviews = shop?.reviews || [];
  const positiveReviews = reviews.filter(review => review.rating >= 4);
  const totalReviews = reviews.length;
  const totalPositiveReviews = positiveReviews.length;

  // Calculate positive reviews percentage, considering the possibility of division by zero
  const positiveReviewsPercentage =
    totalReviews === 0
      ? 'N/A'
      : ((totalPositiveReviews / totalReviews) * 100).toFixed(2);
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '20px' }}>
      {/* Main Fields */}
      <div>
      <Helmet>
        <title>{currentAd.title}</title>
        <meta name="description" content={currentAd.description} />
        <meta property="og:title" content={currentAd.title} />
        <meta property="og:description" content={currentAd.description} />
        <meta property="og:image" content={currentAd.adPictures ? currentAd.adPictures[0] : null} />
        <meta property="og:url" content={`https://jindoo.ma/ads/${id}`} />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={currentAd.title} />
        <meta property="twitter:description" content={currentAd.description} />
        <meta property="twitter:image" content={currentAd.adPictures ? currentAd.adPictures[0] : null}   />
      </Helmet>

      {/* Your component's JSX here */}
    </div>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Grid
        mb="20px"
        templateColumns={{
          base: '1fr',
          lg: 'repeat(2, 1fr)',
          '3xl': '1fr 1fr',
        }}
        templateRows={{
          base: '1fr',
          lg: 'repeat(1, 1fr)',
          '2xl': '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        <Card p={0} shadow="lg">
          {currentAd.adPictures?.every(
            picture => Object.keys(picture).length === 0
          ) ? (
            <>
              <Image
                src={Nft3}
                objectFit="cover"
                width="100%"
                height="100%"
                borderRadius="20px"
              />
            </>
          ) : (
            <Box position="relative">
              <Slider {...settings} ref={slider => setSlider(slider)}>
                {currentAd.adPictures?.map((picture, index) => (
                  <Box
                    key={index}
                    align="center"
                    onClick={() => handleImageClick(picture, index)}
                    cursor="pointer"
                  >
                    <Image
                      objectFit="cover"
                      src={picture}
                      alt={`Image ${index}`}
                      borderRadius="20px"
                      width="100%"
                      height="450px"
                      // Adjust other image props as needed
                    />
                  </Box>
                ))}
              </Slider>
              <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                size="4xl"
              >
                <ModalOverlay
                  bg="blackAlpha.300"
                  backdropFilter="blur(10px) "
                />
                <ModalContent>
                  <ModalCloseButton position="absolute" right="-1" top="0" />
                  <ModalBody
                    p={5}
                    borderRadius="20px"
                    bg={bg}
                    display="flex"
                    flexDirection={isLargerThan768 ? 'row' : 'column'}
                  >
                    <Card
                      mr="4"
                      flex="1"
                      p="4" // Add padding for spacing
                      // Apply reduced border radius
                      // Add a medium box shadow
                    >
                      <DescriptionModal
                        name={currentAd.name}
                        category={currentAd.categoryLabel}
                        subcategory={currentAd.subcategoryLabel}
                        description={currentAd.description}
                        price={
                          currentAd.price === 0
                            ? t('Non défini')
                            : currentAd.price + t(' MAD')
                        }
                        dateCreated={formattedDate}
                        city={currentAd.city}
                        receiverId={currentAd.userId}
                        id={currentAd._id}
                        phone={user.phone}
                      />
                    </Card>
                    <Box flex="1.5" borderRadius="20px">
                      {currentAd.adPictures &&
                      currentAd.adPictures.length > 0 ? (
                        <Carousel
                          borderRadius="20px"
                          selectedItem={selectedImageIndex}
                          infiniteLoop={true}
                          interval={2000}
                          autoPlay={true}
                          renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                              <IconButton
                                aria-label="left-arrow"
                                colorScheme="messenger"
                                borderRadius="full"
                                position="absolute"
                                left={4}
                                top="50%"
                                transform="translate(0%, -50%)"
                                zIndex={2}
                                onClick={onClickHandler}
                              >
                                <BiLeftArrowAlt />
                              </IconButton>
                            )
                          }
                          renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                              <IconButton
                                aria-label="right-arrow"
                                colorScheme="messenger"
                                borderRadius="full"
                                position="absolute"
                                right={4}
                                top="50%"
                                transform="translate(0%, -50%)"
                                zIndex={2}
                                onClick={onClickHandler}
                              >
                                <BiRightArrowAlt />
                              </IconButton>
                            )
                          }
                        >
                          {currentAd.adPictures.map((picture, index) => (
                            <Box key={index} mt="4" style={{ height: '400px' }}>
                              {' '}
                              {/* Set the desired height */}
                              <Image
                                borderRadius="20px"
                                src={picture}
                                alt={`Image ${index}`}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </Box>
                          ))}
                        </Carousel>
                      ) : (
                        <Text>{t('No pictures available')}</Text>
                      )}
                      <Box mt="2" ml="2">
                        <h3 style={{ fontWeight: 'bold' }}>{t('General tips')}:</h3>
                        <ul style={{ marginLeft: '6px' }}>
                          <li>{t('Meet in public only.')}</li>
                          <li>{t('Do not send money beforehand.')}</li>
                          <li>{t('Check the product well before purchasing it.')}</li>
                        </ul>
                      </Box>
                    </Box>
                  </ModalBody>
                </ModalContent>
              </Modal>
              {/* Left Arrow */}
              <IconButton
                aria-label="left-arrow"
                colorScheme="messenger"
                borderRadius="full"
                position="absolute"
                left={4}
                top="50%"
                transform="translate(0%, -50%)"
                zIndex={2}
                onClick={() => slider?.slickPrev()}
              >
                <BiLeftArrowAlt />
              </IconButton>
              {/* Right Arrow */}
              <IconButton
                aria-label="right-arrow"
                colorScheme="messenger"
                borderRadius="full"
                position="absolute"
                right={4}
                top="50%"
                transform="translate(0%, -50%)"
                zIndex={2}
                onClick={() => slider?.slickNext()}
              >
                <BiRightArrowAlt />
              </IconButton>
            </Box>
          )}
        </Card>

        {isLoading === false ? (
          currentAd.shop ? (
            <BannerShop
              posts={adsCount}
              avatar={shop?.logo}
              name={shop?.name}
              userId={shop?.owner}
              banner={bannerShop}
              memberShip={owner?.memberShip}
              positiveReviews={positiveReviewsPercentage}
              createdAt={shop?.createdAt}
            />
          ) : (
            <Banner
              gridArea="1 / 2 / 2 / 2"
              banner={
                user?.UserType?.includes('Vendre') &&
                user?.UserType?.includes('Acheter')
                  ? banner
                  : user.UserType?.includes('Acheter')
                  ? bannerBuyer
                  : user.UserType?.includes('Vendre')
                  ? bannerSeller
                  : ''
              }
              avatar={currentAd.userProfilePicture}
              name={currentAd.firstName + ' ' + currentAd.lastName}
              job={user?.phone}
              posts={count?.adCount}
              userId={currentAd.userId}
              propart={user?.ProPart}
            />
          )
        ) : (
          <Box
            borderRadius="lg"
            overflow="hidden"
            bg={bgColor}
            position="relative"
          >
            <Skeleton
              height={{ base: '200px', md: '250px' }}
              width="auto"
              startColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
              endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
            />
            <Box
              position="absolute"
              top="40%"
              left="50%"
              transform="translate(-50%, -50%)"
            >
              <Skeleton
                isLoaded={!isLoading}
                borderRadius="full"
                height="150px"
                width="150px"
              ></Skeleton>
            </Box>
            <Box p="6">
              <Box
                mt="6"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
                color={textColor}
              >
                <Skeleton
                  height="10px"
                  width={{ base: '50%', md: '30%' }}
                  mb={2}
                  startColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
                  endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                />
                <Box bg={bgColor} height="20px" width="50%" mb={2}></Box>
                <Skeleton
                  height="10px"
                  width={{ base: '20%', md: '10%' }}
                  mr={2}
                  startColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
                  endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                />
              </Box>
              <Box
                d="flex"
                alignItems="baseline"
                color={textColor}
                fontSize="sm"
              >
                <Box bg={bgColor} height="10px" width="20%" mr={2}></Box>
                <Box bg={bgColor} height="10px" width="30%" mr={2}></Box>
              </Box>
              <Box d="flex" alignItems="baseline">
                <Button
                  isLoading
                  loadingText={t('Display my profile...')}
                  height="30px"
                  width="100%"
                  mb={2}
                  bg="#7551ff"
                  color="rgb(25, 7, 146)"
                />
                <Button
                  isLoading
                  loadingText={t('Update my profile...')}
                  height="30px"
                  width="100%"
                  bg="#7551ff"
                  color="rgb(25, 7, 146)"
                />
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
      <Description
        isShopPromoted={currentAd.isShopPromoted}
        promotedPrice={`${currentAd.promotedPrice} MAD`}
        discountShop={currentAd.discountShop}
        name={currentAd.name}
        category={currentAd.categoryLabel}
        subcategory={currentAd.subcategoryLabel}
        description={currentAd.description}
        price={
          currentAd.price === 0 ? t('Non défini') : currentAd.price + t(' MAD')
        }
        dateCreated={formattedDate}
        city={currentAd.city}
        receiverId={currentAd.userId}
        id={currentAd._id}
        phone={user.phone}
        status={currentAd.status}
        video={currentAd?.adVideo}
        file={currentAd?.adFile}
      />
      {currentUser?._id === currentAd.userId && subscriptions.length > 0 && (
        <Card mb="5">
          <Text
            textAlign="start"
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="15px"
          >
            {t('Vous voulez plus de visiteurs ?')}
          </Text>
          <Grid
            templateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
            display={{ base: 'block', md: 'grid' }}
            gap={6}
            mb={8}
          >
            {Array.isArray(subscriptions) &&
              subscriptions.map((subscription, index) => {
                let priceForSelectedDays = [];
                const selectedPromoDays = subscription.days[dayIndices[index]];
                const selectedRepostsDays =
                  subscription.reposts[repostIndices[index]];
                if (selectedPromoDays) {
                  priceForSelectedDays =
                    subscription.price[priceIndices[index]][
                      selectedPromoDays.toString()
                    ];
                } else if (selectedRepostsDays) {
                  priceForSelectedDays =
                    subscription.price[priceIndices[index]][
                      selectedRepostsDays.toString()
                    ];
                }
                const handleSelection = () => {
                  makeOrder(
                    subscription._id,
                    id,
                    subscription.days[dayIndices[index]],
                    subscription.reposts[repostIndices[index]],
                    priceForSelectedDays
                  ); // Get the ad by its id when the component is clicked
                };

                return (
                  <>
                    {subscription.name !== 'SILVER' && (
                      <Card
                        mb={5}
                        mt={5}
                        bg={bgColor}
                        borderWidth="1px"
                        position="relative"
                        p="2"
                        py="30px"
                        
                      >
                        <Box textAlign="center">
                          <Box justifyContent="center" alignItems="center">
                            <Text color="purple.600">{subscription.name}</Text>
                            <Text
                              pt={2}
                              fontSize="xl"
                              fontWeight="700"
                              color="purple.600"
                            >
                              {priceForSelectedDays} {t("MAD")}/{t("Jour")}
                            </Text>
                          </Box>

                          <Flex justify="center" mb={10}>
                            <Flex>
                              <Text mr={1} fontSize="sm" color="gray.600">
                                {t(`Recevez jusqu'à`)}
                              </Text>
                              <Text
                                fontSize="sm"
                                fontWeight="700"
                                color="purple.600"
                              >
                                {subscription.name === 'GOLD'
                                  ? t(`25x plus de visibilité`)
                                  : t(`15x plus de visibilité`)}
                              </Text>
                            </Flex>
                          </Flex>

                          <Divider
                            variant="dashed"
                            w={'60%'}
                            mx={'auto'}
                            colorScheme={packColor}
                            borderWidth={1}
                            borderColor={packColor}
                            mb={2}
                          />

                          <Flex justify="center" mb={2}>
                            <Box>
                              <Flex mb={2}>
                                {subscription.days[0] === 0 ? (
                                  <Icon
                                    as={MdOutlineClear}
                                    boxSize={5}
                                    color="red"
                                    mr={1}
                                  />
                                ) : (
                                  <Icon
                                    as={MdCheck}
                                    boxSize={5}
                                    color="green"
                                    mr={1}
                                  />
                                )}
                                <Text fontSize="sm" color="gray.600">
                                  {subscription.days[0] === 0 ? (
                                    t('Pas de promotion')
                                  ) : (
                                    <>
                                      <span>{t("Promouvoir")} </span>
                                      <Text
                                        as="span"
                                        fontWeight="bold"
                                        fontSize="lg"
                                        color="purple.600"
                                      >
                                        {subscription.days[dayIndices[index]]}{' '}
                                        {t('jours')}
                                      </Text>
                                    </>
                                  )}
                                </Text>
                              </Flex>
                              <Flex>
                                {subscription.reposts[0] === 0 ? (
                                  <Icon
                                    as={MdOutlineClear}
                                    boxSize={5}
                                    color="red"
                                    mr={1}
                                  />
                                ) : (
                                  <Icon
                                    as={MdCheck}
                                    boxSize={5}
                                    color="green"
                                    mr={1}
                                  />
                                )}
                                <Text fontSize="sm" color="gray.600">
                                  {subscription.reposts[0] === 0 ? (
                                    t('Pas de promotion')
                                  ) : (
                                    <>
                                      <span>{t('Renouvelement')} </span>
                                      <Text
                                        as="span"
                                        fontWeight="bold"
                                        fontSize="lg"
                                        color="purple.600"
                                      >
                                        {
                                          subscription.reposts[
                                            repostIndices[index]
                                          ]
                                        }{' '}
                                        {t('jours')}
                                      </Text>
                                    </>
                                  )}
                                </Text>
                              </Flex>
                            </Box>
                          </Flex>

                          <Divider
                            variant="dashed"
                            textAlign="center"
                            w={'60%'}
                            mx={'auto'}
                            colorScheme={packColor}
                            borderWidth={1}
                            borderColor={packColor}
                            mb={2}
                          />

                          {/* Left Side Border with Decrement Icon */}
                          <Box
                            position="absolute"
                            top="0"
                            bottom="0"
                            left="0"
                            width="30px"
                            bg="purple.500"
                            color="white"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderTopLeftRadius="lg"
                            borderBottomLeftRadius="lg"
                            onClick={() => handleSubtract(index)}
                            cursor="pointer"
                          >
                            <HiOutlineMinusCircle size={24} />
                          </Box>

                          {/* Right Side Border with Increment Icon */}
                          <Box
                            position="absolute"
                            top="0"
                            bottom="0"
                            right="0"
                            width="30px"
                            bg="purple.500"
                            color="white"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderTopRightRadius="lg"
                            borderBottomRightRadius="lg"
                            onClick={() => handleAdd(index)}
                            cursor="pointer"
                          >
                            <HiOutlinePlusCircle size={24} />
                          </Box>

                          <Flex justify="center" pt={2}>
                            <Button
                              onClick={handleSelection}
                              variant="lightBrand"
                              color="purple.600"
                            >
                              {t('Selectionner')}
                            </Button>
                          </Flex>
                        </Box>
                      </Card>
                    )}
                    {subscription.name === 'SILVER' && (
                      <Card
                        shadow="xl"
                        position="relative"
                        bg={bgColor}
                        borderWidth="1px"
                        py="30px"
                        
                      >
                        <Box textAlign="center">
                          <Box justifyContent="center" alignItems="center">
                            <Text
                              
                              color={packColor}
                              fontSize={20}
                            >
                              {subscription.name}
                            </Text>
                            <Text
                              pt={2}
                              fontSize="2xl"
                              fontWeight="700"
                              color={packColor}
                            >
                              {priceForSelectedDays + t(`MAD`) + `/` + t(`Jour`)}{' '}
                            </Text>
                          </Box>
                          <Flex justify="center" mb={10}>
                            
                            <Flex>
                              <Text mr={1} fontSize="sm" color="gray.600">
                                {t(`Recevez jusqu'à`)}
                              </Text>
                              <Text
                                fontSize="sm"
                                fontWeight="700"
                                color={packColor}
                              >
                                {t(`20x plus de visibilité`)}
                              </Text>
                            </Flex>
                          </Flex>

                          <Divider
                            variant="dashed"
                            textAlign="center"
                            colorScheme={packColor}
                            borderWidth={1}
                            borderColor={packColor}
                            w={'60%'}
                            mx={'auto'}
                            mb={1}
                          />
                          <Flex justify="center" mb={2}>
                            <Box mb={1}>
                              <Flex>
                                <Icon
                                  as={MdCheck}
                                  boxSize={5}
                                  color="green"
                                  mr={1}
                                />

                                <Text mr={1} fontSize="sm" color="gray.600">
                                  {subscription.days[0] === 0 ? (
                                    t('Pas de promotion')
                                  ) : (
                                    <>
                                      <span>{t('Promouvoir')} </span>
                                      <Text
                                        as="span"
                                        fontWeight="bold"
                                        fontSize="lg" // Adjust the size as needed
                                        color={packColor} // Set your desired color
                                      >
                                        {subscription.days[dayIndices[index]]}{' '}
                                        {t('jours')}
                                      </Text>
                                    </>
                                  )}
                                </Text>
                              </Flex>

                              <Flex>
                                {subscription.reposts[0] === 0 ? (
                                  <Icon
                                    as={MdOutlineClear}
                                    boxSize={5}
                                    color="red"
                                    mr={1}
                                  />
                                ) : (
                                  <Icon
                                    as={MdCheck}
                                    boxSize={5}
                                    color="green"
                                    mr={1}
                                  />
                                )}

                                <Text mr={1} fontSize="sm" color="gray.600">
                                  {subscription.reposts[0] === 0
                                    ? t('Pas de renouvelement')
                                    : t(`Renouvelement `) + 
                                        subscription.reposts[
                                          repostIndices[index]
                                        ]
                                       + t(` jours`)}
                                </Text>
                              </Flex>
                            </Box>
                          </Flex>
                          <Divider
                            variant="dashed"
                            textAlign="center"
                            colorScheme={packColor}
                            borderWidth={1}
                            borderColor={packColor}
                            w={'60%'}
                            mx={'auto'}
                            mb={1}
                          />
                          <Box
                            position="absolute"
                            top="0"
                            bottom="0"
                            left="0"
                            width="30px"
                            bg={bgBar}
                            color="white"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderTopLeftRadius="lg"
                            borderBottomLeftRadius="lg"
                            onClick={() => handleSubtract(index)}
                            cursor="pointer"
                          >
                            <HiOutlineMinusCircle size={24} />
                          </Box>

                          {/* Right Side Border with Increment Icon */}
                          <Box
                            position="absolute"
                            top="0"
                            bottom="0"
                            right="0"
                            width="30px"
                            bg={bgBar}
                            color="white"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderTopRightRadius="lg"
                            borderBottomRightRadius="lg"
                            onClick={() => handleAdd(index)}
                            cursor="pointer"
                          >
                            <HiOutlinePlusCircle size={24} />
                          </Box>
                          <Flex w="100%" justify="center" pt={2}>
                     
                            <Button
                              onClick={handleSelection}
                              variant="darkBrand"
                            >
                              {t('Selectionner')}
                            </Button>
                          </Flex>
                        </Box>
                        <Box
                          position="absolute"
                          top="-3"
                          right="1"
                          bg="yellow"
                          color="black"
                          px={2}
                          py={1}
                          borderRadius="md"
                          fontWeight="bold"
                        >
                          {t('Best Seller')}
                        </Box>
                      </Card>
                    )}
                  </>
                );
              })}
          </Grid>
        </Card>
      )}

      <Grid
        mb="20px"
        templateColumns={{
          base: '1fr',
          lg: 'repeat(1, 1fr)',
          '2xl': '1fr 1fr',
        }}
        templateRows={{
          base: '1fr',
          lg: 'repeat(1, 1fr)',
          '2xl': '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        {
          <Flex
            flexDirection="column"
            mb={{ base: '0px', '2xl': '20px' }}
            align="flex-start"
          >
            <Card textAlign="start" mb="20px">
              <Text
                color={textColorPrimary}
                fontWeight="bold"
                fontSize="4xl"
                mt="10px"
                mb="4px"
              >
                {t('Caractéristiques')}
              </Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                {currentAd.type === 'null' ? null : (
                  <>
                    <Flex>
                      {' '}
                      <Text
                        color={textColorPrimary}
                        fontWeight="bold"
                        fontSize="xl"
                        mt="10px"
                        mb="4px"
                      >
                        {t('Type')}
                      </Text>
                    </Flex>
                    <Flex>
                      {' '}
                      <Text
                        color={textColorPrimary}
                        fontWeight="regular"
                        fontSize="xl"
                        mt="10px"
                        mb="4px"
                      >
                        {currentAd.type}
                      </Text>
                    </Flex>
                  </>
                )}

                <Flex>
                  {' '}
                  <Text
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="xl"
                    mt="10px"
                    mb="4px"
                  >
                    {t('Catégorie')}
                  </Text>
                </Flex>
                <Flex>
                  {' '}
                  <Text
                    color={textColorPrimary}
                    fontWeight="regular"
                    fontSize="xl"
                    mt="10px"
                    mb="4px"
                  >
                    {currentAd.categoryLabel}
                  </Text>
                </Flex>
                {currentAd.subcategory && (
                  <>
                    <Flex>
                      {' '}
                      <Text
                        color={textColorPrimary}
                        fontWeight="bold"
                        fontSize="xl"
                        mt="10px"
                        mb="4px"
                      >
                        {t('Sous-catégorie')}
                      </Text>
                    </Flex>
                    <Flex>
                      {' '}
                      <Text
                        color={textColorPrimary}
                        fontWeight="regular"
                        fontSize="xl"
                        mt="10px"
                        mb="4px"
                      >
                        {currentAd.subcategoryLabel}
                      </Text>
                    </Flex>
                  </>
                )}

                {subCustomFieldsValues.map(value => {
                  return (
                    <>
                      {' '}
                      <Flex>
                        {' '}
                        <Text
                          color={textColorPrimary}
                          fontWeight="bold"
                          fontSize="xl"
                          mt="10px"
                          mb="4px"
                        >
                          {t('Sous-Catégorie')}
                        </Text>
                      </Flex>
                      <Flex>
                        {' '}
                        <Text
                          color={textColorPrimary}
                          fontWeight="regular"
                          fontStyle="italic"
                          fontSize="xl"
                          mt="10px"
                          mb="4px"
                        >
                          {currentAd.subcategoryLabel}
                        </Text>
                      </Flex>
                    </>
                  );
                })}
                {customFieldsValues.map(value => {
                  return (
                    <>
                      {' '}
                      <Flex>
                        {' '}
                        <Text
                          color={textColorPrimary}
                          fontWeight="bold"
                          fontSize="xl"
                          mt="10px"
                          mb="4px"
                        >
                          {value.field_name}
                        </Text>
                      </Flex>
                      <Flex>
                        {' '}
                        <Text
                          color={textColorPrimary}
                          fontWeight="regular"
                          fontSize="xl"
                          mt="10px"
                          mb="4px"
                        >
                          {value.value.join(' - ')}
                        </Text>
                      </Flex>
                    </>
                  );
                })}
              </Grid>
            </Card>
          </Flex>
        }

        <Flex
          flexDirection="column"
          mb={{ base: '0px', '2xl': '20px' }}
          align="flex-start"
        >
          <Card textAlign="start" mb="20px">
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="4xl"
              mt="10px"
              mb="4px"
            >
              {t('Autre annonces')}
            </Text>
          </Card>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            gap="20px"
            mb={{ base: '20px', xl: '0px' }}
          >
            {userAds && userAds.length > 0 ? (
              userAds.slice(0, 9).map(ad => {
                const handleClick = () => {
                  getAdById(ad._id); // Get the ad by its id when the component is clicked
                };

                return (
                  <Flex key={ad._id}>
                    <NFT
                      id={ad._id}
                      //userId= {currentUser._id}
                      name={ad.name}
                      author={ad.firstName + ' ' + ad.lastName}
                      bidders={[
                        Avatar1,
                        Avatar2,
                        Avatar3,
                        Avatar4,
                        Avatar1,
                        Avatar1,
                        Avatar1,
                        Avatar1,
                      ]}
                      image={
                        ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
                          ? ad.adPictures[0]
                          : Nft3
                      }
                      category={ad.categoryLabel}
                      currentbid={
                        ad.price === 0 ? t('Non défini') : ad.price + t(' MAD')
                      }
                      Click={handleClick}
                      city={ad.city}
                      dateCreated={
                        new Date() - new Date(ad.createdAt) >= 86400000
                          ? Math.floor(
                              (new Date() - new Date(ad.createdAt)) /
                                1000 /
                                60 /
                                60 /
                                24
                           ) + t(` Jours`)
                          : Math.floor(
                              (new Date() - new Date(ad.createdAt)) /
                                1000 /
                                60 /
                                60
                            ) + t(` Heures`)
                      }
                    />{' '}
                  </Flex>
                );
              })
            ) : (
              <div> {t('Loading...')} </div>
            )}{' '}
          </SimpleGrid>{' '}
        </Flex>
      </Grid>
    </Box>
  );
}
export default AdDetails;
