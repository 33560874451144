import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  ThemeProvider,
  theme,
  Link,
  Box,
  Th,
  Tr,
  Td,
  Tbody,
  Table,
  Thead,
  VStack,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  Image,
  HStack,
  Divider,
  useDisclosure,
  CloseButton,
  Grid,
  GridItem,
  Progress,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  Wrap,
  WrapItem,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  Badge,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { t } from "helpers/TransWrapper";
import { FaArrowLeft, FaHeart, FaShareAlt, FaTimes } from 'react-icons/fa'; // Import icons
import Card from 'components/card/Card.js';
import Swal from 'sweetalert2';
import { ExternalLinkIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  MdCameraEnhance,
  MdCategory,
  MdPerson,
  MdSearch,
  MdHomeFilled,
  MdDelete,
  MdImage,
  MdCheck,
  MdOutlineShoppingBag,
} from 'react-icons/md';
import {
  MdBatteryCharging30,
  MdBatteryCharging60,
  MdBatteryChargingFull,
  MdShoppingCart,
} from 'react-icons/md';
import { MdEmail, MdPhone, MdLocationOn } from 'react-icons/md'; // Import icons
import Carte from '../../../assets/img/carte.png';
import Paypal from '../../../assets/img/paypal.png';
import CashPlus from '../../../assets/img/Cashplus.png';
import { TbRepeat, TbRocket } from 'react-icons/tb';
const ShopOrder = () => {
  const main = useColorModeValue('navy', 'white');
  const secondary = useColorModeValue('gray.600', 'gray.300');
  const bg = useColorModeValue('white', 'navy.700');
  const [coupon, setCoupon] = useState();

  const { id } = useParams();
  const [shoporder, setShoporder] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [totalReposts, setTotalReposts] = useState();
  const [totalPromo, setTotalPromo] = useState();
  const [user, setUser] = useState();
  const [paywallForm, setPaywallForm] = useState('');
  const [payloadData, setPayloadData] = useState();
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/shoporders/shoporder/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const orderResponse = res.data.shopOrder;
        const payloadData = {
          merchantAccount: 'Jindo',
          timestamp: Math.floor(new Date().getTime() / 1000), // Timestamp in seconds
          skin: 'vps-1-vue',
          customerId: currentUser._id,
          customerEmail: currentUser.email,
          customerName: currentUser.firstName + ' ' + currentUser.lastName,
          customerCountry: 'MA',
          customerLocale: 'fr_FR',
          chargeId: Math.floor(new Date().getTime() / 1000), // Optional, unless required by the merchant account
          price: orderResponse.totalPrice,
          currency: 'MAD',
          description: `Plan d'abonnement Professionnel: ${orderResponse?.memberShip}`,
          mode: 'DEEP_LINK',
          paymentMethod: 'CREDIT_CARD',
          showPaymentProfiles: 'true',
          callbackUrl: `https://jindoo.ma:6001/shoporders/shoporder/cmi/callback?orderId=${id}`,
          //flowCompletionUrl: 'http://sample.com/hosted-paywall-files/redirect.html', //
          successUrl: `https://jindoo.ma/#/shop/cmi/success?orderId=${id}`,
          failureUrl: 'https://jindoo.ma/#/ad/payzone/cancel',
        };
        setPayloadData(payloadData);
        console.log('payloadData', payloadData);
        console.log('shoporder', orderResponse);
        setShoporder(orderResponse);
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }
    };

    fetchOrder(); // Call the asynchronous function here
  }, [id]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/users/${shoporder?.idUser}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const user = res.data;
        setUser(user);
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }
    };

    fetchUser(); // Call the asynchronous function here
  }, [shoporder?.idUser]);

  const handleCmi = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/shoporders/shoporder/cmi/create`,
        {
          payload: payloadData,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setPayloadResponse(response.data.payload);
      setSignature(response.data.signature);
      console.log('signature', response.data.signature);

      //window.location.href = targetUrl;
      console.log('response', response.data);
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };
  useEffect(() => {
    handleCmi();
  }, [shoporder?.totalPrice]);
  const handleSubmit = () => {
    document.getElementById('openPaywall').submit();
  };
  const [payloadResponse, setPayloadResponse] = useState();
  const [signature, setSignature] = useState();

  const executePaypalPayment = async (paymentId, payerId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/shops/shoporder/paypal/execute`,
        {
          paymentId,
          payerId,
          // Add other necessary details
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Handle the response as needed
      console.log('PayPal payment executed successfully:', response.data);
    } catch (error) {
      console.error('Error executing PayPal payment:', error);
    }
  };
  const updateOrderStatus = async (id, status) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/shoporders/shoporder/${id}`,
        {
          status: status,

          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error updating shoporder:', error);
    }
  };

  const handleCancelOrder = async () => {
    Swal.fire({
      title: t('Êtes-vous sûr(e) ?'),
      text: t('Vous ne pourrez pas revenir en arrière !'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Oui, annuler la commande !'),
      cancelButtonText: t('Non'),
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await updateOrderStatus(id, 'CANCELED');

          Swal.fire(t('Supprimée !'), t('Vote commande est annulee.'), t('success'));
          history.go(0);
        } catch (error) {
          console.error(error);
          Swal.fire(t('Erreur'), t("Une erreur s'est produite."), t('error'));
        }
      }
    });
  };
  const history = useHistory();
  const orderDate = new Date(shoporder?.createdAt);
  const formattedDate = orderDate.toLocaleDateString();

  const currentDate =
    new Date().toISOString().replace(/\D/g, '').slice(0, -1) + 'T+0000';
  // PAYEMENTS LOGIC
  const handlePaypalClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/shops/shoporder/paypal/create`,
        {
          generatedId: id,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const paymentId = response.data.paymentId;

      const approvalUrl = response.data.approvalUrl;
      window.location.replace(approvalUrl);
      const executePaymentResponse = await executePaypalPayment(
        paymentId,
        shoporder?.idUser
      );
    } catch (error) {
      console.error('Error creating PayPal payment:', error);
    }
  };
  const handleCashPlusClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/shoporders/shoporder/cash/create`,
        {
          orderId: id,
          transmission_datetime: currentDate,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const payementRequest = response.data;
      console.log('Payment Request:', payementRequest);

      if (payementRequest && payementRequest.payexpressResponseData.url1) {
        const url = payementRequest.payexpressResponseData.url1;
        console.log('Payment URL:', url);
        openPaymentWindow(url);
      } else {
        console.error('Invalid payment URL in the response:', payementRequest);
      }
    } catch (error) {
      console.error('Error creating PayPal payment:', error);
    }
  };

  const openPaymentWindow = url => {
    // Open a new tab/window with the payment URL
    const paymentWindow = window.open(url, '_blank');
    if (paymentWindow) {
      // Focus on the new tab/window if it's successfully opened
      paymentWindow.focus();
    } else {
      // Handle if the popup is blocked
      console.error('Popup blocked or unable to open the payment window.');
      // You can inform the user or provide an alternative method to proceed
    }
  };
  const [couponApplied, setCouponApplied] = useState(false);
  const updateOrderTotalPrice = async (id, totalPrice, couponCode) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/shoporders/shoporder/${id}`,
        { totalPrice: totalPrice, couponCode: couponCode },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200 && response.data.shopOrder.isCouponUsed) {
        setCouponApplied(true);
      }
    } catch (error) {
      console.error('Error updating shoporder:', error);
    }
  };
  const [isCouponValid, setIsCouponValid] = useState(null);
  const [originalPrice, setOriginalPrice] = useState();
  const handleCouponValidation = async () => {
    const validCoupons = ['SUPERJINDOO', 'SAVE50'];
    if (validCoupons.includes(coupon)) {
      if (!couponApplied) {
        if (coupon === 'SAVE50') {
          setIsCouponValid(true);
          setOriginalPrice(shoporder?.totalPrice);
          await updateOrderTotalPrice(id, shoporder?.totalPrice * 0.5, coupon);
          setTotalPrice(shoporder?.totalPrice * 0.5);
        } else if (coupon === 'SUPERJINDOO') {
          setIsCouponValid(true);
          setOriginalPrice(shoporder?.totalPrice);
          await updateOrderTotalPrice(id, 0, coupon);
          setTotalPrice(0);
        }
      } else {
        setIsCouponValid(false);
      }
    } else {
      setIsCouponValid(false);
    }
  };
  const updateOrder = async (
    id,
    status,
    paymentStatus,
    paypalPaymentStatus
  ) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/shoporders/shoporder/${id}`,
        {
          status: status,
          paymentStatus: paymentStatus,
          paypalPaymentStatus: paypalPaymentStatus,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error updating shoporder:', error);
    }
  };

  const updateUser = async (
    id,
    promoted,
    memberShip,
    billingCycle,
    orderId,
    credit,
    nbrAds,
    nbrAdmins
  ) => {
    const currentDate = new Date(); // Get current date
    const endDate = new Date(currentDate); // Clone current date to calculate end date
    endDate.setMonth(endDate.getMonth() + billingCycle); // Add billingCycle months to end date

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/users/update/${id}`,
        {
          promoted: promoted,
          memberShip: memberShip,
          billingCycle: billingCycle,
          orderId: orderId,
          ProPart: 'pro',
          memberShipPeriod: {
            startDate: currentDate,
            endDate: endDate,
          },
          credit: credit,
          nbrAds: nbrAds,
          nbrAdmins: nbrAdmins,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setUser(response.data);
    } catch (error) {
      console.error('Error updating User:', error);
    }
  };
  const handleValidateOrder = async () => {
    Swal.fire({
      title: t('Êtes-vous sûr(e) ?'),
      text: t('Vous ne pourrez pas revenir en arrière !'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Oui, valider la commande !'),
      cancelButtonText: t('Non'),
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await updateOrder(id, 'SET', 'COMPLETED', 'completed');
          await updateUser(
            shoporder?.idUser,
            true,
            shoporder?.memberShip,
            shoporder?.billingCycle,
            id,
            shoporder?.credit,
            shoporder?.nbrAds,
            shoporder?.nbrAdmins
          );

          Swal.fire(t('Supprimée !'), t('Vote commande est validee.'), t('success'));
          history.go(0);
        } catch (error) {
          console.error(error);
          Swal.fire(t('Erreur'), t("Une erreur s'est produite."), t('error'));
        }
      }
    });
  };
  useEffect(() => {
    console.log('user: ', user);
    if (user) {
      localStorage.setItem('user-token', JSON.stringify(user));
      // Perform other actions with the updated user here
    }
  }, [user]);
  function getPriceByCoupon(coupon) {
    switch (coupon) {
      case 'SAVE50':
        return shoporder?.totalPrice * 0.5;
      case 'SUPERJINDOO':
        return shoporder?.totalPrice;
      default:
        return '00.00';
    }
  }
  return (
    <>
      <Card mt={5} mb={12} p={6}>
        <Box textAlign="start">
          <Flex w="100%" justify="space-between">
            <Box>
              {' '}
              <Heading
                fontSize={25}
                w="100%"
                textAlign={'start'}
                fontWeight="bold"
                pl="8px"
                pb="5px"
              >
                {t('Commande Magasin')} #{id}
              </Heading>
              <Text mr={1} mb={5} pl="8px" fontSize="md" color={secondary}>
                {t('Date de commande :')} <strong>{formattedDate}</strong>
              </Text>
            </Box>
            <Flex>
             
              <Box>
                {shoporder?.status === 'ON GOING' ? (
                  <Badge variant="solid" colorScheme="purple">
                    {t('EN COURS')}
                  </Badge>
                ) : shoporder?.status === 'SET' ? (
                  <Badge variant="solid" colorScheme="green">
                    {t('REGLEE')}
                  </Badge>
                ) : shoporder?.status === 'CANCELED' ? (
                  <Badge variant="solid" colorScheme="red">
                    {t('ANNULEE')}
                  </Badge>
                ) : null}
              </Box>
            </Flex>
          </Flex>

          <Box px={3}>
            <Card shadow="lg" mb={5} bg={bg}>
              <Flex w="100%" justify="space-between">
                <Heading as="h2" size="md" mb={4} color={main}>
                  {t('Informations Client')}
                </Heading>

                <Box>
                  {user?.ProPart === 'part' ? (
                    <Badge variant="solid" colorScheme="blue">
                      {t('PARTICULIER')}
                    </Badge>
                  ) : (
                    <Badge variant="solid" colorScheme="green">
                      {t('PROFESSIONEL')}
                    </Badge>
                  )}
                </Box>
              </Flex>
              <Divider
                variant="dashed"
                textAlign="center"
                colorScheme={main}
                borderWidth={1}
                borderColor={main}
                mb={4}
              />{' '}
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <HStack mb={2} alignItems="center">
                    <Icon as={MdPerson} boxSize={6} color={main} />
                    <Text fontSize="md">
                      {user?.firstName} {user?.lastName}
                    </Text>
                  </HStack>
                  <HStack mb={2} alignItems="center">
                    <Icon as={MdEmail} boxSize={6} color={main} />
                    <Text fontSize="md">{user?.email}</Text>
                  </HStack>
                </GridItem>
                <GridItem colSpan={1}>
                  <HStack mb={2} alignItems="center">
                    <Icon as={MdPhone} boxSize={6} color={main} />
                    <Text fontSize="md">{user?.phone}</Text>
                  </HStack>
                  <HStack mb={2} alignItems="center">
                    <Icon as={MdLocationOn} boxSize={6} color={main} />
                    <Text fontSize="md">{user?.address}</Text>
                  </HStack>
                </GridItem>
              </Grid>
            </Card>
            <Card shadow="lg" mb={5} bg={bg}>
              <Flex w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Icon
                      as={
                        shoporder?.memberShip === 'Débutant '
                          ? MdBatteryCharging30
                          : shoporder?.memberShip === 'Professionnel'
                          ? MdBatteryCharging60
                          : shoporder?.memberShip === 'Expert'
                          ? MdBatteryChargingFull
                          : null
                      }
                      boxSize="90px"
                      color={main}
                    />
                    <Box>
                      <Flex>
                        {' '}
                        <Text fontWeight="600" fontSize="xl" color={main}>
                          {shoporder?.memberShip}
                        </Text>
                        <Text ml={1} fontSize="sm" mt="6px" color={secondary}>
                          : {shoporder?.descriptionShort}
                        </Text>
                      </Flex>

                      <Text fontSize="sm" color={secondary}>
                        {' '}
                        {t('Nombre de mois :')}{' '}
                        <strong>{shoporder?.billingCycle} {t('mois')}</strong>
                      </Text>
                      <Text fontSize="sm" color={secondary}>
                        {' '}
                        <strong>{shoporder?.nbrAds}</strong> {t('Annonces')}
                      </Text>
                      <Text fontSize="sm" color={secondary}>
                        {' '}
                        <strong>{shoporder?.nbrAdmins}</strong> {t('Administrateurs')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Box alignSelf="center">
                  {' '}
                  <Text ml={1} fontSize="lg" fontWeight="600" color={main}>
                    {shoporder?.price} {t('MAD / Mois')}
                  </Text>
                  <Text ml={1} fontSize="sm" color={secondary}>
                    <strong>{shoporder?.credit} </strong>{t('Credits inclus')}
                  </Text>
                </Box>
              </Flex>
            </Card>
            <Box mb={6}>
              <Flex justify='space-between' w='100%'>
              <Text fontWeight="600" mb={2}>
                {t('Coupon')}
              </Text>
              <Icon
                    mb={1}
                    as={MdOutlineShoppingBag}
                    boxSize={5}
                    color="blue.500"
                    mr={1}
                    //onClick={handleCouponValidation}
                    //cursor="pointer"
                  />
              </Flex>
             
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  h="35px"
                  type="text"
                  placeholder={t("Code promo")}
                  value={coupon}
                  onChange={e => setCoupon(e.target.value)}
                />
                <InputRightElement width="5.5rem" pb={1}>
                
                  <Button onClick={handleCouponValidation} 
                  p={2}
                  h="25px"
                 variant='link'
                  
               
                  
                  >
                    <Text fontSize={12} color={main} >
                      {t('Valider')}
                    </Text>
                  </Button>
                </InputRightElement>
              </InputGroup>
              {isCouponValid === true && (
                <Text color="green.500" mt={2}>
                  {t('Coupon is valid!')}
                </Text>
              )}
              {isCouponValid === false && (
                <Text color="red.500" mt={2}>
                  {t('Coupon is invalid or already used!')}
                </Text>
              )}
            </Box>
            <Box>
              <Text fontWeight="600" mb={2}>
                {t('Résumé')}
              </Text>

              <Flex mb={1} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Box>
                      {' '}
                      <Text
                        ml={1}
                        fontSize="md"
                        color={secondary}
                        fontWeight="600"
                      >
                        {t('Sous-total')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text ml={1} fontSize="md" fontWeight="600" color={secondary}>
                  {' '}
                  {totalPrice ? originalPrice : shoporder?.totalPrice} MAD
                </Text>
              </Flex>
              <Flex mb={1} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Box>
                      {' '}
                      <Text
                        ml={1}
                        fontSize="md"
                        color={secondary}
                        fontWeight="600"
                      >
                        {t('Coupon')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text fontSize="md" color="red.300" fontWeight="600">
                  {' '}
                  - {getPriceByCoupon(coupon)} {t('MAD')}
                </Text>
              </Flex>
              <Divider
                variant="dashed"
                textAlign="center"
                colorScheme={main}
                borderWidth={1}
                borderColor={main}
                mb={1}
              />
              <Flex mb={2} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Box>
                      {' '}
                      <Text fontWeight="600" fontSize="md" color={main}>
                        {t('Total')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text fontSize="lg" fontWeight="600" color={main}>
                  {' '}
                  {totalPrice === undefined
                    ? shoporder?.totalPrice
                    : totalPrice === 0
                    ? totalPrice
                    : totalPrice}{' '}
                  {t('MAD')}
                </Text>
              </Flex>
            </Box>

            {totalPrice !== 0 ? (shoporder?.status === 'ON GOING' &&
              !shoporder?.cashReservationId &&
              user?._id === currentUser._id && (
                <>
                  <Text fontSize={25} mt={5} fontWeight="500" mb={2}>
                    {t('Méthodes de Paiement')}
                  </Text>
                  <Card shadow="lg" mb={5}>
                    <form
                      id="openPaywall"
                      action="https://payment.payzone.ma/pwthree/launch"
                      method="POST"
                    >
                      <input
                        type="hidden"
                        name="payload"
                        value={payloadResponse}
                      />
                      <input type="hidden" name="signature" value={signature} />

                      <Flex
                        bg={bg}
                        onClick={handleSubmit}
                        cursor="pointer"
                        shadow="md"
                        p={4}
                        borderRadius={15}
                        mb={3}
                        mt={2}
                        w="100%"
                        justify="space-between"
                      >
                        <Box>
                          <Flex>
                            <Box>
                              {' '}
                              <Text
                                fontWeight="600"
                                ml={1}
                                fontSize="sm"
                                color={main}
                              >
                                {t('Carte de crédit')}
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                        <Image maxW="15%" h="auto" src={Carte} />
                      </Flex>
                    </form>

                    <Flex
                      bg={bg}
                      onClick={handlePaypalClick}
                      cursor="pointer"
                      shadow="md"
                      p={4}
                      borderRadius={15}
                      mb={3}
                      mt={2}
                      w="100%"
                      justify="space-between"
                    >
                      <Box>
                        <Flex>
                          <Box>
                            {' '}
                            <Text
                              fontWeight="600"
                              ml={1}
                              fontSize="sm"
                              color={main}
                            >
                              {t('Paypal')}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                      <Image maxW="10%" h="auto" src={Paypal} />
                    </Flex>

                    <Flex
                      bg={bg}
                      cursor="pointer"
                      onClick={handleCashPlusClick}
                      shadow="md"
                      p={3}
                      py={2}
                      borderRadius={15}
                      mt={2}
                      w="100%"
                      justify="space-between"
                    >
                      <Box>
                        <Flex>
                          <Box>
                            {' '}
                            <Text
                              fontWeight="600"
                              pt={1}
                              ml={1}
                              fontSize="sm"
                              color={main}
                            >
                              {t('Cash Plus')}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                      <Image maxW="12%" h="auto" src={CashPlus} />
                    </Flex>
                  </Card>
                  <Flex w="100%">
                    <Button
                      w="100%"
                      leftIcon={<MdDelete />}
                      onClick={handleCancelOrder}
                      colorScheme="red"
                      variant="solid"
                    >
                      {t('Annuler la commande')}
                    </Button>
                  </Flex>
                </>
              )   ) : (
                <Flex w="100%">
                  <Button
                    w="100%"
                    //leftIcon={<MdDelete />}
                    onClick={handleValidateOrder}
                    colorScheme="green"
                    variant="solid"
                  >
                    {t('Valider la commande')}
                  </Button>
                </Flex>
              )}
            {shoporder?.cashReservationId && (
              <Card mt={5} p="4" mb="4" shadow="lg" bg={bg}>
                <Flex w="100%" justify="space-between">
                  {' '}
                  <Heading size="md" mb="1" color={main}>
                    {shoporder?.cashReservationStatus}
                  </Heading>
                  <Image maxW="12%" h="auto" src={CashPlus} />
                </Flex>
                <Text mb="2">
                  {t('Réservation ID :')} #{shoporder?.cashReservationId}
                </Text>

                <Flex align="center">
                  <Link
                    href={shoporder.cashReservationURL}
                    color="purple.500"
                    mr="2"
                    display="flex"
                    alignItems="center"
                  >
                    <Button
                      as="span"
                      colorScheme="purple"
                      display="flex"
                      alignItems="center"
                    >
                      <ExternalLinkIcon mr="1" /> {t('Lien vers la réservation')}
                    </Button>
                  </Link>

                  <Button
                    as="a"
                    href={shoporder?.cashReservationPDF}
                    colorScheme="brand"
                    leftIcon={<DownloadIcon />}
                  >
                    {t('Télécharger le PDF')}
                  </Button>
                </Flex>
              </Card>
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
};
export default ShopOrder;
