import {  InputGroup,
  InputRightElement,  Input,
  
   Stack,Badge,IconButton, Text, useColorModeValue, Flex, Box,ButtonGroup, Button,Checkbox,TableCaption,Table, Tbody, Td, Th, Thead, Tr,Image,Tab, TabList, Tabs, Icon} from '@chakra-ui/react';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Project from 'views/admin/profile/components/Project';
import AdsCard from '../../../../components/sidebar/components/SidebarCard';
import Nft3 from 'assets/img/nfts/Nft3.png';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { t } from 'helpers/TransWrapper';
import Card from 'components/card/Card.js';
import ReactPaginate from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { CheckIcon } from '@chakra-ui/icons';
import { MdWorkspacePremium,MdOpenInNew,MdOutlineEdit,MdDeleteOutline   } from "react-icons/md";
import { SearchIcon } from '@chakra-ui/icons';
const fetchUserAds = async () => {
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  try {
    let url = `${process.env.REACT_APP_API}/ads/all/${currentUser._id}`;
        
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const adData = response.data;
    return adData;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array or handle the error accordingly
  }
};
export default function Projects(props) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );
  const bgItem = useColorModeValue("white","navy.700");
  const [searchQuery, setSearchQuery] = useState('');

  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('all'); // Default is 'all', can be changed to 'published', 'draft', or 'validation'
  const [selectAll, setSelectAll] = useState(false);
  const [pageSize, setPageSize] = useState(10); // Number of ads per page

  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const history = useHistory();
  const [selectedAds, setSelectedAds] = useState([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    if (selectedStatus === 'all') {
      setFilteredAds(ads);
    } else {
      const filtered = ads.filter(ad => ad.status === selectedStatus);
      setFilteredAds(filtered);
    }
  }, [ads, selectedStatus]);
  const handleClickPrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleClickNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const adsData = await fetchUserAds();
      

      if (searchQuery) {
        const filteredData = adsData.filter((item) =>
          item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredAds(filteredData);
      } else {
        setAds(adsData);
        setFilteredAds([]); // Clear filtered data if search query is empty
      }
    };

    fetchData();
  }, [ads, searchQuery]); // Use currentUser._id in the dependency array to fetch data when the user changes or searchQuery changes
  
  const deleteAd = async id => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/ads/ad/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAd = (adId) => {
    setSelectedAds((prevSelectedAds) => {
      if (prevSelectedAds.includes(adId)) {
        return prevSelectedAds.filter((id) => id !== adId);
      } else {
        return [...prevSelectedAds, adId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedAds(selectAll ? [] : displayedAds.map((ad) => ad._id));
  };

  const handleDeleteSelectedAds = async () => {
    if (selectedAds.length === 0) return;
  
    Swal.fire({
      title: t('Êtes-vous sûr(e) ?'),
      text: t("Vous ne pourrez pas revenir en arrière !"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Oui, supprimer !'),
      cancelButtonText: t('Annuler'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await Promise.all(
            selectedAds.map(async (adId) => {
              await deleteAd(adId);
            })
          );
  
          Swal.fire(t('Supprimée !'), t('Vos annonces sélectionnées ont été supprimées.'), t('success'));
          
        } catch (error) {
          console.error(error);
          Swal.fire(t('Erreur'), t('Une erreur s\'est produite lors de la suppression des annonces.'), t('error'));
        }
  
        setSelectedAds([]);
      }
    });
  };
  


  const handleStatusChange = status => {
    setSelectedStatus(status);
  };

  const handleClick = id => {
    history.push(`/ads/${id}`);
  };

  const handleUpdate = id => {
    history.push(`/ad/updateAd/${id}`);
  };

  const handleDelete = id => {
    Swal.fire({
      title: t('Êtes-vous sûr(e) ?'),
      text: t("Vous ne pourrez pas revenir en arrière !"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Oui, supprimer !'),
      cancelButtonText: t('Annuler'),
    }).then(result => {
      if (result.isConfirmed) {
        deleteAd(id);
        Swal.fire(t('Supprimée !'), t('Votre annonce a été supprimé.'), t('success'));
        
      }
    });
  };
  const getColorScheme = (status) => {
    let colorScheme;
  
    switch (status) {
      case t('Validée'):
        colorScheme = 'green';
        break;
      case t('Brouillon'):
        colorScheme = 'orange';
        break;
      case t('Annulée'):
        colorScheme = 'red';
        break;
      default:
        colorScheme = 'blue';
        break;
    }
  
    return colorScheme;
  };
  const totalPages = Math.ceil(filteredAds.length / itemsPerPage);
  const currentPageClamped = Math.max(0, Math.min(currentPage, totalPages - 1));
  const start = currentPageClamped * itemsPerPage;
  const end = start + itemsPerPage;
  const displayedAds = filteredAds.slice(start, end);
  const disabledButtonColor = useColorModeValue('gray.300', 'gray.700');

  // Update the conditions for disabling the previous and next buttons
  const isPreviousDisabled = currentPageClamped === 0;
  const isNextDisabled = currentPageClamped >= totalPages - 1;

  return (
    <Card shadow="lg" mb={{ base: '0px', '2xl': '20px' }}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        {t(`Mes annonces`)}
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        {t(`Ici vous pouvez gérer vos annonces publiées, en brouillons ou en cours de validation`)}
      </Text>
  
      <Box >
      <Flex w='100%' justify='space-between' >  
      <Tabs  colorScheme="purple" variant="enclosed" flex="1">
                <TabList mb={{ base: '4px', md: '0' }}>
            <Tab
            fontSize='sm'
            fontWeight={selectedStatus === t('all') ? 'bold' : 'normal'}
              onClick={() => handleStatusChange(t('all'))}
              isSelected={selectedStatus === t('all')}
            >
              {t("Toutes vos annonces")}
            </Tab>
            <Tab
             fontSize='sm'
            fontWeight={selectedStatus === t('Validée') ? 'bold' : 'normal'}
              onClick={() => handleStatusChange(t('Validée'))}
              isSelected={selectedStatus === t('Validée')}
            >
              {t("Annonces Validées")}
            </Tab>
            <Tab
              fontSize='sm'
            fontWeight={selectedStatus === t('Brouillon') ? 'bold' : 'normal'}
              onClick={() => handleStatusChange(t('Brouillon'))}
              isSelected={selectedStatus === t('Brouillon')}
            >
              {t('Annonces en Brouillon')}
            </Tab>
            <Tab
              fontSize='sm'
            fontWeight={selectedStatus === t('En cours de Validation') ? 'bold' : 'normal'}
              onClick={() => handleStatusChange(t('En cours de Validation'))}
              isSelected={selectedStatus === t('En cours de Validation')}
            >
              {t("Annonces en cours de validation")}
            </Tab>
          </TabList>
          
         
       
        </Tabs>
        <Box w="40%" maxWidth="300px"> {/* Adjust the width of the search bar */}
    <InputGroup>
      <Input
        
        type="text"
        
        variant='outline'
        placeholder={t("Rechercher des annonces")}
        value={searchQuery}
        onChange={handleSearchQueryChange}
      />
      <InputRightElement>
        <Icon
          aria-label="Search"
          as={SearchIcon}
        />
      </InputRightElement>
    </InputGroup>
  </Box>
          </Flex>
    

      </Box>
  
      {selectedAds.length > 0 && (
        <Box mb={4} mt={4} p={4}  borderRadius="md" bg={bgItem} shadow='lg'>
          <Flex justify="space-between" align="center">
            <Text color="red" fontWeight="bold">
              {selectedAds.length} {t('annonce')}{selectedAds.length > 1 ? 's' : ''} {t('sélectionnée')}{selectedAds.length > 1 ? 's' : ''}
            </Text>
            <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteSelectedAds}
            >
              {t('Supprimer')}
            </Button>
          </Flex>
        </Box>
      )}
  
      {filteredAds && filteredAds.length > 0 ? (
        <Box>
        <Box borderWidth="1px" overflowX="auto" style={{ borderTop: 'none' }}>  
        <Table   variant="simple" mt={2} minWidth="100%">
    <Thead>
      <Tr >
        <Th  display={{ base: 'none', md: 'table-cell' }}>   <Checkbox
                colorScheme="blue"
                isChecked={selectAll}
                onChange={handleSelectAll}
              /></Th> {/* Checkbox column */}
        <Th>{t('Titre')}</Th>
        <Th>{t('Catégorie')}</Th>
        <Th>{t('Status')}</Th>
        <Th>{t('Image')}</Th>
        <Th>{t('Date')}</Th>
        <Th>{t('Actions')}</Th>
      </Tr>
    </Thead>
    <Tbody>
      {displayedAds.map((ad) => {
        const colorScheme = getColorScheme(ad.status);
        const createdAt = ad.createdAt; // Example: "2023-12-01T12:34:56.789Z"

const date = new Date(createdAt);
const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() % 100}`;
        return (
          <Tr key={ad._id}>
            <Td display={{ base: 'none', md: 'table-cell' }}>
              <Checkbox
                colorScheme="blue"
                
                isChecked={selectedAds.includes(ad._id)}
                onChange={() => handleSelectAd(ad._id)}
              />
            </Td>
            <Td >{ad.name}</Td>
            <Td>{ad.categoryLabel}</Td>
            <Td>
              <Badge me="16px" ms="auto" colorScheme={colorScheme}>
                {ad.status}
              </Badge>
            </Td>
            <Td>
              <Image
                src={
                  ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
                    ? ad.adPictures[0]
                    : Nft3
                }
                boxSize="40px"
                objectFit="cover"
              />
            </Td>
            <Td>
            {formattedDate}
            </Td>
           
            <Td>
              <Flex justify="space-around" >
                <IconButton icon={<MdOpenInNew />} variant="action" size="sm" onClick={() => handleClick(ad._id)}/>
                <IconButton icon={<MdOutlineEdit />} variant="action" size="sm" onClick={() => handleUpdate(ad._id)}/>
                <IconButton icon={<MdDeleteOutline />} colorScheme="red" size="sm" onClick={() => handleDelete(ad._id)}/>

             
             
              </Flex>
            </Td>
          </Tr>
        );
      })}
    </Tbody>
  </Table>
</Box>
          <Flex justify="space-between" align="center" mt="4">
            <Text fontSize="sm" color="gray.500">
              {t('Page')} {currentPageClamped + 1} {t('sur')} {totalPages}
            </Text>
            <ButtonGroup>
              <Button
                colorScheme="blue"
                size="sm"
                leftIcon={<ChevronLeftIcon />}
                onClick={handleClickPrevious}
                isDisabled={currentPage === 0}
                _disabled={{ bg: disabledButtonColor, cursor: 'not-allowed' }}
              >
                {t('Précédent')}
              </Button>
              <Button
                colorScheme="blue"
                size="sm"
                rightIcon={<ChevronRightIcon />}
                onClick={handleClickNext}
                isDisabled={currentPage === totalPages - 1}
                _disabled={{ bg: disabledButtonColor, cursor: 'not-allowed' }}
              >
                {t('Suivant')}
              </Button>
            </ButtonGroup>
          </Flex>
        </Box>
      ) : (
        <Box>
          <Text fontWeight={300} fontFamily="body" mb={15}>
            {t(`Aucune annonce n'a été trouvée pour le moment`)}. <br></br> {t(`Ajoutez une annonce via le bouton "Annonce gratuite"`)}
          </Text>
          <AdsCard />
        </Box>
      )}
    </Card>
  );
  
}
