import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Text,
  Spinner,
  Input,
  Button,
  HStack,
  useColorModeValue,
  InputLeftElement,
  InputGroup,
  Checkbox
} from '@chakra-ui/react';
import { FiMoreVertical, FiEdit, FiTrash, FiRepeat } from 'react-icons/fi';
import { SearchIcon } from '@chakra-ui/icons';
import Swal from 'sweetalert2';
import {

    useHistory,
  } from 'react-router-dom';
const ListTransactions = () => {
    const history = useHistory();

  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const brand = useColorModeValue('brand.500', 'pink.200');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgItem = useColorModeValue('white', 'navy.700');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const itemsPerPage = 10;
  const fetchTransactions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/transactions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      let data = response.data.transactions;
 
      setTransactions(data);
      setFilteredTransactions(data);

    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteTransaction = async id => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/transactions/${id}/archive`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      fetchTransactions();
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectTransaction = TransactionId => {
    setSelectedTransactions(prevSelectedTransactions => {
      if (prevSelectedTransactions.includes(TransactionId)) {
        return prevSelectedTransactions.filter(id => id !== TransactionId);
      } else {
        return [...prevSelectedTransactions, TransactionId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedTransactions(selectAll ? [] : paginatedTransactions.map(transaction => transaction._id));
  };

  const handleDeleteSelectedTransactions = async () => {
    if (selectedTransactions.length === 0) return;

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await Promise.all(
            selectedTransactions.map(async TransactionId => {
              await deleteTransaction(TransactionId);
            })
          );

          Swal.fire(
            'Supprimée !',
            'Vos transactions sélectionnées ont été archives.',
            'success'
          );
        } catch (error) {
          console.error(error);
          Swal.fire(
            'Erreur',
            "Une erreur s'est produite .",
            'error'
          );
        }

        setSelectedTransactions([]);
      }
    });
  };
  const handleDelete = id => {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(result => {
      if (result.isConfirmed) {
        deleteTransaction(id);
        Swal.fire('Supprimée !', 'Votre transaction a été archivée.', 'success');
      }
    });
  };
  const handleView = name => {
    history.push(`/transaction/transaction/${name}}`)
  };
  useEffect(() => {
    

    fetchTransactions();
  }, []);

  const handleSearch = event => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = transactions.filter(transaction =>
      transaction.firstName?.toLowerCase().includes(value)
    );
    setFilteredTransactions(filtered);
    setCurrentPage(1); // Reset to the first page
  };

  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
  const paginatedTransactions = filteredTransactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  const handlePageChange = page => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={10}>
        <Spinner size="lg" />
        <Text mt={4} fontSize="lg" fontWeight="semibold">
          Loading transactions...
        </Text>
      </Box>
    );
  }

  return (
    <Box p={6} minH="100vh">
      <HStack justifyContent="space-between" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">
          Liste des Transactions 
        </Text>

        <InputGroup width="300px">
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input
            variant="search"
            fontSize="sm"
            value={searchTerm}
            onChange={handleSearch}
            bg={inputBg}
            color={inputText}
            fontWeight="500"
            _placeholder={{ color: 'gray.400', fontSize: '14px' }}
            borderRadius="30px"
            placeholder="Rechercher par Titre..."
          />
        </InputGroup>
     
      </HStack>
      {selectedTransactions.length > 0 && (
        <Box mb={4} mt={4} p={4} borderRadius="md" bg={bgItem} shadow="lg">
          <Flex justify="space-between" align="center">
            <Text color="red" fontWeight="bold">
              {selectedTransactions.length} transactions
              {selectedTransactions.length > 1 ? 's' : ''} sélectionnée
              {selectedTransactions.length > 1 ? 's' : ''}
            </Text>
            <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteSelectedTransactions}
            >
              Supprimer
            </Button>
          </Flex>
        </Box>
      )}
      {filteredTransactions.length === 0 ? (
        <Box textAlign="center" mt={10}>
          <Text fontSize="lg" fontWeight="semibold">
            No Transactions available.
          </Text>
        </Box>
      ) : (
        <>
          <Box overflowX="auto" borderRadius="md" boxShadow="md">
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                <Th width="20px">
                    {' '}
                    <Checkbox
                      colorScheme="blue"
                      isChecked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </Th>
                  <Th>ID</Th>
                  <Th>Owner</Th>
                  <Th>Ad Promo</Th>
                  <Th>MemberShip</Th>
                  <Th>Payment Method</Th>
                  <Th>Amount</Th>
                  <Th>Creation date</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedTransactions.map(transaction => {
                  const capitalizeFirstLetter = string => {
                    // Handle empty or undefined strings
                    return string.charAt(0).toUpperCase() + string.slice(1);
                  };

                  const user = users[transaction.owner] || {};
                  

                  return (
                    <Tr key={transaction._id}>
                          <Td display={{ base: 'none', md: 'table-cell' }}>
                        <Checkbox
                          colorScheme="blue"
                          isChecked={selectedTransactions.includes(transaction._id)}
                          onChange={() => handleSelectTransaction(transaction._id)}
                        />
                      </Td>
                      <Td isTruncated maxWidth="100px">{transaction?._id || 'N/A'}</Td>
                      <Td isTruncated maxWidth="100px">
                        {transaction.firstName + ' ' + transaction.lastName || 'No name'}
                      </Td>
                      <Td isTruncated maxWidth="100px">
                        {transaction.adPromo}
                      </Td>
                      <Td isTruncated maxWidth="150px">
                        {transaction.ads.length || 'No ads'}
                      </Td>
                      <Td>
                        {capitalizeFirstLetter(transaction.memberShip) || 'Gratuit'}
                      </Td>
                      <Td>
                        {transaction.paymentMethod }
                      </Td>
                      <Td>
                      {transaction.amount }
                                            </Td>
                      <Td>
                        {new Date(transaction.createdAt).toLocaleDateString() }
                      </Td>
                    
                      <Td>
                        <Menu>
                          <Tooltip label="Actions" placement="top">
                            <MenuButton
                              as={IconButton}
                              icon={<FiMoreVertical />}
                              variant="action"
                              aria-label="Options"
                            />
                          </Tooltip>
                          <MenuList>
                            <MenuItem   onClick={() => handleView(transaction.name)} icon={<FiEdit />}>Voir</MenuItem>
                            
                            <MenuItem icon={<FiTrash />}
                              onClick={() => handleDelete(transaction._id)}
                            
                            >Supprimer</MenuItem>
                        
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <HStack justifyContent="center" mt={4}>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                colorScheme={index + 1 === currentPage ? 'brand' : 'gray'}
              >
                {index + 1}
              </Button>
            ))}
          </HStack>
        </>
      )}
    </Box>
  );
};

export default ListTransactions;
