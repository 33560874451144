import { Box, Text, Flex, IconButton, Tooltip, Icon, HStack, VStack, Image, Grid, useColorModeValue,Center, Wrap, WrapItem  } from '@chakra-ui/react';
import {
  FaShippingFast,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaCreditCard, FaMoneyBill, FaCcPaypal
} from 'react-icons/fa';
import { BsCash  } from "react-icons/bs";
import { RiVisaFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { t } from "helpers/TransWrapper";
import Card from 'components/card/Card.js';
const PaymentMethodIcon = ({ icon, label, color }) => (
  <VStack spacing="2" alignItems="center">
    <Icon color={color} as={icon} boxSize="8" />
    
  </VStack>
);
const ShopInfoCard = ({
  description,
  phone,
  email,
  address,
  delivers,
  facebook,
  instagram,
  twitter,
  tags,
  paymentMethods
}) => {
  const textColor = useColorModeValue('brand.500', 'gray.300');
  const delivery = useColorModeValue('brand.500', 'purple.300');
  const twitterColor = useColorModeValue('white', 'black');
  const twitterbg = useColorModeValue('black', 'white');

  return (
    <Card p="6"       mb={{ base: '20px', lg: '5px' }}
    shadow="lg">
      <VStack spacing="4" align="start">
        <Text fontWeight="bold" fontSize="xl">
          {t("Informations sur la boutique")}
        </Text>
        <Text fontSize="md" color={textColor} fontStyle="italic">
  {description}
</Text>
        <Grid templateColumns="0.8fr 1fr" gap="2" alignItems="center">
          <Flex align="center">
            <Text fontWeight="bold">{t('Téléphone')}</Text>
          </Flex>
          <Flex align="center">
            <Icon as={FaPhone} mr="2" color="green.500" />
            <Text>{phone}</Text>
          </Flex>
          <Flex align="center">
            <Text fontWeight="bold">{t("Email")}</Text>
          </Flex>
          <Flex align="center">
            <Icon as={FaEnvelope} mr="2" color="blue.500" />
            <Text>{email}</Text>
          </Flex>
          <Flex align="center">
            <Text fontWeight="bold">{t('Adresse')}</Text>
          </Flex>
          <Flex align="center">
            <Icon as={FaMapMarkerAlt} mr="2" color="red.500" />
            <Text>{address}</Text>
          </Flex>

        </Grid>
        <Flex alignItems="center" >
        {/* Other sections */}
  
        <Wrap>
          {tags?.map((tag, index) => (
            <WrapItem key={index}>
              <Box
                bg={delivery}
                color="gray.100"
                px="2"
                py="1"
                borderRadius="md"
                mr="2"
              >
                {tag}
              </Box>
            </WrapItem>
          ))}
        </Wrap>
      </Flex>
        {delivers && (
          <Flex align="center">
          <Icon as={FaShippingFast} boxSize="40px" mr="2" color={delivery} />
            <Box>
              <Text fontWeight="bold">{t("Livraison disponible")}</Text>
              <Text fontSize="sm" color="gray.600">
                {t("Expédition rapide et sécurisée")}
              </Text>
            </Box>
          </Flex>
        )}
        <Flex w="100%" alignItems="center" justify='space-between'>
          <Text fontWeight="bold">{t('Modes de paiement')}</Text>
          <HStack spacing="5" align="center">
            {paymentMethods?.map((method, index) => {
              switch (method) {
                case 'Paypal':
                  return (
                    <PaymentMethodIcon
                      key={index}
                      color='blue.400'
                      icon={FaCcPaypal}
                      label={t("PayPal")}
                    />
                  );
                case 'Cash a la livraison':
                  return (
                    <PaymentMethodIcon
                      key={index}
                      color='red.400'
                      icon={BsCash}
                      label={t("Cash")}
                    />
                  );
                case 'Carte bancaire':
                  return (
                    <PaymentMethodIcon
                      key={index}
                      color='orange.400'
                      icon={RiVisaFill}
                      label={t("Credit Card")}
                    />
                  );
                default:
                  return null;
              }
            })}
          </HStack>
        </Flex>

      </VStack>
      <Center   mt="4">
  <HStack spacing="5">
    {facebook && (
      <Tooltip label="Facebook" placement="top">
        <IconButton
         borderRadius='full'
          aria-label={t("Facebook")}
          icon={<FaFacebook />}
          colorScheme="facebook"
          onClick={() => window.open(facebook)}
        />
      </Tooltip>
    )}
    {instagram && (
      <Tooltip label="Instagram" placement="top">
        <IconButton
         borderRadius='full'
          aria-label={t("Instagram")}
          icon={<FaInstagram />}
          colorScheme="pink"
          onClick={() => window.open(instagram)}
        />
      </Tooltip>
    )}
    {twitter && (
      <Tooltip label="Twitter" placement="top">
        <IconButton
        borderRadius='full'
          aria-label={t("Twitter")}
          icon={<FaXTwitter />}
          color={twitterColor}
          bg={twitterbg}
          _hover={{
    bg: twitterbg, // Define your desired hover background color
    color: twitterColor, // Define your desired hover text color
  }}
          onClick={() => window.open(twitter)}
        />
      </Tooltip>
    )}
  </HStack>
</Center>
    </Card>
  );
};

export default ShopInfoCard;
