import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import './NFT.css';
import moment from 'moment';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';

// Custom components
import Banner from 'views/admin/marketplace/components/Banner';

import NFT from 'components/card/NFT';
import Card from 'components/card/Card.js';

// Assets
import HistoryItem from 'views/ads/recentAds/components/HistoryItem';

import Nft3 from 'assets/img/nfts/Nft3.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import { t } from 'helpers/TransWrapper';
import { useColorMode } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
import { createSelector } from 'reselect';
import { MdWorkspacePremium, MdStars } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { addAds } from '../../../state/auth/authSlice';

export default function Marketplace() {
  // Chakra Color Mode
  const dispatch = useDispatch();
  const TestAds = state => state.auth.Test;
  // const TestAds = useSelector((state) => state.Test);
  const [likers, setLikers] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const [likedAds, setLikedAds] = useState([]);
  const premium = useColorModeValue('#6f432a', 'gold');
  const liked = useColorModeValue('#960018', 'gold');
  const [adsCount, setAdsCount] = useState();
  const [adData, setAdData] = useState([]);
  const [like, setLike] = useState();
  const [SelectedAd, setSelectedAd] = useState([]);
  const history = useHistory();
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [isLoading, setIsLoading] = useState(false);
  const NFTMemoized = React.memo(NFT);
  const [user, setUser] = useState({});
  const [promotedAds, setPromotedAds] = useState([]);

  const processedTestAds = createSelector([TestAds], testAds => {
    return testAds;
  });
  const selectProcessedTestAds = useSelector(processedTestAds);

  console.log(JSON.stringify(adData)); // JSON.stringify
  const getLikers = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${id}/like`
      );

      const likerIds = response.data;

      const likerData = [];

      for (const likerId of likerIds) {
        const userDataResponse = await axios.get(
          `${process.env.REACT_APP_API}/users/${likerId}`
        );
        const userData = userDataResponse.data;
        likerData.push(userData);

        console.log('User Data:', userData); // Log the user data for debugging
      }

      const profilePictures = likerData.map(liker => liker.profilePicture);

      console.log('Profile Pictures:', profilePictures); // Log profile pictures for debugging

      return profilePictures;
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };
  useEffect(() => {
    const fetchPromoAds = async () => {
      try {
        // Fetch ads and user data in a single request
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/promoted/ads`
        );
        const ads = response.data;

        const adData = await Promise.all(
          ads.map(async ad => {
            const userResponse = await axios.get(
              `${process.env.REACT_APP_API}/users/${ad.userId}`
            );
            const user = userResponse.data;
            return user ? { ad, user } : null;
          })
        );

        console.log('promoted ads', adData);
        setPromotedAds(adData);
      } catch (error) {
        console.error(error);
      }  
    };

    fetchPromoAds();
  }, []);
  useEffect(() => {
    const likedAds = async () => {
      try {
        // Fetch ads and user data in a single request
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/home/likedAds`
        );
        const likedAds = response.data;

        setLikedAds(likedAds);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    likedAds();
  }, []);
  const PromotedAdsWrapper = ({ ad, user }) => {
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <HistoryItem
        id={ad._id}
        name={ad.name}
        click={handleClick}
        author={user.firstName + ' ' + user.lastName}
        date={ ad.validatedAt ? moment(ad.validatedAt).fromNow() : moment(ad.createdAt).fromNow()}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        price={ad.price === 0 ? t('Non défini') : `${ad.price} MAD`}
      />
    );
  };
  const NFTWrapper = ({ ad, user }) => {
    const [likersForAd, setLikersForAd] = useState([]);

    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }

      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <NFT
      id={ad._id}
      discountShop={ad.discountShop}
      isShopPromoted={ad.isShopPromoted}
      promotedPrice={`${ad.promotedPrice} MAD`}
      bidders={likersForAd}
      name={ad.name}
      author={`${user.firstName} ${user.lastName}`}
      image={
        ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
          ? ad.adPictures[0]
          : Nft3
      }
      category={ad.categoryLabel}
      propart={ad?.ProPart}
      currentbid={ad.price === 0 ? t('Non défini') : `${ad.price} MAD`}
      Click={handleClick}
      city={ad.city}
      dateCreated={ ad.validatedAt ? moment(ad.validatedAt).fromNow() : moment(ad.createdAt).fromNow()}
      nbrPictures={`${ad.adPictures.length} images `}
    />
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectProcessedTestAds.length < 1) {
        setIsLoading(true);

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/ads/Adsm/home`
          );
          const ads = response.data;
          
          const adData = await Promise.all(
            ads.map(async ad => {
              const userResponse = await axios.get(
                `${process.env.REACT_APP_API}/users/${ad.userId}`
              );
              const user = userResponse.data;
              return user ? { ad, user } : null;
            })
          );

          const filteredAdData = adData.filter(Boolean);
          dispatch(addAds(filteredAdData));
          setAdData(filteredAdData);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [TestAds.length]);
  const countAds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/count/ads`
      );
      setAdsCount(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  // useEffect(() => {

  // }, [window.location.href, setIsLoading]);

  useEffect(() => {
    countAds();
  }, []);

  const getAdById = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/ad/${id}`
      );
      setSelectedAd(response.data);

      history.push(`/ads/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  // const fetchAds = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API}/ads`);
  //     setAds(response.data);

  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchAds();
  // }, []);

  const items = Array.from({ length: 12 });

  const { colorMode } = useColorMode();

  const bgColor = colorMode === 'dark' ? 'rgb(17, 28, 68)' : 'gray.200';

  console.log('ad', adData);
  return (
    <Box pt={{ base: '100px', md: '80px', xl: '80px' }}>
      {' '}
      {/* Main Fields */} 
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
        gap={{ base: '20px', xl: '20px' }}
        display={{ base: 'block', xl: 'grid' }}
      >

        <Flex flexDirection="column">
          <Card p="5px" mb="20px">
            <Flex
              align={{ sm: 'flex-start', lg: 'center' }}
              justify="space-between"
              w="100%"
              px="22px"
              py="18px"
            >
              <Text color={textColor} fontSize="xl" fontWeight="600">
                {t('Annonces Premium')}
              </Text>
              <MdWorkspacePremium color={premium} size={40} />
            </Flex>
            <Box overflowY="auto" height={promotedAds.length === 0 ? "auto" : "auto"}>
              {' '}
              {promotedAds.map(({ ad, user }) => {
                return <PromotedAdsWrapper ad={ad} user={user} />;
              })}
            </Box>
          </Card>
          <Card p="0px" mb="20px">
            <Flex
              align={{ sm: 'flex-start', lg: 'center' }}
              justify="space-between"
              w="100%"
              px="22px"
              py="18px"
              direction={{ base: 'column', md: 'row' }}
            >
              <Text color={textColor} fontSize="xl" fontWeight="600">
                {t('Les mieux notées')}{' '}
              </Text>
            <MdWorkspacePremium color={liked} size={40} />
            </Flex>
            <SimpleGrid
              overflowY="auto"
              height="1720px"
              columns={{ base: 1, md: 1 }}
              gap="20px"
            >
              {likedAds && likedAds.length > 0
                ? likedAds.map(ad => {
                    const handleClick = () => {
                      getAdById(ad._id); // Get the ad by its id when the component is clicked
                    };

                    return (
                      <Flex key={ad._id}>
                        <HistoryItem
                          id={ad._id}
                          name={ad.name}
                          click={handleClick}
                          author={ad.firstName + ' ' + ad.lastName}
                          date={ ad.validatedAt ? moment(ad.validatedAt).fromNow() : moment(ad.createdAt).fromNow()}
                          image={
                            ad.adPictures[0] &&
                            Object.keys(ad.adPictures[0]).length
                              ? ad.adPictures[0]
                              : Nft3
                          }
                          price={
                            ad.price === 0 ? t('Non défini') : `${ad.price} MAD`
                          }
                        />
                      </Flex>
                    );
                  })
                : null}
            </SimpleGrid>
          </Card>
        </Flex>
        <Flex
          flexDirection="column"
          gridArea={{ xl: '4 / 3 / 1 / 1', '2xl': '1 / 1 / 2 / 2' }}
        >
<Banner />
          <Flex direction="column">
            <Card p={1} mb={5}>
              {' '}
              <Flex
                align={{ sm: 'flex-start', lg: 'center' }}
                justify="space-between"
                w="100%"
                px="22px"
                py="18px"
              >
                <Text color={textColor} fontSize="xl" fontWeight="600">
                  {t('Ajoutées récemment')}
                  {/* ({adsCount}) */}
                </Text>{' '}
                <NavLink to="/ad/recentAds">
                  {' '}
                  <Button variant="darkBrand"> {t('Voir plus')} </Button>{' '}
                </NavLink>{' '}
              </Flex>{' '}
            </Card>{' '}
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              gap="20px"
              mb={{ base: '20px', xl: '0px' }}
            >
              <>
                {isLoading === false ? (
                  selectProcessedTestAds.map(({ ad, user }) => {
                    const likersForAd = getLikers(ad._id);
                    console.log('likers', likersForAd);

                    const handleClick = () => {
                      getAdById(ad._id);
                    };

                    return (
                      <LazyLoad height={200} offset={100}>
                        <Flex key={ad._id} className="nft">
                          <NFTWrapper ad={ad} user={user} />
                        </Flex>
                      </LazyLoad>
                    );
                  })
                ) : (
                  <>
                    {items.map((_, index) => {
                      return (
                        <Flex key={index} className="nft">
                          <Box borderRadius="lg" overflow="hidden" bg={bgColor}>
                            <Skeleton
                              height={{ base: '200px', md: '200px' }}
                              width="600px"
                              startColor={
                                colorMode === 'dark' ? 'gray.700' : 'gray.200'
                              }
                              endColor={
                                colorMode === 'dark' ? 'gray.600' : 'gray.300'
                              }
                            />
                            {/* <Box height="" width="600px"></Box> */}
                            <Box p="6">
                              <Box
                                mt="1"
                                fontWeight="semibold"
                                as="h4"
                                lineHeight="tight"
                                isTruncated
                                color={textColor}
                              >
                                <Skeleton
                                  height="10px"
                                  width={{ base: '50%', md: '30%' }}
                                  mb={2}
                                  startColor={
                                    colorMode === 'dark'
                                      ? 'gray.700'
                                      : 'gray.200'
                                  }
                                  endColor={
                                    colorMode === 'dark'
                                      ? 'gray.600'
                                      : 'gray.300'
                                  }
                                />
                                <Box
                                  bg={bgColor}
                                  height="20px"
                                  width="50%"
                                  mb={2}
                                ></Box>
                                <Skeleton
                                  height="10px"
                                  width={{ base: '20%', md: '10%' }}
                                  mr={2}
                                  startColor={
                                    colorMode === 'dark'
                                      ? 'gray.700'
                                      : 'gray.200'
                                  }
                                  endColor={
                                    colorMode === 'dark'
                                      ? 'gray.600'
                                      : 'gray.300'
                                  }
                                />
                              </Box>
                              <Box
                                d="flex"
                                alignItems="baseline"
                                color={textColor}
                                fontSize="sm"
                              >
                                <Box
                                  bg={bgColor}
                                  height="10px"
                                  width="20%"
                                  mr={2}
                                ></Box>
                                <Box
                                  bg={bgColor}
                                  height="10px"
                                  width="30%"
                                  mr={2}
                                ></Box>
                              </Box>
                            </Box>
                          </Box>
                        </Flex>
                      );
                    })}
                  </>
                )}
              </>
            </SimpleGrid>{' '}
            <NavLink to="/ad/recentAds">
              {' '}
              <Button w="100%" mt={3} variant="darkBrand">
                {' '}
                {t('Voir plus')}{' '}
              </Button>{' '}
            </NavLink>{' '}
          </Flex>{' '}
        </Flex>{' '}
      </Grid>{' '}
    </Box>
  );
}
