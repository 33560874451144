import React, { useState, useEffect, useRef } from 'react';
import { t } from "helpers/TransWrapper";
import { NavLink, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  ThemeProvider,
  theme,
  Link,
  Box,
  Th,
  Tr,
  Td,
  Tbody,
  Table,
  Thead,
  VStack,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  Image,
  HStack,
  Divider,
  useDisclosure,
  CloseButton,
  Grid,
  GridItem,
  Progress,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  Wrap,
  WrapItem,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { FaArrowLeft, FaHeart, FaShareAlt, FaTimes } from 'react-icons/fa'; // Import icons
import Card from 'components/card/Card.js';
import {
  MdCameraEnhance,
  MdCategory,
  MdPerson,
  MdSearch,
  MdHomeFilled,
  MdDelete,
  MdImage,
  MdCheck,
  MdOutlineShoppingBag,
} from 'react-icons/md';
import { FaTimesCircle  } from 'react-icons/fa';
import { TbRepeat, TbRocket } from 'react-icons/tb';
const Cancel = () => {
const history = useHistory();
return (
    <Box p={5}>
      <VStack spacing={8} align="center">
        <FaTimesCircle size={50} color="red" />
        <Heading as="h2" size="xl">
          {t('Paiement Annulé')}
        </Heading>
        <Text fontSize="lg">{t('Votre paiement a été annulé.')}</Text>
        <Text fontSize="md">
          {t('Si vous souhaitez effectuer le paiement, veuillez réessayer.')}
        </Text>
        <Button
          colorScheme="teal"
          size="lg"
          onClick={() => {
           
            history.goBack();
          }}
        >
          {t('Retour au Panier')}
        </Button>
      </VStack>
    </Box>
  );
}
export default Cancel;
