import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from "helpers/TransWrapper";
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import Card from 'components/card/Card.js';

const Confirmation = ({ match }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();
  
    const handleConfirmation = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/auth/confirm/${match.params.token}`);
        setIsLoading(false);
        history.push('/auth/login');
      } catch (error) {
        setIsLoading(false);
        setErrorMessage('Error confirming email');
      }
    };
  
    return (
        <Card height="100%" >  <Box textAlign="center" marginTop={5} h='100%'>
        <Heading as="h1" size="xl" marginBottom={5} >
        {t('Nous avons a besoin de confirmer votre adresse e-mail')}        </Heading>
        <Box  marginX="auto">
          <Text fontSize={20} marginBottom={5}>
          {t(`Nous souhaitons vous informer qu'un e-mail de confirmation a été envoyé à votre adresse. Nous vous invitons à vérifier votre boîte de réception afin de finaliser les démarches nécessaires. Si vous ne trouvez pas ce message, n'hésitez pas à nous contacter. Notre équipe technique est à votre disposition pour vous accompagner.`)}          </Text>
         
        </Box>
      </Box></Card>
    
    );
  };
  
  export default Confirmation;

