import React from "react";
import Chart from "react-apexcharts";

const ColumnChart = ({ chartData, chartOptions, chartType }) => {
  return (
    <Chart
      options={chartOptions}
      series={chartData}
      type={chartType || 'bar'}
      width="100%"
      height="100%"
    />
  );
};

export default ColumnChart;
