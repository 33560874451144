import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Checkbox,
  Button,
  VStack,
  HStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Spinner,
  Icon,
  InputLeftElement,
  InputGroup,
  IconButton,
} from '@chakra-ui/react';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { SearchIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { toast } from 'react-toastify';

const AudienceSegmentation = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [audienceName, setAudienceName] = useState('');
  const [audiences, setAudiences] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10); // You can adjust the limit per page

  const bgColor = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const user = localStorage.getItem('user-token');
  const CurrentUser = JSON.parse(user);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/users/get/audience`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            params: { page: currentPage, limit },
          }
        );
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        toast.error('Failed to fetch users.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, limit]);
  const fetchAudiences = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/audiences`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setAudiences(response.data.audiences);
   
    } catch (error) {
      toast.error('Failed to fetch audiences.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error fetching audiences:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {


    fetchAudiences();
  }, []);

  const handleUserSelect = userId => {
    setSelectedUsers(prev =>
      prev.includes(userId)
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleCreateAudience = async () => {
    if (!audienceName.trim()) {
      toast.error('Please enter a name for the audience.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (selectedUsers.length === 0) {
      toast.error('Please select at least one user.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const newAudience = {
      name: audienceName,
      users: selectedUsers,
      createdBy: CurrentUser._id,
    };
    try {
      // Call API to create the audience
      const response = await axios.post(
        `${process.env.REACT_APP_API}/audiences`,
        newAudience,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    
      fetchAudiences(); // Ensure the audiences state is updated      setAudienceName('');
      setSelectedUsers([]);
      setAudienceName('')
      toast.success('Audience created successfully.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error('Failed to create audience.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error creating audience:', error);
    }
  };

  const handleSearch = e => {
    setSearch(e.target.value);
    const query = e.target.value.toLowerCase();
    setFilteredUsers(
      users.filter(
        user =>
          user.firstName.toLowerCase().includes(query) ||
          user.lastName.toLowerCase().includes(query) ||
          user.email.toLowerCase().includes(query)
      )
    );
  };

  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDeleteAudience = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API}/audiences/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setAudiences(prevAudiences => prevAudiences.filter(audience => audience.id !== id));
      toast.success('Audience deleted successfully.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchAudiences(); // Ensure the audiences state is updated
    } catch (error) {
      console.error('Error deleting audience:', error);
      toast.error('Failed to delete audience.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={10}>
        <Spinner size="lg" />
        <Text mt={4} fontSize="lg" fontWeight="semibold">
          Loading users...
        </Text>
      </Box>
    );
  }

  return (
    <Box p={6} minH="100vh">
      <Text fontSize="2xl" fontWeight="bold" mb={6} color={textColor}>
        Audience Segmentation
      </Text>

      {/* User Selection */}
      <Box bg={bgColor} p={4} borderRadius="md" boxShadow="md" mb={6}>
        <HStack justifyContent="space-between" mb={4} w="100%">
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Create an Audience
          </Text>

          <InputGroup width="300px">
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
              variant="search"
              fontSize="sm"
              value={search}
              onChange={handleSearch}
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius="30px"
              placeholder="Rechercher un utilisateur..."
            />
          </InputGroup>
        </HStack>

        <VStack align="start" spacing={4}>
          <Input
            placeholder="Audience Name"
            value={audienceName}
            onChange={e => setAudienceName(e.target.value)}
          />

          <Box maxH="500px" overflowY="auto" width="100%">
            <Table size="sm" mt={4} width="100%">
              <Thead position="sticky" top={0} bg={bgColor} zIndex="docked">
                <Tr>
                  <Th>Select</Th>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Role</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredUsers.map(user => (
                  <Tr key={user._id}>
                    <Td>
                      <Checkbox
                        isChecked={selectedUsers.includes(user._id)}
                        onChange={() => handleUserSelect(user._id)}
                      />
                    </Td>
                    <Td>{`${user.firstName} ${user.lastName}`}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      {user.ProPart === 'pro'
                        ? 'Professionnel'
                        : 'Particulier' || 'N/A'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {/* Pagination Controls */}
            <HStack justify="space-between" mt={4}>
              <Button
                pv="2"
                variant="brand"
                isDisabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </Button>
              <Text>
                Page {currentPage} of {totalPages}
              </Text>
              <Button
                pv="2"
                variant="brand"
                isDisabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Button>
            </HStack>
          </Box>

          <Button
            colorScheme="green"
            leftIcon={<FaPlus />}
            onClick={handleCreateAudience}
            isDisabled={!audienceName.trim() || selectedUsers.length === 0}
          >
            Create Audience
          </Button>
        </VStack>
      </Box>

      {/* Created Audiences List */}
      <Box
        bg={bgColor}
        p={4}
        borderRadius="md"
        boxShadow="md"
        maxH="500px"
        overflowY="auto"
        width="100%"
      >
        <Text fontSize="lg" fontWeight="bold" mb={4}>
          Created Audiences
        </Text>
        <Table size="sm" mt={4} width="100%">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Admin</Th>

              <Th>Name</Th>
              <Th>Users</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {audiences.map(audience => ( 
              <Tr key={audience._id}>
                <Td>{audience._id}</Td>
                <Td>{audience.createdBy.firstName}</Td>
                <Td>{audience.name}</Td>
                <Td>{audience.users.length} Users</Td>
                <Td>
                  <IconButton
                    aria-label="Delete Audience"
                    icon={<FaTrashAlt />}
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleDeleteAudience(audience._id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default AudienceSegmentation;
