import {
  Flex,
  useColorModeValue,
  Divider,
  Box,
  Grid,
  Text,
  Heading,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Link 
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React from 'react';
import { t } from "helpers/TransWrapper";

export default function Chat() {
  const bg = useColorModeValue('white', 'navy.800');
  return (
    <Flex flexDirection='column' justify='space-between' textAlign='start'  maxW="800px" mx="auto" mt="4" p="4" boxShadow="md" borderRadius="lg" bg="white">

      <Text fontSize="lg" lineHeight="tall" textAlign='justify' >
        {t(`Jindoo.ma est une application web gratuite d'annonces créée par l'entreprise BENIFIT.IO.
        BENIFIT.IO est une entreprise enregistrée au Maroc sous le numéro de registre de commerce 51939. Le siège social de BENIFIT.IO est situé à Rabat, Maroc.
        Pour contacter BENIFIT.IO, veuillez envoyer un email à <Link href="mailto:contact@benifit.io">contact@benifit.io</Link>.
        Le directeur de la publication pour Jumatik.ma est AIT BENI IFIT Achraf, en qualité de représentant légal de BENIFIT.IO.
        L'hébergement de Jumatik.ma est assuré par LWS.
        Jumatik.ma a été développé par BENIFIT.IO et est la propriété exclusive de JUMATIK SARL.
        Tous les contenus publiés sur Jumatik.ma sont la propriété de leurs auteurs respectifs.
        BENIFIT.IO ne sera pas responsable des dommages directs ou indirects causés par l'utilisation de Jumatik.ma.
        Tous les litiges découlant de ou liés à l'utilisation de Jumatik.ma seront soumis à la compétence exclusive des tribunaux marocains.
        Ces mentions légales sont fournies à titre indicatif et peuvent être sujettes à des modifications ultérieures.`)}
      </Text>
      <Box mt="4">
        <Heading as="h3" fontSize="lg" mb="2">
          {t('Coordonnées de JUMATIK SARL :')}
        </Heading>
        <UnorderedList pl="4">
          <ListItem><strong>{t('Nom de la société')}</strong>: {t('JUMATIK SARL')}</ListItem>
          <ListItem><strong>{t('Siège social')}</strong>: {t('4,RUE 18 ,2EME ETAGE N°2 AMAL ,SIDI BERNOUSSI  CASABLANCA')}</ListItem>
          <ListItem><strong>{t('N° du RC')}</strong>: 528593 </ListItem>
          <ListItem><strong>{t('IF')}</strong> {t('(Identifiant Fiscal)')}: 51621257 </ListItem>
          <ListItem><strong>{t('ICE')}</strong> {t(`(Identifiant Commun de l'Entreprise)`)}: 002966921000001</ListItem>
          <ListItem><strong>{t('Patent')}</strong>: 31651816 </ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  );
}
