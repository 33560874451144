import {
    InputGroup,
    InputRightElement,
    Input,
    Stack,
    Badge,
    IconButton,
    Text,
    useColorModeValue,
    Flex,
    Box,
    ButtonGroup,
    Button,
    Checkbox,
    TableCaption,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Image,
    Tab,
    TabList,
    Tabs,
    Icon,
  } from '@chakra-ui/react';
  import axios from 'axios';
  import React, { useState, useEffect, useMemo } from 'react';
  import Project from 'views/admin/profile/components/Project';
  import AdsCard from '../../../../components/sidebar/components/SidebarCard';
  import Nft3 from 'assets/img/nfts/Nft3.png';
  import { useHistory } from 'react-router-dom';
  import Swal from 'sweetalert2';
  import { t } from 'helpers/TransWrapper';
  import Card from 'components/card/Card.js';
  import ReactPaginate from 'react-paginate';
  import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
  import { CheckIcon } from '@chakra-ui/icons';
  import {
    MdWorkspacePremium,
    MdOpenInNew,
    MdOutlineEdit,
    MdDeleteOutline,
    MdCancel,
    MdDone,
    MdBallot,
    MdSettings,
    MdPaid 
  } from 'react-icons/md';
  import { SearchIcon } from '@chakra-ui/icons';
  import { ExternalLinkIcon, DownloadIcon } from '@chakra-ui/icons';
  
  export default function ShopOrders(props) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const cardShadow = useColorModeValue(
      '0px 18px 40px rgba(112, 144, 176, 0.12)',
      'unset'
    );
    const bgItem = useColorModeValue('white', 'navy.700');
    const [searchQuery, setSearchQuery] = useState('');
  
    const [orders, setOrders] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [filtredOrders, setFiltredOrders] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('all'); // Default is 'all', can be changed to 'published', 'draft', or 'validation'
    const [selectAll, setSelectAll] = useState(false);
    const [pageSize, setPageSize] = useState(10); // Number of orders per page
  
    const userData = localStorage.getItem('user-token');
    const currentUser = JSON.parse(userData);
    const history = useHistory();
    const [selectedOrders, setSelectedOrders] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const fetchUsers = async (orderData) => {
        try {
          // Filter out orders with empty idUser and map to userIds
          const userIds = orderData
            .filter(order => order.idUser !== "") // Remove orders with empty idUser
            .map(order => order.idUser);
      
          const token = localStorage.getItem('token'); // Get the token from localStorage
      
          const headers = {
            Authorization: `Bearer ${token}`,
            // Add other necessary headers if required
          };
      
          const usersResponse = await axios.post(
            `${process.env.REACT_APP_API}/users/admin/users/batch`,
            { userIds },
            { headers } // Include your headers in the request
          );
      
          console.log('Order users', usersResponse.data);
          return usersResponse.data;
        } catch (error) {
          console.error(error);
          return [];
        }
      };
      
    
    
    // ...
    
    useEffect(() => {
      const fetchOrdersAndUsers = async () => {
        try {
          const ordersResponse = await axios.get(`${process.env.REACT_APP_API}/shoporders/shoporders`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          const ordersData = ordersResponse.data.shopOrders;
          setOrders(ordersData);
    
          const usersData = await fetchUsers(ordersData);
          const usersMap = usersData.reduce((acc, user) => {
            acc[user._id] = user; // Assuming user._id is the unique identifier
            return acc;
          }, {});
          setSelectedUser(usersMap);
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchOrdersAndUsers();
    }, []);
    useEffect(() => {
      if (selectedStatus === 'all') {
        setFiltredOrders(orders);
      } else {
        const filtered = orders.filter(order => order.status === selectedStatus);
        setFiltredOrders(filtered);
      }
    }, [orders, selectedStatus]);
    const handleClickPrevious = () => {
      setCurrentPage(prevPage => Math.max(prevPage - 1, 0));
    };
  
    const handleClickNext = () => {
      setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages - 1));
    };
    const handleSearchQueryChange = event => {
      setSearchQuery(event.target.value);
    };
    // Use currentUser._id in the dependency array to fetch data when the user changes or searchQuery changes
  
    const deleteOrder = async id => {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API}/shoporders/shoporder/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
  
    const handleSelectOrder = OrderId => {
      setSelectedOrders(prevSelectedOrders => {
        if (prevSelectedOrders.includes(OrderId)) {
          return prevSelectedOrders.filter(id => id !== OrderId);
        } else {
          return [...prevSelectedOrders, OrderId];
        }
      });
    };
  
    const handleSelectAll = () => {
      setSelectAll(!selectAll);
      setSelectedOrders(selectAll ? [] : displayedOrders.map(order => order.generatedId));
    };
  
    const handleDeleteSelectedOrders = async () => {
      if (selectedOrders.length === 0) return;
  
      Swal.fire({
        title: 'Êtes-vous sûr(e) ?',
        text: 'Vous ne pourrez pas revenir en arrière !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer !',
        cancelButtonText: 'Annuler',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await Promise.all(
              selectedOrders.map(async OrderId => {
                await deleteOrder(OrderId);
              })
            );
  
            Swal.fire(
              'Supprimée !',
              'Vos commandes sélectionnées ont été supprimées.',
              'success'
            );
          } catch (error) {
            console.error(error);
            Swal.fire(
              'Erreur',
              "Une erreur s'est produite lors de la suppression des annonces.",
              'error'
            );
          }
  
          setSelectedOrders([]);
        }
      });
    };
  
    const handleStatusChange = status => {
      setSelectedStatus(status);
    };
  
    const handleClick = id => {
      history.push(`/ad/shoporder/${id}`);
    };
  
    const handleDelete = id => {
      Swal.fire({
        title: 'Êtes-vous sûr(e) ?',
        text: 'Vous ne pourrez pas revenir en arrière !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer !',
        cancelButtonText: 'Annuler',
      }).then(result => {
        if (result.isConfirmed) {
          deleteOrder(id);
          Swal.fire('Supprimée !', 'Votre annonce a été supprimé.', 'success');
        }
      });
    };
    const getColorScheme = status => {
      let colorScheme;
  
      switch (status) {
        case 'SET':
          colorScheme = 'green';
          break;
        case 'CANCELED':
          colorScheme = 'red';
          break;
        default:
          colorScheme = 'blue';
          break;
      }
  
      return colorScheme;
    };
    const totalPages = Math.ceil(filtredOrders.length / itemsPerPage);
    const currentPageClamped = Math.max(0, Math.min(currentPage, totalPages - 1));
    const start = currentPageClamped * itemsPerPage;
    const end = start + itemsPerPage;
    const displayedOrders = filtredOrders.slice(start, end);
    const disabledButtonColor = useColorModeValue('gray.300', 'gray.700');
  
    // Update the conditions for disabling the previous and next buttons
    const isPreviousDisabled = currentPageClamped === 0;
    const isNextDisabled = currentPageClamped >= totalPages - 1;
  
    return (
      <Card mb={{ base: '0px', '2xl': '20px' }}>
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          {t(`Gestion des commandes`)}
        </Text>
  
        <Box>
          <Flex w="100%" justify="space-between">
            <Tabs colorScheme="purple" variant="enclosed" flex="1">
              <TabList mb={{ base: '4px', md: '0' }}>
                <Tab
                  fontSize="sm"
                  fontWeight={selectedStatus === 'all' ? 'bold' : 'normal'}
                  onClick={() => handleStatusChange('all')}
                  isSelected={selectedStatus === 'all'}
                > 
                <MdBallot size={20} />
                <Text ml={1}>Toutes les commandes</Text>
                  
                </Tab>
                <Tab
                  fontSize="sm"
                  fontWeight={selectedStatus === 'ON GOING' ? 'bold' : 'normal'}
                  onClick={() => handleStatusChange('ON GOING')}
                  isSelected={selectedStatus === 'ON GOING'}
                >
                <MdSettings size={20} />
                   <Text ml={1}>Commandes en cours</Text>
                  
                </Tab>
                <Tab
                  fontSize="sm"
                  fontWeight={selectedStatus === 'SET' ? 'bold' : 'normal'}
                  onClick={() => handleStatusChange('SET')}
                  isSelected={selectedStatus === 'SET'}
                >
                <MdPaid  size={20} />
                   <Text ml={1}>Commandes reglées</Text>
                  
                </Tab>
                <Tab
                  fontSize="sm"
                  fontWeight={selectedStatus === 'CANCELED' ? 'bold' : 'normal'}
                  onClick={() => handleStatusChange('CANCELED')}
                  isSelected={selectedStatus === 'CANCELED'}
                >
                 <MdCancel size={20} />
                   <Text ml={1}>Commandes annulées</Text>
                  
                </Tab>
              </TabList>
            </Tabs>
       
          </Flex>
        </Box>
  
        {selectedOrders.length > 0 && (
          <Box mb={4} mt={4} p={4} borderRadius="md" bg={bgItem} shadow="lg">
            <Flex justify="space-between" align="center">
              <Text color="red" fontWeight="bold">
                {selectedOrders.length} commande
                {selectedOrders.length > 1 ? 's' : ''} sélectionnée
                {selectedOrders.length > 1 ? 's' : ''}
              </Text>
              <Button
                colorScheme="red"
                size="sm"
                onClick={handleDeleteSelectedOrders}
              >
                Supprimer
              </Button>
            </Flex>
          </Box>
        )}
  
        {filtredOrders && filtredOrders.length > 0 ? (
          <Box>
            <Box borderWidth="1px" overflowX="auto" style={{ borderTop: 'none' }}>
              <Table size='sm' variant="simple" mt={2} minWidth="100%">
                <Thead>
                  <Tr>
                    <Th width="20px">
                      {' '}
                      <Checkbox
                        colorScheme="blue"
                        isChecked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </Th>
                    {/* Checkbox column */}
                    <Th>ID</Th>
                    <Th>Client</Th>
  
                    <Th>Date</Th>
                    <Th>Abonnement</Th>
                    <Th>Prix</Th>
  
                    <Th>Status</Th>
                    <Th>DocS</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {displayedOrders.map(order => {
                    const colorScheme = getColorScheme(order.status);
                    const createdAt = order.createdAt; // Example: "2023-12-01T12:34:56.789Z"
  
                    const date = new Date(createdAt);
                    const formattedDate = `${date.getDate()}/${
                      date.getMonth() + 1
                    }/${date.getFullYear() % 100}`;
                    const user = selectedUser[order.idUser]; // Fetch user data using order's idUser                  
                    return (
                      <Tr key={order.generatedId}>
                        <Td display={{ base: 'none', md: 'table-cell' }}>
                          <Checkbox
                            colorScheme="blue"
                            isChecked={selectedOrders.includes(order.generatedId)}
                            onChange={() => handleSelectOrder(order.generatedId)}
                          />
                        </Td>
  
                        <Td>{order.generatedId}</Td>
                        <Td>{user ? `${user.firstName} ${user.lastName}` : 'Loading ...'}</Td>
                        <Td>{formattedDate}</Td>
                        <Td>
                          {order.memberShip }
                        </Td>
                   
                        <Td>{order.totalPrice}</Td>
  
                        <Td>
                          <Badge me="16px" ms="auto" colorScheme={colorScheme}>
                            {order.status}
                          </Badge>
                        </Td>
                        <Td>
                          {order?.cashReservationPDF ? (
                            <IconButton
                              as="a"
                              borderRadius="full"
                              href={order?.cashReservationPDF}
                              variant="action"
                              icon={<DownloadIcon />}
                            />
                          ) : null}
                        </Td>
  
                        <Td>
                          <Flex justify="space-around">
                            <IconButton
                              mr={1}
                              icon={<MdOpenInNew />}
                              variant="action"
                              size="sm"
                              onClick={() => handleClick(order.generatedId)}
                            />
                            <IconButton
                              icon={<MdDeleteOutline />}
                              colorScheme="red"
                              size="sm"
                              onClick={() => handleDelete(order.generatedId)}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
            <Flex justify="space-between" align="center" mt="4">
              <Text fontSize="sm" color="gray.500">
                Page {currentPageClamped + 1} sur {totalPages}
              </Text>
              <ButtonGroup>
                <Button
                  colorScheme="blue"
                  size="sm"
                  leftIcon={<ChevronLeftIcon />}
                  onClick={handleClickPrevious}
                  isDisabled={currentPage === 0}
                  _disabled={{ bg: disabledButtonColor, cursor: 'not-allowed' }}
                >
                  Précédent
                </Button>
                <Button
                  colorScheme="blue"
                  size="sm"
                  rightIcon={<ChevronRightIcon />}
                  onClick={handleClickNext}
                  isDisabled={currentPage === totalPages - 1}
                  _disabled={{ bg: disabledButtonColor, cursor: 'not-allowed' }}
                >
                  Suivant
                </Button>
              </ButtonGroup>
            </Flex>
          </Box>
        ) : (
          <Box>
            <Text fontWeight={300} fontFamily="body" mb={15}>
              Aucune annonce n'a été trouvée pour le moment. <br></br> Ajoutez une
              annonce via le bouton "Annonce gratuite"
            </Text>
            <AdsCard />
          </Box>
        )}
      </Card>
    );
  }
  