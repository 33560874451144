import React from "react";
import { useHistory } from 'react-router-dom';

import {
  Flex,
  Avatar,
  AvatarBadge,
  Text,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  Tooltip,
  Icon,
  useColorMode,
  IconButton,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { FaInfoCircle, FaTrash, FaArchive  } from "react-icons/fa";
import { MdReportProblem } from "react-icons/md";
import Swal from 'sweetalert2';
import axios from 'axios';
import { t } from "helpers/TransWrapper";

const Header = (props) => {
  const { id,avatar, name, status, userId } = props;
  const { colorMode } = useColorMode();
  const bgItem = useColorModeValue(
    { bg: 'white', boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)' },
    { bg: 'navy.700', boxShadow: 'unset' }
  );
  const history = useHistory();

  const deleteConv = async id => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/conversations/conv/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteConversation = id => {
    Swal.fire({
      title: t('Êtes-vous sûr(e) ?'),
      text: t('Vous ne pourrez pas revenir en arrière !'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Oui, supprimer !'),
      cancelButtonText: t('Annuler'),
    }).then(result => {
      if (result.isConfirmed) {
        deleteConv(id);
        Swal.fire(t('Supprimée !'), t('Votre conversation a été supprimé.'), t('success'));
        
      }
    });
  };
  const handleReportUser = () => {
    const emailSubject = `Reporting User ID : ${userId}` ; // Subject of the email
    const emailBody = 'I would like to report this user'; // Body of the email, can be pre-filled
    
    const mailtoLink = `mailto:contact@jindoo.ma?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;

    // Open the user's default email client
    window.location.href = mailtoLink;
  };
  return (
    <Flex w="100%">
      <Avatar size="md" src={avatar}>
        <AvatarBadge boxSize="1em" bg={status === t("Connected") ? "green.500" : "gray.500"} />
      </Avatar>
      <Flex flexDirection="column" mx="5" alignItems="start">
        <Text fontSize="md" fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="sm" color={status === t("Connected") ? "green.500" : "gray.500"}>
          {status === t("Connected") ? t("En ligne") : t("Hors ligne")}
        </Text>
      </Flex>
      <Popover >
        <PopoverTrigger>
          <IconButton
            ml="auto"
            size="sm"
            aria-label={t("Conversation Info")}
            icon={<FaInfoCircle />}
          />
        </PopoverTrigger>
        <PopoverContent
          css={css`
                  width: 16rem;
                  height : 7.5rem;
                  max-height: 300px; 
                `}
                bg={colorMode === 'light' ? 'gray.100' : 'navy.800'}

                >
                
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Flex direction="column" align="flex-start">
              <Flex align={"center"}>
                <IconButton
                  aria-label={t("Delete Conversation")}
                  bg="brand.300"
                  borderRadius="50%"
                  icon={<Icon as={FaTrash} boxSize={6} color="white" />}
                  mr="0.5rem"
                  _hover={{
                    bg: "brand.600",
                  }}
                  onClick={() => handleDeleteConversation(id)}
                />
                <Button size="sm" variant="ghost" onClick={() => handleDeleteConversation(id)}>
                  {t('Delete conversation')}
                </Button>
              </Flex>
              {/* <Flex align={"center"} mt="1rem">
                <IconButton
                  aria-label="Archive Conversation"
                  bg="brand.300"
                  borderRadius="50%"
                  icon={<Icon as={FaArchive} boxSize={6} color="white" />}
                  mr="0.5rem"
                  _hover={{
                    bg: "brand.600",
                  }}
                  onClick={() => console.log("Archive conversation")}
                />
                <Button size="sm" variant="ghost" onClick={() => console.log("Archive conversation")}>
                  Archive conversation
                </Button>
              </Flex> */}
              <Flex align={"center"} mt="1rem">
                <IconButton
                  aria-label={t("Report User")}
                  bg="brand.300"
                  borderRadius="50%"
                  icon={<Icon as={MdReportProblem} boxSize={6} color="white" />}
                  mr="0.5rem"
                  _hover={{
                    bg: "brand.600",
                  }}
                  onClick={handleReportUser}
                />
                <Button size="sm" variant="ghost" onClick={handleReportUser}>
                  {t('Report user')}
                </Button>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default Header;
