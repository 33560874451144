import {
    Flex,
    Table,
    Progress,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    TableContainer,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Heading,
    Center,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Textarea,
    Stack,
    Switch,
    Radio,
    RadioGroup,
    SimpleGrid,
    Avatar,
    AvatarBadge,
    IconButton,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Grid,
  } from '@chakra-ui/react';
  import Swal from 'sweetalert2';
  
  import React, { useMemo, useState, useEffect, useCallback } from 'react';
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from 'react-table';
  import axios from 'axios';
  import { DeleteIcon, EditIcon, MdOutlineError } from '@chakra-ui/icons';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  // Custom components
  import Card from 'components/card/Card';
  import Menu from 'components/menu/MainMenu';
  import { NavLink } from 'react-router-dom';
  import { useHistory } from 'react-router-dom';
  const PriceInput = React.memo(({ day, dayPrice, setDayPrice }) => {
    const handlePriceChange = e => {
      const price =
        e.target.value !== '' ? parseFloat(e.target.value) : undefined;
      setDayPrice(day, price);
    };
  
    return (
      <Flex>
        <Box borderWidth="1px" borderRadius="15px" p="4" shadow="md">
          <FormLabel>Période de {day} jours </FormLabel>
          <Input
            type="number"
            variant="filled"
            value={dayPrice || ''}
            onChange={handlePriceChange}
            placeholder={`Prix pour une période de ${day} jours`}
          />
        </Box>
      </Flex>
    );
  });
  export default function SubsTable(props) {
    const { columnsData } = props;
    const [categories, setCategories] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editData, setEditData] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure(); // For controlling the modal
    const [promotionChecked, setPromotionChecked] = useState(false);
    const [renouvellementChecked, setRenouvellementChecked] = useState(false);
    const [dayPrices, setDayPrices] = useState({}); // State to hold prices for each day
    const [days, setDays] = useState([]);
    const [name, setName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [price, setPrice] = useState(); // Initialize with default value
  
    const HandleChange = e => {
      setName(e.target.value);
      const selectedValue = e.target.value;
  
      if (selectedValue === 'BRONZE') {
        setRenouvellementChecked(true);
        setPromotionChecked(false);
        setDays([1, 3, 7, 14, 30]); // Set days for BRONZE
      } else if (selectedValue === 'SILVER') {
        setPromotionChecked(true);
        setRenouvellementChecked(false);
        setDays([1, 3, 7, 14, 30]); // Set days for SILVER
      } else if (selectedValue === 'GOLD') {
        setPromotionChecked(true);
        setRenouvellementChecked(true);
        setDays([1, 3, 7, 14, 30]); // Set days for GOLD
      }
    };
    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_API}/categories`)
        .then(res => setCategories(res.data))
        .catch(err => console.error(err));
    }, []);
    const handleEdit = rowData => {
      setEditData(rowData);
      onOpen(); // Open the modal
    };
    useEffect(() => {
      let repostsData = [];
      let daysData = [];
  
      if (renouvellementChecked) {
        repostsData = [1, 3, 7, 14, 30];
      } else {
        repostsData = [];
      }
  
      if (promotionChecked) {
        daysData = [1, 3, 7, 14, 30];
        setDays(daysData);
      } else {
        daysData = [];
      }
  
      const data = {
        name: name,
        reposts: repostsData,
        days: daysData,
        price: dayPrices,
        idCategory: selectedCategoryId,
      };
  
      console.log('selectedCategoryId', selectedCategoryId);
      console.log(data);
  
      // Additional logic or side effects based on the updated data can be handled here
    }, [
      renouvellementChecked,
      promotionChecked,
      name,
      price,
      selectedCategoryId,
      dayPrices
    ]);
  
    const handleSetDayPrice = useCallback((day, price) => {
      setDayPrices(prevPrices => ({
        ...prevPrices,
        [day]: price !== '' ? parseFloat(price) : undefined,
      }));
    }, []);
  
    const handlePromotionChange = () => {
      setPromotionChecked(!promotionChecked);
    };
  
    const handleRenouvellementChange = () => {
      setRenouvellementChecked(!renouvellementChecked);
    };
    const handleSubmit = async id => {
      const configuration = {
        method: 'patch',
        url: `${process.env.REACT_APP_API}/subscriptions/update/${id}`,
        data: {
          name: name,
          reposts: renouvellementChecked ? [1, 3, 7, 14, 30] : 0,
          days: promotionChecked ? [1, 3, 7, 14, 30] : 0,
          price: dayPrices,
          idCategory: selectedCategoryId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      try {
        await axios(configuration);
        toast('Plan ajouté avec succes!', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        onClose(); // Close the modal after submitting changes
      } catch (error) {
        console.error(error);
        // Handle error scenarios
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Fetch categories
  
          // Fetch subscriptions
          const subscriptionsResponse = await axios.get(
            `${process.env.REACT_APP_API}/subscriptions`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
  
          const subscriptions = subscriptionsResponse.data.subscriptions;
          console.log('Subscriptions:', subscriptions);
  
          // Map and update state
          const newData = subscriptions.map(item => {
            const category = categories.find(
              category => category._id === item.idCategory
            );
  
            if (category) {
              console.log(
                `Match Found: Subscription ID ${item._id} - Category ID ${category._id}`
              );
            } else {
              console.log(`No Match Found: Subscription ID ${item._id}`);
            }
  
            // Format reposts and days arrays into string representations
            const formattedReposts = item.reposts.join(', ');
            const formattedPrice = item.price.join(', ');
            const formattedDays = item.days.join(', ');
  
            const priceArray = item.price.map(priceObj => {
              // Extract keys (day durations) from the price object
              const durations = Object.keys(priceObj);
  
              // Create a string representation for each duration and price
              const formattedPrices = durations
                .map(duration => ` ${priceObj[duration]}`)
                .join(', ');
  
              return formattedPrices;
            });
  
            // Create an object with the formatted data
            return {
              id: item._id,
              name: item.name,
              category: category ? category.label : '',
              price: priceArray,
              reposts: formattedReposts,
              days: formattedDays,
              date: new Date(item.createdAt).toLocaleString(),
              action: item._id,
            };
          });
  
          console.log('New Data:', newData);
          setTableData(newData); // Assuming you want to update the state with the new data
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchData();
    }, [categories]); // Empty dependency array to run the effect once when the component mounts
  
    const deletePlan = async id => {
      Swal.fire({
        title: 'Êtes-vous sûr(e) ?',
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer !',
        cancelButtonText: 'Annuler',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.delete(
              `${process.env.REACT_APP_API}/subscriptions/delete/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              }
            );
    
            Swal.fire('Supprimée !', 'le plan a été supprimées.', 'success');
            
          } catch (error) {
            console.error(error);
            Swal.fire('Erreur', 'Une erreur s\'est produite lors de la suppression des annonces.', 'error');
          }
    
        }
      });
      
    };
    // Add categories as a dependency
  
    const columns = useMemo(() => columnsData, [columnsData]);
  
    const tableInstance = useTable(
      {
        columns,
        data: tableData,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
      nextPage,
      previousPage,
      canPreviousPage,
      canNextPage,
      pageOptions,
      state: { pageIndex },
    } = tableInstance;
    initialState.pageSize = 6;
    const history = useHistory();
    const brand = useColorModeValue('brand.500', 'pink.200');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const getAdById = async id => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/ad/${id}`
        );
  
        history.push(`/ads/${id}`);
      } catch (error) {
        console.error(error);
      }
    };
  
    return (
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex px="25px" justify="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Liste des plans d'annonces
          </Text>
        </Flex>
        <Table
          p="50px"
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb="24px"
        >
          <Thead>
            {headerGroups?.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page?.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells?.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <Flex align="center">
                          <Flex>
                            <Text color={brand} fontSize="sm" fontWeight="700">
                              {cell.value}
                            </Text>
                          </Flex>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'NOM') {
                      data = (
                        <Flex align="center">
                          <Flex>
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'CATEGORIE') {
                      data = (
                        <Flex align="center">
                          <Text
                            me="10px"
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'PRIX(MAD)') {
                      data = (
                        <Flex align="center">
                          <Text
                            me="10px"
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'RENOUVELEMENT(jours)') {
                      data = (
                        <Flex align="center">
                          <Text
                            me="10px"
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'PROMO(jours)') {
                      data = (
                        <Flex align="center">
                          <Text
                            me="10px"
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'DATE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'ACTION') {
                      data = (
                        <Flex align="center">
                          <IconButton
                            colorScheme="blue"
                            aria-label="Update"
                            icon={<EditIcon />}
                            onClick={e => handleEdit(cell.value)}
                          />
                          <IconButton
                            ml="2"
                            colorScheme="red"
                            aria-label="Delete"
                            icon={<DeleteIcon />}
                            onClick={() => deletePlan(cell.value)}
                          />
                          <Modal
                            isOpen={isOpen}
                            onClose={onClose}
                            size="xl"
                            scrollBehavior="inside"
                          >
                            <ModalOverlay
                              bg="none"
                              backdropFilter="auto"
                              backdropBlur="2px"
                            />
                            <ModalContent>
                              <ModalHeader>Modification du champs</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <Box
                                  px="20px"
                                  justify="space-between"
                                  mb="1px"
                                  align="center"
                                >
                                  <Box w="100%" m={1}>
                                    <FormLabel
                                      fontSize="sm"
                                      fontWeight="500"
                                      mb="1"
                                    >
                                      Nom du plan
                                    </FormLabel>
                                    <Select
                                      m={1}
                                      id="name"
                                      name="name"
                                      isRequired={true}
                                      fontSize="sm"
                                      mb="24px"
                                      size="lg"
                                      variant="auth"
                                      onChange={e => HandleChange(e)}
                                      placeholder="Choisir un nom pour le plan"
                                    >
                                      <option value="BRONZE">BRONZE</option>
                                      <option value="SILVER">SILVER</option>
                                      <option value="GOLD">GOLD</option>
                                    </Select>
                                  </Box>
                                </Box>
                                {/* Render inputs for editing inside the modal */}
                                <Box
                                  px="20px"
                                  justify="space-between"
                                  mb="1px"
                                  align="center"
                                >
                                  <Box w="100%" m={1}>
                                    <FormLabel
                                      fontSize="sm"
                                      fontWeight="500"
                                      mb="1"
                                    >
                                      Catégorie du plan
                                    </FormLabel>
                                    <Select
                                      w="100%"
                                      m={1}
                                      id="category"
                                      name="categoryName"
                                      isRequired={true}
                                      fontSize="sm"
                                      mb="24px"
                                      size="lg"
                                      variant="auth"
                                      onChange={e => {
                                        const selectedCategoryId =
                                          e.target.options[e.target.selectedIndex]
                                            .dataset.id;
                                        const selectedCategory = categories.find(
                                          category =>
                                            category._id === selectedCategoryId
                                        );
                                        setSelectedCategory(
                                          selectedCategory.name
                                        );
                                        setSelectedCategoryId(selectedCategoryId);
                                        setSelectedCategoryLabel(
                                          selectedCategory.label
                                        );
                                      }}
                                      placeholder="Choisir une categorie"
                                    >
                                      {categories.map(category => (
                                        <option
                                          key={category._id}
                                          value={category.name}
                                          name={category.label}
                                          data-id={category._id}
                                        >
                                          {category.label}
                                        </option>
                                      ))}
                                    </Select>
                                  </Box>
  
                                  <Flex ml={1} w="100%" justify="space-around">
                                    <FormLabel
                                      fontSize="md"
                                      fontWeight="700"
                                      htmlFor="Promotion"
                                    >
                                      Promotion
                                    </FormLabel>
                                    <Switch
                                      colorScheme="brand"
                                      size="lg"
                                      onChange={handlePromotionChange}
                                      isChecked={promotionChecked}
                                      isDisabled={true}
                                    />
                                    <FormLabel
                                      fontSize="md"
                                      fontWeight="700"
                                      htmlFor="Renouvellement"
                                    >
                                      Renouvellement
                                    </FormLabel>
                                    <Switch
                                      colorScheme="brand"
                                      size="lg"
                                      onChange={handleRenouvellementChange}
                                      isChecked={renouvellementChecked}
                                      isDisabled={true}
                                    />
                                  </Flex>
                                </Box>
  
                                {(renouvellementChecked || promotionChecked) &&
                                  days.length > 0 && (
                                    <Grid
                                      templateColumns="repeat(2, 1fr)"
                                      gap={2}
                                      mb={5}
                                    >
                                      {days.map(day => (
                                        <PriceInput
                                          key={day}
                                          day={day}
                                          dayPrice={dayPrices[day]}
                                          setDayPrice={handleSetDayPrice}
                                        />
                                      ))}
                                    </Grid>
                                  )}
  
                                {/* Add other input fields for different data */}
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  colorScheme="brand"
                                  mr={3}
                                  onClick={e => handleSubmit(editData)}
                                >
                                  Sauvegarder
                                </Button>
                                <Button onClick={onClose}>Annuler</Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </Flex>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex px="25px" justify="space-between" align="center">
          <Button
            variant="brand"
            p="auto"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Précédent
          </Button>
          <Text>
            Page{' '}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{' '}
          </Text>
          <Button
            variant="brand"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Suivant
          </Button>
        </Flex>
      </Card>
    );
  }
  