/**
 * Generates a Cart component with various functionalities such as fetching order details, updating order status and total price, handling PayPal and CashPlus payments, updating user and ad details, handling credit, and generating paywall form.
 */
import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { t } from "helpers/TransWrapper";
import axios from 'axios';
import {
  ThemeProvider,
  theme,
  Link,
  Box,
  Th,
  Tr,
  Td,
  Tbody,
  Table,
  Thead,
  VStack,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  Image,
  HStack,
  Divider,
  useDisclosure,
  CloseButton,
  Grid,
  GridItem,
  Progress,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  Wrap,
  WrapItem,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  Badge,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { FaArrowLeft, FaHeart, FaShareAlt, FaTimes } from 'react-icons/fa'; // Import icons
import Card from 'components/card/Card.js';
import Swal from 'sweetalert2';
import { ExternalLinkIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  MdCameraEnhance,
  MdCategory,
  MdPerson,
  MdSearch,
  MdHomeFilled,
  MdDelete,
  MdImage,
  MdCheck,
  MdOutlineShoppingBag,
} from 'react-icons/md';
import { FaWallet } from 'react-icons/fa';
import { MdEmail, MdPhone, MdLocationOn } from 'react-icons/md'; // Import icons
import Carte from '../../../assets/img/carte.png';
import Paypal from '../../../assets/img/paypal.png';
import CashPlus from '../../../assets/img/Cashplus.png';
import { TbRepeat, TbRocket } from 'react-icons/tb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Cart = () => {
  const main = useColorModeValue('navy', 'white');
  const mainBrand = useColorModeValue('brand.500', 'white');
  const second = useColorModeValue('gray.600', 'gray.300');
  const bg = useColorModeValue('white', 'navy.700');
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [totalReposts, setTotalReposts] = useState();
  const [totalPromo, setTotalPromo] = useState();
  const [subscription, setSubscription] = useState();
  const [user, setUser] = useState();
  const [payload, setPayload] = useState('');
  const [paywallForm, setPaywallForm] = useState('');
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [payloadData, setPayloadData] = useState();
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/orders/order/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const orderResponse = res.data.order;
        setOrder(orderResponse);
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }
    };

    fetchOrder(); // Call the asynchronous function here
  }, [id]);
  useEffect(() => {
    const fetchSubsciption = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/subscriptions/get/${order?.idSubscription}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const subscription = res.data;
        setSubscription(subscription);
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }
    };

    fetchSubsciption(); // Call the asynchronous function here
  }, [order?.idSubscription]);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/users/${order?.idUser}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const user = res.data;
        setUser(user);
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }
    };

    fetchUser(); // Call the asynchronous function here
  }, [order?.idUser]);

  

  const [payloadResponse, setPayloadResponse] = useState();
  const [signature, setSignature] = useState();

  const handleCmi = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/orders/order/cmi/create`,
        {
          payload: payloadData,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setPayloadResponse(response.data.payload);
      setSignature(response.data.signature);
      console.log('signature', response.data.signature);
    
      //window.location.href = targetUrl;
      console.log('response', response.data);
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };
  useEffect(() => {
    handleCmi();
  }, [totalPrice]);
  const handleSubmit = () => {
    document.getElementById("openPaywall").submit();
  };
  
  const executePaypalPayment = async (paymentId, payerId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/orders/order/paypal/execute`,
        {
          paymentId,
          payerId,
          // Add other necessary details
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Handle the response as needed
      console.log('PayPal payment executed successfully:', response.data);
    } catch (error) {
      console.error('Error executing PayPal payment:', error);
    }
  };
  const updateOrderStatus = async (id, status) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/orders/order/${id}`,
        {
          status: status,

          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };
  const updateOrderTotalPrice = async (id, totalPrice) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/orders/order/${id}`,
        {
          totalPrice: totalPrice,

          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const handleCancelOrder = async () => {
    Swal.fire({
      title: t('Êtes-vous sûr(e) ?'),
      text: t('Vous ne pourrez pas revenir en arrière !'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Oui, annuler la commande !'),
      cancelButtonText: 'Non',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await updateOrderStatus(id, 'CANCELED');

          Swal.fire(t('Supprimée !'), t('Vote commande est annulee.'), t('success'));
          history.go(0);
        } catch (error) {
          console.error(error);
          Swal.fire(t('Erreur'), t("Une erreur s'est produite."), t('error'));
        }
      }
    });
  };
  const history = useHistory();
  const orderDate = new Date(order?.createdAt);
  const formattedDate = orderDate.toLocaleDateString();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!order) return; // Ensure order exists before processing

        let totalReposts = 0;
        let totalPromo = 0;

        if (order?.reposts === 0) {
          totalReposts = 0;
          setTotalReposts(0);
        } else if (order?.promo === 0) {
          totalReposts = order?.price * order?.reposts;
          setTotalReposts(totalReposts.toFixed(2));
        } else {
          totalReposts = (order?.price / 3) * order?.reposts;
          setTotalReposts(totalReposts.toFixed(2));
        }

        if (order?.promo === 0) {
          totalPromo = 0;
          setTotalPromo(0);
        } else if (order?.reposts === 0) {
          totalPromo = order?.price * order?.promo;
          setTotalPromo(totalPromo.toFixed(2));
        } else {
          totalPromo = ((2 * order?.price) / 3) * order?.promo;
          setTotalPromo(totalPromo.toFixed(2));
        }

        const totalPrice = (
          parseFloat(totalReposts) + parseFloat(totalPromo)
        ).toFixed(2);
        setTotalPrice(parseFloat(totalPrice));
        const payloadData = {
          merchantAccount: 'Jindo',
          timestamp: Math.floor(new Date().getTime() / 1000), // Timestamp in seconds
          skin: 'vps-1-vue',
          customerId: currentUser._id,
          customerEmail: currentUser.email,
          customerName: currentUser.firstName + ' ' + currentUser.lastName,
          customerCountry: 'MA',
          customerLocale: 'fr_FR',
          chargeId: Math.floor(new Date().getTime() / 1000), // Optional, unless required by the merchant account
          price: totalPrice,
          currency: 'MAD',
          description: 'Plan de sponsoring pour annonce',
          mode: 'DEEP_LINK',
          paymentMethod: 'CREDIT_CARD',
          showPaymentProfiles: 'true',
          callbackUrl: `https://jindoo.ma:6001/orders/order/cmi/callback?orderId=${id}`,
          //flowCompletionUrl: 'http://sample.com/hosted-paywall-files/redirect.html', // 
          successUrl: `https://jindoo.ma/#/ad/payzone/success?orderId=${id}`,
          failureUrl: 'https://jindoo.ma/#/ad/payzone/cancel',
        };
        setPayloadData(payloadData);
        console.log('payloadData',payloadData);
        console.log('totalPrice', totalPrice);
        await updateOrderTotalPrice(id, parseFloat(totalPrice));

      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id, order]);

  const currentDate =
    new Date().toISOString().replace(/\D/g, '').slice(0, -1) + 'T+0000';
  // PAYEMENTS LOGIC
  const handlePaypalClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/orders/order/paypal/create`,
        {
          generatedId: id,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const paymentId = response.data.paymentId;

      const approvalUrl = response.data.approvalUrl;
      window.location.replace(approvalUrl);
      const executePaymentResponse = await executePaypalPayment(
        paymentId,
        order?.idUser
      );
    } catch (error) {
      console.error('Error creating PayPal payment:', error);
    }
  };
  const handleCashPlusClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/orders/order/cash/create`,
        {
          orderId: id,
          transmission_datetime: currentDate,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const payementRequest = response.data;
      console.log('Payment Request:', payementRequest);

      if (payementRequest && payementRequest.payexpressResponseData.url1) {
        const url = payementRequest.payexpressResponseData.url1;
        console.log('Payment URL:', url);
        openPaymentWindow(url);
      } else {
        console.error('Invalid payment URL in the response:', payementRequest);
      }
    } catch (error) {
      console.error('Error creating PayPal payment:', error);
    }
  };

  const openPaymentWindow = url => {
    // Open a new tab/window with the payment URL
    const paymentWindow = window.open(url, '_blank');
    if (paymentWindow) {
      // Focus on the new tab/window if it's successfully opened
      paymentWindow.focus();
    } else {
      // Handle if the popup is blocked
      console.error('Popup blocked or unable to open the payment window.');
      // You can inform the user or provide an alternative method to proceed
    }
  };

  const updateOrder = async (id, status, paymentStatus) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/orders/order/${id}`,
        {
          status: status,
          paymentStatus: paymentStatus,
          creditPromotion: true,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const updateAd = async (
    id,
    isPromoted,
    isReposts,
    orderId,
    subscriptionId,
    promotionDays,
    repostsDays
  ) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/ads/${id}/promo/update`,
        {
          isPromoted: isPromoted,
          isReposts: isReposts,
          orderId: orderId,
          subscriptionId: subscriptionId,
          promotionDays: promotionDays,
          repostsDays: repostsDays,
          // Add other payment details as needed
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error updating Ad:', error);
    }
  };
  const updateUser = async (id, credit) => {
    // Add billingCycle months to end date

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/users/update/${id}`,
        {
          credit: credit,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setUser(response.data);
    } catch (error) {
      console.error('Error updating User:', error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUpdatedCredit, setNewUpdatedCredit] = useState(0);

  const handleCredit = async () => {
    try {
      if (order.generatedId) {
        const updatedCredit = user.credit - totalPrice;
        if (updatedCredit >= 0) {
          setNewUpdatedCredit(updatedCredit);
          setIsModalOpen(true);
        } else {
          // Gérer le crédit insuffisant avec une notification Toast
          toast.error(
            t("Crédit insuffisant. Votre crédit actuel n'est pas suffisant pour cet achat.")
          );
        }
      } else {
        // Gérer les paramètres manquants avec une notification Toast
        toast.error(
          t("Paramètres manquants. Les paramètres de paiement ou de commande sont manquants dans l'URL.")
        );
      }
    } catch (error) {
      console.error('Erreur lors du traitement du retour de crédit :', error);
      // Gérer l'erreur comme nécessaire, par exemple, afficher un message d'erreur à l'utilisateur
    }
  };

  const handleConfirm = async () => {
    try {
      // Mise à jour de la commande
      await updateOrder(order.generatedId, 'SET', 'COMPLETED', 'completed');
      toast.success(t('Commande mise à jour avec succès!'));

      // Mise à jour de l'utilisateur
      await updateUser(order.idUser, newUpdatedCredit);
      toast.success(t('Crédit utilisateur mis à jour avec succès!'));

      // Mise à jour de l'annonce
      await updateAd(
        order?.idAd,
        subscription?.days !== 0 ? true : false,
        subscription?.reposts !== 0 ? true : false,
        order.generatedId,
        order?.idSubscription,
        order?.promo,
        order?.reposts
      );
      toast.success(t('Annonce mise à jour avec succès!'));

      // Afficher un message de succès global
      toast.success(t('Votre achat a été confirmé avec succès!'));

      setIsModalOpen(false);
    } catch (error) {
      console.error("Erreur lors de la confirmation de l'achat :", error);

      // Gérer l'erreur spécifique à chaque mise à jour
      if (error.response) {
        // Erreur de réponse HTTP
        toast.error(
          `Erreur HTTP: ${error.response.status} - ${error.response.data.message}`
        );
      } else if (error.message) {
        // Erreur générale
        toast.error(`Erreur: ${error.message}`);
      } else {
        // Erreur inattendue
        toast.error(
          t("Une erreur inattendue s'est produite lors de la confirmation de l'achat.")
        );
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

 
  return (
    <>
      <Card mt={5} mb={12} p={6}>
        <Box textAlign="start">
          <Flex w="100%" justify="space-between">
            <Box>
              {' '}
              <Heading
                fontSize={25}
                w="100%"
                textAlign={'start'}
                fontWeight="bold"
                pl="8px"
                pb="5px"
              >
                {t('Commande')} #{id}
              </Heading>
              <Text mr={1} mb={5} pl="8px" fontSize="md" color="gray.600">
                {t('Date de commande :')} <strong>{formattedDate}</strong>
              </Text>
            </Box>
            <Box>
              {order?.status === 'ON GOING' ? (
                <Badge variant="solid" colorScheme="purple">
                  {t('EN COURS')}
                </Badge>
              ) : order?.status === 'SET' ? (
                <Badge variant="solid" colorScheme="green">
                  {t('REGLEE')}
                </Badge>
              ) : order?.status === 'CANCELED' ? (
                <Badge variant="solid" colorScheme="red">
                  {t('ANNULEE')}
                </Badge>
              ) : null}
            </Box>
          </Flex>

          <Box px={3}>
            <Card shadow="lg" mb={5} bg={bg}>
              <Flex w="100%" justify="space-between">
                <Heading as="h2" size="md" mb={4} color={main}>
                  {t('Informations Client')}
                </Heading>
                <Box>
                  {user?.ProPart === 'part' ? (
                    <Badge variant="solid" colorScheme="blue">
                      {t('PARTICULIER')}
                    </Badge>
                  ) : (
                    <Badge variant="solid" colorScheme="green">
                      {t('PROFESSIONEL')}
                    </Badge>
                  )}
                </Box>
              </Flex>
              <Divider
                variant="dashed"
                textAlign="center"
                colorScheme={main}
                borderWidth={1}
                borderColor={main}
                mb={4}
              />{' '}
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <HStack mb={2} alignItems="center">
                    <Icon as={MdPerson} boxSize={6} color={main} />
                    <Text fontSize="md">
                      {user?.firstName} {user?.lastName}
                    </Text>
                  </HStack>
                  <HStack mb={2} alignItems="center">
                    <Icon as={MdEmail} boxSize={6} color={main} />
                    <Text fontSize="md">{user?.email}</Text>
                  </HStack>
                </GridItem>
                <GridItem colSpan={1}>
                  <HStack mb={2} alignItems="center">
                    <Icon as={MdPhone} boxSize={6} color={main} />
                    <Text fontSize="md">{user?.phone}</Text>
                  </HStack>
                  <HStack mb={2} alignItems="center">
                    <Icon as={MdLocationOn} boxSize={6} color={main} />
                    <Text fontSize="md">{user?.address}</Text>
                  </HStack>
                </GridItem>
              </Grid>
            </Card>
            <Card shadow="lg" mb={5} bg={bg}>
              <Flex mb={5} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Icon
                      mt={1}
                      as={TbRepeat}
                      boxSize={5}
                      color={main}
                      mr={1}
                    />
                    <Box>
                      {' '}
                      <Text fontWeight="600" ml={1} fontSize="md" color={main}>
                        {t('Renouvelement')}
                      </Text>
                      <Text ml={1} fontSize="sm" color="gray.600">
                        {' '}
                        {('Renouvelement 1 fois par jours')}
                      </Text>
                      <Text ml={1} fontSize="sm" color="gray.600">
                        {' '}
                        {t('Nombre de jour :')} <strong>{order?.reposts} {t('jours')}</strong>
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text ml={1} fontSize="md" fontWeight="600" color={main}>
                  {order?.reposts === 0
                    ? 0
                    : order?.promo === 0
                    ? order?.price
                    : (order?.price / 3).toFixed(2)}{' '}
                  {t('MAD / Jour')}
                </Text>
              </Flex>
              <Divider
                variant="dashed"
                textAlign="center"
                colorScheme={main}
                borderWidth={1}
                borderColor={main}
                mb={1}
              />
              <Flex mt={3} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Icon
                      mt={1}
                      as={TbRocket}
                      boxSize={5}
                      color={main}
                      mr={1}
                    />
                    <Box>
                      {' '}
                      <Text fontWeight="600" ml={1} fontSize="md" color={main}>
                        {t('Promotion')}
                      </Text>
                      <Text ml={1} fontSize="sm" color="gray.600">
                        {' '}
                        {t('Annonce en promotion')}
                      </Text>
                      <Text ml={1} fontSize="sm" color="gray.600">
                        {' '}
                        {t('Nombre de jour :')} <strong>{order?.promo} {t('jours')}</strong>
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text ml={1} fontSize="md" fontWeight="600" color={main}>
                  {' '}
                  {order?.promo === 0
                    ? 0
                    : order?.reposts === 0
                    ? order?.price
                    : ((2 * order?.price) / 3).toFixed(2)}{' '}
                  {t('MAD / Jour')}
                </Text>
              </Flex>
            </Card>
            <Box mb={6}>
              <Text fontWeight="600" mb={2}>
                {t('Coupon')}
              </Text>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  h="35px"
                  type="text"
                  placeholder="Code promo"
                />
                <InputRightElement width="4.5rem">
                  <Icon
                    mb={1}
                    as={MdOutlineShoppingBag}
                    boxSize={5}
                    color={main}
                    mr={1}
                    onClick={() => {}}
                  />
                </InputRightElement>
              </InputGroup>
            </Box>
            <Box>
              <Text fontWeight="600" mb={2}>
                {t('Résumé')}
              </Text>

              <Flex mb={1} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Box>
                      {' '}
                      <Text ml={1} fontSize="sm" color="gray.600">
                        {t('Total Renouvelement')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text ml={1} fontSize="sm" color="gray.600">
                  {totalReposts} {t('MAD')}
                </Text>
              </Flex>
              <Flex mb={1} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Box>
                      {' '}
                      <Text ml={1} fontSize="sm" color="gray.600">
                        {t('Total Promotion')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text ml={1} fontSize="sm" color="gray.600">
                  {totalPromo} {t('MAD')}
                </Text>
              </Flex>
              <Flex mb={1} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Box>
                      {' '}
                      <Text
                        ml={1}
                        fontSize="md"
                        color="gray.600"
                        fontWeight="600"
                      >
                        {t('Sous-total')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text ml={1} fontSize="md" fontWeight="600" color="gray.600">
                  {' '}
                  {totalPrice} {t('MAD')}
                </Text>
              </Flex>
              <Flex mb={1} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Box>
                      {' '}
                      <Text
                        ml={1}
                        fontSize="md"
                        color="gray.600"
                        fontWeight="600"
                      >
                        {t('Coupon')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text fontSize="md" color="red.300" fontWeight="600">
                  {' '}
                  - 00.00 + {t('MAD')}
                </Text>
              </Flex>
              <Divider
                variant="dashed"
                textAlign="center"
                colorScheme={main}
                borderWidth={1}
                borderColor={main}
                mb={1}
              />
              <Flex mb={2} w="100%" justify="space-between">
                <Box>
                  <Flex>
                    <Box>
                      {' '}
                      <Text fontWeight="600" fontSize="md" color={main}>
                        {t('Total')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Text fontSize="lg" fontWeight="600" color={main}>
                  {' '}
                  {totalPrice} {t('MAD')}
                </Text>
              </Flex>
            </Box>

            {order?.status === 'ON GOING' &&
              !order?.cashReservationId &&
              user?._id === currentUser._id && (
                <>
                  <Text fontSize={25} mt={5} fontWeight="500" mb={2}>
                    {t('Méthodes de Paiement')}
                  </Text>
                  <Card shadow="lg" mb={5}>
                    <form
                      id="openPaywall"
                      action="https://payment.payzone.ma/pwthree/launch"
                      method="POST"
                    >
                      <input
                        type="hidden"
                        name="payload"
                        value={payloadResponse}
                      />
                      <input type="hidden" name="signature" value={signature} />

                      <Flex
                        bg={bg}
                        onClick={handleSubmit}
                        cursor="pointer"
                        shadow="md"
                        p={4}
                        borderRadius={15}
                        mb={3}
                        mt={2}
                        w="100%"
                        justify="space-between"
                      >
                        <Box>
                          <Flex>
                            <Box>
                              {' '}
                              <Text
                                fontWeight="600"
                                ml={1}
                                fontSize="sm"
                                color={main}
                              >
                                {t('Carte de crédit')}
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                        <Image maxW="15%" h="auto" src={Carte} />
                      </Flex>
                    </form>

                    <Flex
                      bg={bg}
                      onClick={handlePaypalClick}
                      cursor="pointer"
                      shadow="md"
                      p={4}
                      borderRadius={15}
                      mb={3}
                      mt={2}
                      w="100%"
                      justify="space-between"
                    >
                      <Box>
                        <Flex>
                          <Box>
                            {' '}
                            <Text
                              fontWeight="600"
                              ml={1}
                              fontSize="sm"
                              color={main}
                            >
                              {t('Paypal')}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                      <Image maxW="10%" h="auto" src={Paypal} />
                    </Flex>

                    <Flex
                      bg={bg}
                      cursor="pointer"
                      onClick={handleCashPlusClick}
                      shadow="md"
                      p={3}
                      py={2}
                      borderRadius={15}
                      mt={2}
                      w="100%"
                      justify="space-between"
                    >
                      <Box>
                        <Flex>
                          <Box>
                            {' '}
                            <Text
                              fontWeight="600"
                              pt={1}
                              ml={1}
                              fontSize="sm"
                              color={main}
                            >
                              {t('Cash Plus')}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                      <Image maxW="12%" h="auto" src={CashPlus} />
                    </Flex>
                    <Flex
                      onClick={handleCredit}
                      bg={bg}
                      cursor="pointer"
                      shadow="md"
                      p={4}
                      borderRadius={15}
                      mb={3}
                      mt={2}
                      w="100%"
                      justify="space-between"
                    >
                      <Box>
                        <Flex>
                          <Box>
                            {' '}
                            <Text
                              fontWeight="600"
                              ml={1}
                              fontSize="sm"
                              color={main}
                            >
                              {t('Utiliser mon portfeuille de crédit de promotion')}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                      <Icon as={FaWallet} boxSize={25} color={main} />
                    </Flex>
                    <Modal isOpen={isModalOpen} onClose={handleCancel}>
                      <ModalOverlay />
                      <ModalContent bg={bg} borderRadius="md">
                        <ModalHeader fontWeight="bold" color={main}>
                          {t(`Confirmer l'utilisation du crédit`)}
                        </ModalHeader>
                        <ModalBody>
                          <Text fontSize="lg" mb={3}>
                            {t(`Crédit actuel :`)}{' '}
                            <Text as="span" color={mainBrand} fontWeight="bold">
                              {user.credit} {t('crédits')}
                            </Text>
                          </Text>
                          <Text fontSize="lg" mb={3}>
                            {t('Nouveau crédit mis à jour :')}{' '}
                            <Text as="span" color={mainBrand} fontWeight="bold">
                              {newUpdatedCredit} {t('crédits')}
                            </Text>
                          </Text>
                          <Text fontSize="md" color="gray.600">
                            {t('Voulez-vous utiliser votre crédit pour cet achat ?')}
                          </Text>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={handleConfirm}
                          >
                            {t('Confirmer')}
                          </Button>
                          <Button onClick={handleCancel} variant="outline">
                            {t('Annuler')}
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </Card>
                  <Flex w="100%">
                    <Button
                      w="100%"
                      leftIcon={<MdDelete />}
                      onClick={handleCancelOrder}
                      colorScheme="red"
                      variant="solid"
                    >
                      {t('Annuler la commande')}
                    </Button>
                  </Flex>
                </>
              )}
            {order?.cashReservationId && (
              <Card mt={5} p="4" mb="4" shadow="lg" bg={bg}>
                <Flex w="100%" justify="space-between">
                  {' '}
                  <Heading size="md" mb="1" color={main}>
                    {order?.cashReservationStatus}
                  </Heading>
                  <Image maxW="12%" h="auto" src={CashPlus} />
                </Flex>
                <Text mb="2">{t('Réservation ID :')} #{order?.cashReservationId}</Text>

                <Flex align="center">
                  <Link
                    href={order.cashReservationURL}
                    color="purple.500"
                    mr="2"
                    display="flex"
                    alignItems="center"
                  >
                    <Button
                      as="span"
                      colorScheme="purple"
                      display="flex"
                      alignItems="center"
                    >
                      <ExternalLinkIcon mr="1" /> {t('Lien vers la réservation')}
                    </Button>
                  </Link>

                  <Button
                    as="a"
                    href={order?.cashReservationPDF}
                    colorScheme="brand"
                    leftIcon={<DownloadIcon />}
                  >
                    {t('Télécharger le PDF')}
                  </Button>
                </Flex>
              </Card>
            )}
          </Box>
        </Box>
      </Card>
      <Box dangerouslySetInnerHTML={{ __html: paywallForm }}></Box>
    </>
  );
};
export default Cart;
