import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import { t } from 'helpers/TransWrapper';
import { useColorMode } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';

// Custom components
import Banner from 'views/admin/marketplace/components/Banner';
import TableTopCreators from 'views/admin/marketplace/components/TableTopCreators';
import HistoryItem from 'views/ads/recentAds/components/HistoryItem';
import NFT from 'components/card/NFT';
import Card from 'components/card/Card.js';

// Assets
import Nft1 from 'assets/img/nfts/Nft1.png';
import Nft2 from 'assets/img/nfts/Nft2.png';
import Nft3 from 'assets/img/nfts/Nft3.png';
import Nft4 from 'assets/img/nfts/Nft4.png';
import Nft5 from 'assets/img/nfts/Nft5.png';
import Nft6 from 'assets/img/nfts/Nft6.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import moment from 'moment';

import { AiOutlineIdcard, AiOutlineOrderedList } from 'react-icons/ai';
import '../../admin/marketplace/NFT.css';
import tableDataTopCreators from 'views/admin/marketplace/variables/tableDataTopCreators.json';
import { tableColumnsTopCreators } from 'views/admin/marketplace/variables/tableColumnsTopCreators';
import AdAsList from 'views/ads/recentAds/components/AdAsList';
import LoadingScreen from './LoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import { setRecentAds } from '../../../state/recentAds/recentAdsSlice';
import LazyLoad from 'react-lazyload';

export default function RecentAds() {
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const [ads, setAds] = useState([]);
  const [SelectedAd, setSelectedAd] = useState([]);
  const history = useHistory();
  const [showLList, setshowList] = useState(false);
  const [showCard, setshowCard] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const RecentAds = useSelector(state => state.recentAds.recentAds);
  const dispatch = useDispatch();
  const [adData, setAdData] = useState([]);
  const [promotedAds, setPromotedAds] = useState([]);

  const getAdById = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/ad/${id}`
      );
      setSelectedAd(response.data);

      history.push(`/ads/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (RecentAds.length < 1) {
        setIsLoading(true);

        try {
          // Fetch ads and user data in a single request
          const response = await axios.get(`${process.env.REACT_APP_API}/ads`);
          const ads = response.data;

          const adData = await Promise.all(
            ads.map(async ad => {
              const userResponse = await axios.get(
                `${process.env.REACT_APP_API}/users/${ad.userId}`
              );
              const user = userResponse.data;
              return user ? { ad, user } : null;
            })
          );

          dispatch(setRecentAds(adData.filter(Boolean)));
          setAdData(adData.filter(Boolean));
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, RecentAds.length]);
  useEffect(() => {
    const fetchPromoAds = async () => {
      try {
        // Fetch ads and user data in a single request
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/promoted/ads`
        );
        const ads = response.data;

        const adData = await Promise.all(
          ads.map(async ad => {
            const userResponse = await axios.get(
              `${process.env.REACT_APP_API}/users/${ad.userId}`
            );
            const user = userResponse.data;
            return user ? { ad, user } : null;
          })
        );

        console.log('promoted ads', adData);
        setPromotedAds(adData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPromoAds();
  }, []);
  const [test, setTest] = useState(true);
  const getLikers = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${id}/like`
      );

      const likerIds = response.data;

      const likerData = [];

      for (const likerId of likerIds) {
        const userDataResponse = await axios.get(
          `${process.env.REACT_APP_API}/users/${likerId}`
        );
        const userData = userDataResponse.data;
        likerData.push(userData);

        console.log('User Data:', userData); // Log the user data for debugging
      }

      const profilePictures = likerData.map(liker => liker.profilePicture);

      console.log('Profile Pictures:', profilePictures); // Log profile pictures for debugging

      return profilePictures;
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };
  const NFTWrapper = ({ ad, user }) => {
    const [likersForAd, setLikersForAd] = useState([]);

    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }

      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <NFT
        id={ad._id}
        discountShop={ad.discountShop}
        isShopPromoted={ad.isShopPromoted}
        promotedPrice={`${ad.promotedPrice} MAD`}
        bidders={likersForAd}
        name={ad.name}
        author={`${user.firstName} ${user.lastName}`}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        category={ad.categoryLabel}
        propart={ad?.ProPart}
        currentbid={ad.price === 0 ? t('Non défini') : ad.price + t(` MAD`)}
        Click={handleClick}
        city={ad.city}
        dateCreated={
          ad.validatedAt
            ? moment(ad.validatedAt).fromNow()
            : moment(ad.createdAt).fromNow()
        }
        nbrPictures={ad.adPictures.length + t(` images `)}
      />
    );
  };
  const PromotedAdsWrapper = ({ ad, user }) => {
    const [likersForAd, setLikersForAd] = useState([]);

    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }

      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <AdAsList
        id={ad._id}
        title={ad.name}
        category={ad.categoryLabel}
        //link={ad.name}
        price={ad.price === 0 ? t('Non défini') : ad.price + t(` MAD`)}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        city={ad.city}
        bidders={likersForAd}
        dateCreated={
          ad.validatedAt
            ? moment(ad.validatedAt).fromNow()
            : moment(ad.createdAt).fromNow()
        }
        propart={ad.shop ? 'pro' : 'part'}
        nbrPictures={ad.adPictures.length + t(` images `)}
        Click={handleClick}
        discountShop={ad.discountShop}
        isShopPromoted={ad.isShopPromoted}
        promotedPrice={ad.promotedPrice + t(` MAD`)}
      />
    );
  };
  const ListWrapper = ({ ad, user }) => {
    const [likersForAd, setLikersForAd] = useState([]);

    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }

      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <AdAsList
      id={ad._id}
      title={ad.name}
      category={ad.categoryLabel}
      //link={ad.name}
      price={ad.price === 0 ? t('Non défini') : ad.price + t(` MAD`)}
      image={
        ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
          ? ad.adPictures[0]
          : Nft3
      }
      city={ad.city}
      bidders={likersForAd}
      dateCreated={
        ad.validatedAt
          ? moment(ad.validatedAt).fromNow()
          : moment(ad.createdAt).fromNow()
      }
      propart={ad.shop ? 'pro' : 'part'}
      nbrPictures={ad.adPictures.length + t(` images `)}
      Click={handleClick}
      discountShop={ad.discountShop}
      isShopPromoted={ad.isShopPromoted}
      promotedPrice={ad.promotedPrice + t(` MAD`)}
    />
    );
  };

  const items = Array.from({ length: 12 });

  const { colorMode } = useColorMode();

  const bgColor = colorMode === 'dark' ? 'rgb(17, 28, 68)' : 'gray.200';

  return (
    <Box>
      {isLoading === false ? (
        <Box pt={{ base: '120px', md: '80px', xl: '30px' }}>
          {' '}
          {/* Main Fields */}{' '}
          <Grid
            mb="20px"
            gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
            gap={{ base: '20px', xl: '10px' }}
            display={{ base: 'block', xl: 'grid' }}
          >
            <Flex
              flexDirection="column"
              gridArea={{ xl: '4 / 3 / 1 / 1 ', '2xl': '1 / 1 / 2 / 2' }}
            >
              <Card mb={5}>
                <Flex>
                  <Button
                    mr={5}
                    w={100}
                    h={10}
                    borderRadius={20}
                    leftIcon={<AiOutlineIdcard />}
                    colorScheme="teal"
                    variant="solid"
                    onClick={() => [
                      setshowCard(true),
                      setshowList(false),
                      setTest(true),
                    ]}
                  >
                    Cartes{' '}
                  </Button>{' '}
                  <Button
                    w={100}
                    h={10}
                    borderRadius={20}
                    leftIcon={<AiOutlineOrderedList />}
                    colorScheme="pink"
                    variant="solid"
                    onClick={() => [
                      setshowCard(false),
                      setshowList(true),
                      setTest(false),
                    ]}
                  >
                    Listes{' '}
                  </Button>{' '}
                </Flex>{' '}
              </Card>
              <Flex direction="column">
                {' '}
                {showLList && RecentAds
                  ? RecentAds.map(({ ad, user }) => {
                      const handleClick = () => {
                        getAdById(ad._id); // Get the ad by its id when the component is clicked
                      };

                      return (
                        <LazyLoad height={200} offset={100}>
                          <Flex
                            mb={5}
                            key={ad._id}
                            onClick={handleClick}
                            cursor="pointer"
                          >
                            <ListWrapper ad={ad} user={user} />
                          </Flex>
                        </LazyLoad>
                      );
                    })
                  : null}{' '}
                {showCard && (
                  <SimpleGrid
                    columns={{ base: 1, md: 3 }}
                    gap="15px"
                    mb={{ base: '20px', xl: '0px' }}
                  >
                    {' '}
                    {RecentAds &&
                      RecentAds.map(({ ad, user }) => {
                        const handleClick = () => {
                          getAdById(ad._id); // Get the ad by its id when the component is clicked
                        };

                        return (
                          <LazyLoad height={200} offset={100}>
                            <Flex key={ad._id} className="nft">
                              <LazyLoad height={200} offset={100}>
                                <NFTWrapper ad={ad} user={user} />
                              </LazyLoad>
                            </Flex>
                          </LazyLoad>
                        );
                      })}{' '}
                  </SimpleGrid>
                )}{' '}
              </Flex>{' '}
            </Flex>
            <Flex flexDirection="column" mr="-80px">
              <Card w="100%" p="5px" mb="20px">
                <Flex
                  align={{ sm: 'flex-start', lg: 'flex-start' }}
                  w="100%"
                  px="12px"
                  py="18px"
                >
                  <Text color={textColor} fontSize="xl" fontWeight="600">
                    Sponsorisées
                  </Text>
                </Flex>
                {promotedAds.map(({ ad, user }) => {
                  return <PromotedAdsWrapper ad={ad} user={user} />;
                })}
              </Card>
            </Flex>{' '}
          </Grid>{' '}
        </Box>
      ) : (
        <>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            gap="10px"
            mb={{ base: '20px', xl: '0px' }}
          >
            {items.map((_, index) => {
              return (
                <Flex key={index} className="nft" mb={5} mt={5}>
                  <Box borderRadius="lg" overflow="hidden" bg={bgColor}>
                    <Skeleton
                      height={{ base: '200px', md: '200px' }}
                      width={test ? '300px' : '800px'}
                      startColor={
                        colorMode === 'dark' ? 'gray.700' : 'gray.200'
                      }
                      endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                    />
                    {/* <Box height="" width="600px"></Box> */}
                    <Box p="6">
                      <Box
                        mt="1"
                        fontWeight="semibold"
                        as="h4"
                        lineHeight="tight"
                        isTruncated
                        color={textColor}
                      >
                        <Skeleton
                          height="10px"
                          width={{ base: '50%', md: '30%' }}
                          mb={2}
                          startColor={
                            colorMode === 'dark' ? 'gray.700' : 'gray.200'
                          }
                          endColor={
                            colorMode === 'dark' ? 'gray.600' : 'gray.300'
                          }
                        />
                        <Box
                          bg={bgColor}
                          height="20px"
                          width="50%"
                          mb={2}
                        ></Box>
                        <Skeleton
                          height="10px"
                          width={{ base: '20%', md: '10%' }}
                          mr={2}
                          startColor={
                            colorMode === 'dark' ? 'gray.700' : 'gray.200'
                          }
                          endColor={
                            colorMode === 'dark' ? 'gray.600' : 'gray.300'
                          }
                        />
                        <Box
                          bg={bgColor}
                          height="20px"
                          width="50%"
                          mb={2}
                        ></Box>
                        {test ? null : (
                          <Box
                            position="absolute"
                            top="80%"
                            left="30%"
                            transform="translate(-50%, -50%)"
                          >
                            <Skeleton
                              isLoaded={!isLoading}
                              borderRadius="full"
                              height="50px"
                              width="50px"
                            ></Skeleton>
                          </Box>
                        )}
                      </Box>
                      <Box
                        d="flex"
                        alignItems="baseline"
                        color={textColor}
                        fontSize="sm"
                      >
                        <Box
                          bg={bgColor}
                          height="10px"
                          width="20%"
                          mr={2}
                        ></Box>
                        <Box
                          bg={bgColor}
                          height="10px"
                          width="30%"
                          mr={2}
                        ></Box>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              );
            })}
          </SimpleGrid>
        </>
      )}
    </Box>
  );
}
