// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Badge,
} from '@chakra-ui/react';
import { t } from "helpers/TransWrapper";

// Custom components
import Card from 'components/card/Card.js';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';
import { MdImage } from 'react-icons/md';
import Nft3 from 'assets/img/nfts/Nft3.png';

import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { MdLocationOn, MdAccessTimeFilled } from 'react-icons/md';
import { FaHeart, FaHandPointer } from 'react-icons/fa';
// Assets
import { FaStoreAlt } from 'react-icons/fa';
import { FaUserTie } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import '../../../admin/marketplace/NFT.css';
import { useFavorites } from '../../../../contexts/FavoritesContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function AdAsList(props) {
  const {
    id,
    title,
    category,
    propart,
    bidders,
    price,
    image,
    dateCreated,
    city,
    Click,
    discountShop,
    nbrPictures,
    promotedPrice,
    isShopPromoted,
  } = props;
  const { listFavorites, updateFavorites } = useFavorites();

  // Chakra Color Mode
  const [like, setLike] = useState(false);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColorBid = useColorModeValue('brand.500', 'white');
  const currentUserS = localStorage.getItem('user-token');

  const bg = useColorModeValue('white', 'navy.800');
  const history = useHistory();
  const currentUser = JSON.parse(currentUserS);
  const [fav, setFav] = useState(false);
  const [pre, setPre] = useState(true);
  const handleLike = async () => {
    setFav(!fav);

    if (currentUser) {
      try {
        await axios.patch(
          `${process.env.REACT_APP_API}/ads/${id}/like`,
          { userId: currentUser._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        // Update the local favorite list
        if (fav) {
          updateFavorites();
          toast.error(t('Cette annonce a été retirée de votre liste de favoris'), {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else {
          updateFavorites();
          toast.success(
            t('Cette annonce a été ajoutée à votre liste de favoris!'),
            {
              // Corrected from toast.sucess to toast.success
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            }
          );
        }
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    } else {
      toast.error(
        t('Pour sauvegarder vos annonces préférées, veuillez vous connecter !'),
        {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      history.push('/auth/login');
    }
  };

  return (
    <Card bg={bg} p="4px">
      {isShopPromoted && (
        <Box
          position="absolute"
          bottom="50%"
          right="5px"
          align="center"
          justify="center"
        >
          <Badge fontSize="sm" colorScheme="red">
            -{discountShop}%
          </Badge>
        </Box>
      )}
      {propart === 'pro' ? (
        <Flex
          position="absolute"
          top="-6px"
          left="-6px"
          align="center"
          justify="center"
          bg="green.500"
          borderRadius="full"
          w="35px"
          h="35px"
          zIndex={1}
          boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
        >
          <IconButton
            icon={<FaStoreAlt />}
            aria-label={t("Shop Account")}
            variant="solid"
            colorScheme="white"
            size="xl"
          />
        </Flex>
      ) : (
        <Flex
          position="absolute"
          top="-6px"
          left="-6px"
          align="center"
          justify="center"
          bg="red.500"
          borderRadius="full"
          w="35px"
          zIndex={1}
          h="35px"
          boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
        >
          <IconButton
            icon={<FaUserTie />}
            aria-label={t("Shop Account")}
            variant="solid"
            colorScheme="white"
            size="xl"
          />
        </Flex>
      )}
      <Box textAlign='left' align="start" display="flex">
        <Box onClick={Click} align="start" position="relative">
          <Image
            h="120px"
            w="110px"
            src={image}
            borderRadius="8px"
            me="20px"
            objectFit="cover"
            fallbackSrc={Nft3}
          />
          <Box
            position="absolute"
            bottom="0"
            left="35%"
            transform="translateX(-50%)"
            zIndex="0"
          >
            <Box
              textAlign="start"
              bg="rgba(0, 0, 0, 0.6)"
              px="6px"
              py="px"
              borderRadius="md"
              mb="1"
            >
              <Text fontSize="xs" fontWeight="bold" color="white">
                <Icon as={MdImage} mr="1" />
                {image === Nft3 ? '0 images' : nbrPictures}
              </Text>
            </Box>
          </Box>
        </Box>

        <Box align="start" mt={{ base: '2px', md: '0' }}>
          <Text
            color={brandColor}
            fontWeight="500"
            fontSize="md"
            style={{
              textDecoration: isShopPromoted ? 'line-through' : 'none',
              textDecorationThickness: isShopPromoted ? '2px' : 'auto',
            }}
          >
            {' '}
            {price}
          </Text>
          {isShopPromoted && (
            <Text color={brandColor} fontWeight="600" fontSize="lg">
              {' '}
              {promotedPrice}{' '}
            </Text>
          )}
          <Text
            onClick={Click}
            color={brandColor}
            fontWeight="500"
            fontSize="sm"
          >
            {title}
          </Text>

          <Text
            onClick={Click}
            fontWeight="500"
            color={textColorSecondary}
            fontSize="sm"
          >
            {category}
          </Text>
          <Flex
            position="absolute"
            top="-6px"
            right="-6px"
            align="center"
            justify="center"
            bg={fav || listFavorites.includes(id) ? 'red.500' : 'gray.500'}
            borderRadius="full"
            w="35px"
            h="35px"
            boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
          >
            <IconButton
              icon={<FaHeart />}
              aria-label={t("Favorite")}
              variant="solid"
              colorScheme="white"
              size="xl"
              onClick={handleLike}
            />
          </Flex>
          <Flex justify='space-around'>
            <Flex>
              {' '}
              <Icon
                w={3}
                h={3}
                mt="3px"
                mr="2px"
                as={MdLocationOn}
                color="secondaryGray.600"
              />
              <Text
                mr={6}
                fontWeight="600"
                fontSize="xs"
                color="secondaryGray.600"
              >
                {city}
              </Text>
            </Flex>
            <Flex>
              <Icon
                mt="3px"
                mr="2px"
                w={3}
                h={3}
                as={MdAccessTimeFilled}
                color="secondaryGray.600"
              />
              <Text fontWeight="600" fontSize="xs" color="secondaryGray.600">
                {dateCreated}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Card>
  );
}
