import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import Chat from 'layouts/chat';
import Register from 'layouts/register';
import Infos from 'layouts/infos';
import Categories from 'layouts/categories';
import Ads from 'layouts/ads';
import Search from 'layouts/search';
import Ad from 'layouts/ad';
import Shop from 'layouts/shop';
import authReducer from './state';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import store, { persistor } from './state/store';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import RTLLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import axios from 'axios';
import ScrollToTop from './helpers/ScrollToTop';
import { IntlProvider } from 'react-intl';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { tx } from '@transifex/native';
import { T } from '@transifex/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import { t, getLocale } from './helpers/TransWrapper';
import WhatsAppIcon from './whatsappTesting';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer, toast } from 'react-toastify';
import { SocketProvider } from './providers/SocketProvider';
import 'react-toastify/dist/ReactToastify.css';
import { FavoritesProvider } from './contexts/FavoritesContext'; // Import the FavoritesProvider
import { composeWithDevTools } from 'redux-devtools-extension';
import { legacy_createStore as createStore } from 'redux';
import Popup from './Popup'; // Import the Popup component
import { messaging } from './firebase'; // Import messaging from firebase.js
import { getToken, onMessage } from 'firebase/messaging';
tx.init({
  token: '1/0e4083104263e17b6c2cdbb6476dc765cf4b8264',
});

const queryClient = new QueryClient();

const App = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const storeTokenInBackend = async token => {
    try {
      const user = JSON.parse(localStorage.getItem('user-token'));
      if (!user || !user._id) {
        console.error('No valid user found in localStorage.');
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API}/users/token/store`,
        {
          token, // Send the token
          userId: user._id, // Send the userId
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Authorization token
          },
        }
      );

      console.log('FCM token stored in backend:', response.data);
    } catch (error) {
      console.error('Error storing token in backend:', error);
    }
  };

  useEffect(() => {
    // Logic to show the popup
    setIsPopupOpen(true);
  }, []);
  useEffect(() => {
    const requestPermission = async () => {
      try {
        // Register the service worker
        const registration = await navigator.serviceWorker.register(
          '/firebase-messaging-sw.js'
        );

        // Use the service worker with Firebase messaging

        // Request permission and get the token
        const currentToken = await getToken(messaging, {
          vapidKey:
            'BFmpaehhLiQVz9H-0ljdiAKEHSk8kpj8g8v5CTNX0TxMVzO8SEjdZk6Vf_WfSQXRjIznaAQzWVyRc14bO0BQG90',
          serviceWorkerRegistration: registration,
        });

        if (currentToken) {
          console.log('Token received:', currentToken);
          await storeTokenInBackend(currentToken);
          const updatedUser = JSON.parse(localStorage.getItem('user-token'));
          if (updatedUser) {
            updatedUser.deviceToken = currentToken; // Update user's device token
            localStorage.setItem('user-token', JSON.stringify(updatedUser)); // Save updated user in localStorage
            console.log('User updated in localStorage with new FCM token');
          } else {
            console.warn('User object not found in localStorage');
          }
        } else {
          console.warn('No registration token available.');
        }
      } catch (err) {
        console.error(
          'Error during service worker registration or token retrieval:',
          err
        );
      }
    };

    // Call the request permission function
    requestPermission();

    // Listen for incoming messages when the app is in the foreground
    const unsubscribeOnMessage = onMessage(messaging, payload => {
      console.log('Message received. ', payload);
      toast(`${payload.notification.title}: ${payload.notification.body}`);
    });

    // Clean up the listener on component unmount
    return () => {
      unsubscribeOnMessage(); // Unsubscribe to avoid memory leaks
    };
  }, []);

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId="157748997984-fbpdo0bkfvv8t0cs9so42s1ghp35k6qs.apps.googleusercontent.com">
          <ChakraProvider theme={theme}>
            <FavoritesProvider>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <IntlProvider locale={getLocale()} i18n={i18n}>
                    <ThemeEditorProvider>
                      <HashRouter  basename="/">
                        <ScrollToTop />
                      
                        <Switch>
                          <Route
                            path={`/auth`}
                            render={props => <AuthLayout {...props} t={t} />}
                          />
                          <Route
                            path={`/register`}
                            render={props => <Register {...props} t={t} />}
                          />
                          <Route
                            path={`/app`}
                            render={props => <AdminLayout {...props} t={t} />}
                          />
                          <Route path={`/ad`} component={Ad} />
                          <Route path={`/shop`} component={Shop} />
                          <Route path={`/chat`} component={Chat} />
                          <Route path={`/ads`} component={Ads} />
                          <Route path={`/search`} component={Search} />
                          <Route path={`/infos`} component={Infos} />
                          <Route path={`/categories`} component={Categories} />
                          <Route path={`/rtl`} component={RTLLayout} />
                        <Route render={() => <Redirect to="/app/home" />} />
                        </Switch>
                        <ToastContainer
                          position="bottom-right"
                          autoClose={5000}
                        />
                      </HashRouter>
                    </ThemeEditorProvider>
                  </IntlProvider>
                </PersistGate>
              </Provider>
            </FavoritesProvider>
          </ChakraProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
