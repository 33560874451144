import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Spinner,
  TableContainer,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useToast,
  Tooltip,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { FaEllipsisV } from 'react-icons/fa';
import React, { useMemo, useState, useEffect } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { t } from 'helpers/TransWrapper';
import Swal from 'sweetalert2';

// Custom components
import Card from 'components/card/Card';

export default function CheckTable(props) {
  const { columnsData } = props;
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAd, setSelectedAd] = useState(null);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedCategory, setUpdatedCategory] = useState('');
  const [updatedSubCategory, setUpdatedSubCategory] = useState('');
  const history = useHistory();
  const toast = useToast();
  const [categories, setCategories] = useState([]);
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');
  const [selectedSubcategoryLabel, setSelectedSubcategoryLabel] = useState('');

  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI modal hook
  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/admin/allAds`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const adData = response.data;
      const newData = adData.map(item => {
        return {
          action: item._id,
          nom: item.name,
          date: new Date(item.createdAt).toLocaleString(),
          annonceur: `${item.firstName} ${item.lastName}`,
          shop: item.shop ? (
            item.shop
          ) : (
            <Badge colorScheme="red">No Shop</Badge>
          ),
          souscategorie: item.subcategoryLabel,
          categorie: item.categoryLabel,
          ville: item.city,
          categoryId: item.category, // Assuming this is the category ID
          subCategoryId: item.subcategory, // Assuming this is the subcategory ID
        };
      });
      setTableData(newData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
   

    fetchData();
  }, []);

  const columns = useMemo(() => columnsData, [columnsData]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex },
  } = tableInstance;

  initialState.pageSize = 6;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/categories`)
      .then(res => setCategories(res.data))
      .catch(err => console.error(err));
  }, []);
  useEffect(() => {
    if (selectedCategoryId) {
      axios
        .get(`${process.env.REACT_APP_API}/subcategories/${selectedCategoryId}`)
        .then(res => setSubcategories(res.data))
        .catch(err => console.error(err));
    }
  }, [selectedCategoryId]);
  const deleteAd = async id => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/ads/ad/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const handleActionClick = (id, action) => {
    switch (action) {
      case 'view':
        history.push(`/ads/${id}`);
        break;
      case 'update':
        const adToUpdate = tableData.find(ad => ad.action === id);
        console.log('adToUpdate',adToUpdate);
        setSelectedAd(adToUpdate);
        setUpdatedName(adToUpdate?.nom); // Prepopulate the name field in the modal
        setUpdatedCategory(adToUpdate.categorie); // Prepopulate category
        setUpdatedSubCategory(adToUpdate.souscategorie); // Prepopulate subcategory
        onOpen();
        break;
      case 'delete':
        Swal.fire({
          title: t('Êtes-vous sûr(e) ?'),
          text: t("Vous ne pourrez pas revenir en arrière !"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: t('Oui, supprimer !'),
          cancelButtonText: t('Annuler'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
             
             await deleteAd(id);
            
      
              Swal.fire(t('Supprimée !'), t('Vos annonces sélectionnées ont été supprimées.'), t('success'));
              fetchData();

            } catch (error) {
              console.error(error);
              Swal.fire(('Erreur'), t('Une erreur s\'est produite lors de la suppression des annonces.'), t('error'));
            }
      
            
          }
        });
        break;
      default:
        break;
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API}/ads/${selectedAd.action}/admin/update`,
        {
          name: updatedName,
          categoryName: updatedCategory,
          subcategoryName: updatedSubCategory,
          categoryLabel: selectedCategoryLabel,
          subcategoryLabel: selectedSubcategoryLabel,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      toast({
        title: t('Update successful'),
        description: t('The ad has been updated.'),
        status: t('success'),
        duration: 5000,
        isClosable: true,
      });
      onClose(); // Close modal after update
      fetchData();
      // Optionally, you can refetch the data here to reflect the changes
    } catch (error) {
      console.log(error);
      toast({
        title: t('Update failed'),
        description: t('There was an error while updating the ad.'),
        status: t('error'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Card direction="column" w="100%" px="0px">
        <Flex px="25px" justify="center" align="center" h="100%">
          <Spinner size="xl" />
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex px="25px" justify="space-between" align="center" mb="20px">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {t('Annonces en cours de validation')}
          </Text>
        </Flex>
        <TableContainer>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            size="sm"
          >
            <Thead>
              {headerGroups?.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page?.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells?.map((cell, index) => {
                      if (cell.column.id === 'action') {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            maxW={{ sm: '150px', md: '200px', lg: '300px' }}
                            borderColor="transparent"
                            isTruncated
                          >
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                icon={<FaEllipsisV />}
                                variant="outline"
                                size="sm"
                              />
                              <MenuList>
                                <MenuItem
                                  onClick={() =>
                                    handleActionClick(
                                      row.original.action,
                                      'view'
                                    )
                                  }
                                >
                                  {t('View')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleActionClick(
                                      row.original.action,
                                      'update'
                                    )
                                  }
                                >
                                  {t('Update')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleActionClick(
                                      row.original.action,
                                      'delete'
                                    )
                                  }
                                >
                                  {t('Delete')}
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          maxW={{ sm: '100px', md: '100px', lg: '100px' }}
                          borderColor="transparent"
                          isTruncated
                        >
                          <Tooltip label={cell.render('Cell')} placement="top">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.render('Cell')}
                            </Text>
                          </Tooltip>
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex px="25px" justify="space-between" align="center">
          <Button
            variant="brand"
            p="auto"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {t('Previous')}
          </Button>
          <Text fontSize="sm" color={textColor}>
            {t('Page')} {pageIndex + 1} {t('of')} {pageOptions.length}
          </Text>
          <Button
            variant="brand"
            p="auto"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {t('Next')}
          </Button>
        </Flex>
      </Card>

      {/* Modal for updating ad details */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Update Ad')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>{t('Ad Name')}</FormLabel>
              <Input
                value={updatedName}
                onChange={e => setUpdatedName(e.target.value)}
                placeholder={t("Enter new name for the ad")}
              />
            </FormControl>
            <FormControl mt="4">
              <FormLabel>{t('Category')}</FormLabel>
              <Select
                id="category"
                name="categoryName"
                isRequired={true}
                size="md"
                variant="auth"
                value={updatedCategory}
                onChange={e => {
                  const selectedCategoryId =
                    e.target.options[e.target.selectedIndex].dataset.id;
                  const selectedCategory = categories.find(
                    category => category._id === selectedCategoryId
                  );
                  setUpdatedSubCategory(null);
                  setUpdatedCategory(selectedCategory?.name);
                  setSelectedCategoryId(selectedCategoryId);
                  setSelectedCategoryLabel(selectedCategory?.label);
                }}
                placeholder={t('Choisir une categorie')}
              >
                {categories.map(category => (
                  <option
                    key={category._id}
                    value={category.name}
                    name={category.label}
                    data-id={category._id}
                  >
                    {category.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt="4">
              <FormLabel>{t('Subcategory')}</FormLabel>
              <Select
                id="category"
                name="categoryName"
                isRequired={true}
                mb="24px"
                size="md"
                variant="auth"
                onChange={e => {
                  setUpdatedSubCategory(e.target.value);
                  setSelectedSubcategoryLabel(
                    e.target.options[e.target.selectedIndex].text
                  );
                }}
                value={updatedSubCategory}
                placeholder={t('Choisir une sous-categorie')}
              >
                {subcategories.map(subcategory => (
                  <option
                    key={subcategory._id}
                    value={subcategory.name}
                    name={subcategory.label}
                  >
                    {' '}
                    {subcategory.label}{' '}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={2} colorScheme="blue" onClick={handleUpdate}>
              {t('Update')}
            </Button>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
