import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  IconButton,
  Text,
  Heading,
  useBreakpointValue,
  Select,
  VStack,
  HStack,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useColorMode
} from '@chakra-ui/react';
import { FiRefreshCw, FiFilter } from 'react-icons/fi';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { toast } from 'react-toastify';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NotificationAnalytics = () => {

  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [audiences, setAudiences] = useState([]);
  const { colorMode } = useColorMode(); // Get the current color mode
  const fetchAudiences = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/audiences`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setAudiences(response.data.audiences);
   
    } catch (error) {
      toast.error('Failed to fetch audiences.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error fetching audiences:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {


    fetchAudiences();
  }, []);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/pushNotifications`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        let data = response.data.data;
        data = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setNotifications(data);
        setFilteredNotifications(data);

        // Filter delivery and failure statuses for the logs
        const notificationLogs = data.map((notif) => ({
          id: notif._id,
          message: notif.message,
          date: new Date(notif.createdAt).toLocaleDateString(),
          status: notif.status === 'sent' ? 'Success' : 'Failed',
        }));

        setLogs(notificationLogs);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);
  const audienceData = notifications.reduce((acc, notif) => {
    const { targetAudience, deliveryReport } = notif;
  
    if (!acc[targetAudience.type]) {
      acc[targetAudience.type] = { success: 0, failed: 0 };
    }
  
    acc[targetAudience.type].success += deliveryReport.success;
    acc[targetAudience.type].failed += deliveryReport.failed;
  
    return acc;
  }, {});
  console.log('audienceData',audienceData);
  const labels = Object.keys(audienceData); 
  const CLabels = labels.map(
    (key) => key.charAt(0).toUpperCase() + key.slice(1)
  );
const deliveredData = labels.map(audience => audienceData[audience].success);
const failedData = labels.map(audience => audienceData[audience].failed);
const totalSuccess = notifications.reduce((sum, notif) => sum + notif.deliveryReport.success, 0);
const totalFailed = notifications.reduce((sum, notif) => sum + notif.deliveryReport.failed, 0);
const totalNotifications = totalSuccess + totalFailed;
const colors = {
  light: {
    delivered: {
      background: 'rgba(54, 162, 235, 0.6)', // Blue
      border: 'rgba(54, 162, 235, 1)',
    },
    failed: {
      background: 'rgba(255, 99, 132, 0.6)', // Red
      border: 'rgba(255, 99, 132, 1)',
    },
    text: '#000000', // Dark text
    grid: '#cccccc', // Light grid
  },
  dark: {
    delivered: {
      background: 'rgba(54, 162, 235, 0.6)', // Same blue
      border: 'rgba(54, 162, 235, 1)',
    },
    failed: {
      background: 'rgba(255, 159, 64, 0.6)', // Orange for dark mode
      border: 'rgba(255, 159, 64, 1)',
    },
    text: '#e0e0e0', // Light text
    grid: '#444444', // Dark grid
  },
};

const modeColors = colors[colorMode];
const chartData = {
  labels: CLabels,
  datasets: [
    {
      label: 'Delivered Notifications',
      data: deliveredData,
      backgroundColor: modeColors?.delivered.background,
      borderColor: modeColors?.delivered.border,
      borderWidth: 1,
    },
    {
      label: 'Failed Notifications',
      data: failedData,
      backgroundColor: modeColors?.failed.background,
      borderColor: modeColors?.failed.border,
      borderWidth: 1,
    },
  ],
};

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: modeColors?.text, // Dynamic legend color
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: modeColors?.text, // Dynamic axis text color
        },
        grid: {
          color: modeColors?.grid, // Dynamic grid line color
        },
      },
      y: {
        ticks: {
          color: modeColors?.text, // Dynamic axis text color
        },
        grid: {
          color: modeColors?.grid, // Dynamic grid line color
        },
      },
    },
  };



  return (
    <Box p={5} boxShadow="md" borderRadius="md">
      <Heading as="h2" size="lg" mb={4}>Notification Delivery Analytics</Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={20}>
        {/* Chart */}
        <Box>
          <Heading as="h3" size="md" mb={2}>Delivery and Failure Rates</Heading>
          <Bar data={chartData} options={chartOptions} />
        </Box>

        {/* Analytics Stats */}
        <VStack align="start" spacing={4}>
          <Stat>
            <StatLabel>Total Notifications Sent</StatLabel>
            <StatNumber>{totalNotifications}</StatNumber>
            <StatHelpText>Push notification</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Success Rate</StatLabel>
            <StatNumber>
              {((totalSuccess / totalNotifications) * 100).toFixed(2)}%
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Failure Rate</StatLabel>
            <StatNumber>
              {((totalFailed / totalNotifications) * 100).toFixed(2)}%
            </StatNumber>
          </Stat>
        </VStack>
      </SimpleGrid>

  

    
    </Box>
  );
};

export default NotificationAnalytics;
