import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import {
  Flex,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  IconButton,
  useColorMode,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Box,
  Divider,
  Icon,
  CloseButton,
  useDisclosure,
  useColorModeValue, 
  useMediaQuery,
  useBreakpointValue,
  Avatar,
  Image
} from '@chakra-ui/react';
import {
  FaStop,
  FaPaperPlane,
  FaTrashAlt,
  FaImage,
  FaMicrophone,
  FaPlus,
  FaPaperclip,
} from 'react-icons/fa';
import Card from 'components/card/Card.js';
import Picker from 'emoji-picker-react';
import Recorder from 'recorder-js';
import WaveSurfer from 'react-wavesurfer';
import ReactPlayer from "react-player";
import ReactAudioWaveform from 'react-audio-waveform';
import { getStorage, ref, uploadBytes, getDownloadURL,deleteObject  } from 'firebase/storage';
import { storage } from '../../../../firebase';
import { v4 } from 'uuid';
import { FaFilePdf, FaFileWord, FaFileAlt } from 'react-icons/fa';
import Nft3 from 'assets/img/nfts/Nft3.png';
import EmojiPicker from 'emoji-picker-react';
import { t } from "helpers/TransWrapper";

const FileIcon = ({ fileName }) => {
  const fileExtension = fileName.split('.').pop().toLowerCase();
  switch (fileExtension) {
    case 'pdf':
      return <FaFilePdf  size='25'/>;
    case 'doc':
    case 'docx':
      return <FaFileWord />;
    default:
      return <FaFileAlt />;
  }
};

const MessageBubble = ({ fileName, fileSize, Isclosed, handleClose }) => {

  const bg = useColorModeValue('gray.200', 'navy.700');
  const [isMobileView] = useMediaQuery('(max-width: 1200px)');

  const dynamicWidth = useBreakpointValue({ base: '80%', md: '80%', xl: '84vh' });
  const dynamicBottom = useBreakpointValue({ base: '6rem', md: '11.2rem', xl: '84vh' });
  const getFileIcon = () => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return <FaFilePdf size='25' />;
      case 'doc':
      case 'docx':
        return <FaFileWord />;
      default:
        return <FaFileAlt />;
    }
  };

  return (
    isMobileView ? 
    (!Isclosed && (
      <Flex position='absolute' bottom={dynamicBottom}   width={dynamicWidth} >      
      <Card bg={bg}  maxW="100%" mt="5" p="3" borderTopRadius="none" >
      <Flex align="center" justify="space-between">
        <Flex align="center">
          {getFileIcon()}
          <Text fontSize="lg" ml={2}>
            {fileName}
          </Text>
          <Divider orientation="vertical" mx={2} h="20px" />
          <Text fontSize="sm" color="gray.600">
            {(fileSize / 1024).toFixed(2)} KB
          </Text>
        </Flex>
        <CloseButton onClick={handleClose} />
      </Flex>
    </Card></Flex>

    ) ): (!Isclosed && (
      <Flex position='absolute' bottom='76px' w="100%" >      
      <Card bg={bg}  maxW="100%" mt="5" p="3" borderTopRadius="none" >
      <Flex align="center" justify="space-between">
        <Flex align="center">
          {getFileIcon()}
          <Text fontSize="lg" ml={2}>
            {fileName}
          </Text>
          <Divider orientation="vertical" mx={2} h="20px" />
          <Text fontSize="sm" color="gray.600">
            {(fileSize / 1024).toFixed(2)} KB
          </Text>
        </Flex>
        <CloseButton onClick={handleClose} />
      </Flex>
    </Card></Flex>

    ))
  );
};
const ImageBubble = ({ fileName, fileSize, Isclosed, handleClose, imageAvatar }) => {

  const bg = useColorModeValue('gray.200', 'navy.700');
  const [isMobileView] = useMediaQuery('(max-width: 1200px)');

  const dynamicWidth = useBreakpointValue({ base: '80%', md: '80%', xl: '84vh' });
  const dynamicBottom = useBreakpointValue({ base: '6rem', md: '11.2rem', xl: '84vh' });

  // Mock URL for image avatar, replace this with actual image URL
   // Add the URL of the image avatar

  const handleImageError = (event) => {
    event.target.src = Nft3; // Add a fallback image URL in case the avatar fails to load
  };

  return (
    isMobileView ? (
      !Isclosed && (
        <Flex position="absolute" bottom={dynamicBottom} width={dynamicWidth}>
          <Card bg={bg} maxW="100%" mt="5" p="3" borderTopRadius="none">
            <Flex align="center" justify="space-between">
              <Flex align="center">
                <Avatar src={imageAvatar} onError={handleImageError} size="sm" />
                <Text fontSize="lg" ml={2}>
                  {fileName}
                </Text>
                <Divider orientation="vertical" mx={2} h="20px" />
                <Text fontSize="sm" color="gray.600">
                  {(fileSize / 1024).toFixed(2)} KB
                </Text>
              </Flex>
              <CloseButton onClick={handleClose} />
            </Flex>
          </Card>
        </Flex>
      )
    ) : (
      !Isclosed && (
        <Flex position="absolute" bottom="76px" w="100%">
          <Card bg={bg} maxW="100%" mt="5" p="3" borderTopRadius="none">
            <Flex align="center" justify="space-between">
              <Flex align="center">
                <Image src={imageAvatar} h='auto' w='10rem' onError={handleImageError}  />
                <Text fontSize="lg" ml={5}>
                  {fileName}
                </Text>
                <Divider orientation="vertical" mx={2} h="20px" />
                <Text fontSize="sm" color="gray.600">
                  {(fileSize / 1024).toFixed(2)} KB
                </Text>
              </Flex>
              <CloseButton onClick={handleClose} />
            </Flex>
          </Card>
        </Flex>
      )
    )
  );
};

const AudioVisualizer = ({ colorMode }) => {
  const canvasRef = useRef(null);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let audioContext = new (window.AudioContext || window.webkitAudioContext)();
    let canvas = canvasRef.current;
    let canvasCtx = canvas.getContext('2d');
    let analyser = audioContext.createAnalyser();
    let microphone;

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);
        visualize();
      })
      .catch(err => {
        console.error('Error accessing the microphone:', err);
      });

      const visualize = () => {
        analyser.fftSize = 2048;
        const bufferLength = analyser.fftSize;
        const dataArray = new Uint8Array(bufferLength);
  
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
  
        const draw = () => {
          const drawVisual = requestAnimationFrame(draw);
          analyser.getByteTimeDomainData(dataArray);
  
          if (colorMode === 'light') {
            canvasCtx.fillStyle = 'rgb(255, 255, 255)';
            canvasCtx.strokeStyle = '#1B254B';
          } else {
            canvasCtx.fillStyle = '#1B254B';
            canvasCtx.strokeStyle = 'rgb(255, 255, 255)';
          }
  
          canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
          canvasCtx.lineWidth = 2;
  
          const barWidth = (canvas.width / bufferLength) * 2.5;
          let x = 0;
  
          for (let i = 0; i < bufferLength; i++) {
            const v = dataArray[i] / 128.0;
            const y = (v * canvas.height) / 2;
  
            if (colorMode === 'light') {
              canvasCtx.fillStyle = '#422AFB'; // Change to the desired light color
            } else {
              canvasCtx.fillStyle = '#A195FD'; // Change to the desired dark color
            }
  
            canvasCtx.fillRect(x, canvas.height - y, barWidth, y);
  
            x += barWidth + 1;
          }
        };
  
        draw();
      };


    // Timer
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer + 1);
    }, 1000);

    return () => {
      if (microphone) {
        microphone.disconnect();
      }
      clearInterval(interval);
    };
  }, [colorMode]);

  // Change color mode on button click
  const textColor = useColorModeValue('#422AFB', 'white');
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  return (
    <Flex w="100%">
      <canvas
      
        style={{
          borderRadius: '15px',
          width: '100%',
          height: '40px', // Change the height as desired
          position: 'relative', // Update position if needed
          // Add other styles as necessary
        }}
        ref={canvasRef}
      ></canvas>
      <Box mt={2} ml={2}>
        <Text color={textColor} fontWeight='bold' > {formatTime(timer)}</Text>
      </Box>
      
    </Flex>
  );
};
const Footer = ({ inputMessage,setInputMessage, setVoiceUrl,voiceUrl, handleSendMessage,fileUrl, imageUrl, setFileUrl, setImageUrl }) => {
  const { colorMode } = useColorMode();
  const bg = useColorMode('gray.100', 'navy.700');
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      console.log('Enter key pressed');
      handleSendMessage();
    }
  };
  const [audioSrc, setAudioSrc] = useState(null);
    const { onOpen, onClose, isOpen } = useDisclosure()
  const [recording, setRecording] = useState(false);
  const [Isclosed, setIsClosed] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const recorder = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null); // State to store selected file information
  const [selectedImage, setSelectedImage] = useState(null); // State to store selected file information
  const [previewImage, setPreviewImage] = useState('');
  const [audioChunks, setAudioChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
    const [chosenEmoji, setChosenEmoji] = useState(null); // State to store selected file information

    const startRecording = async () => {
      setAudioSrc(null);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks = []; // Initialize an empty array to store audio chunks
      setMediaRecorder(recorder);

      recorder.addEventListener('dataavailable', (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data); // Append the received audio data to the chunks array
        }
      });
    
      recorder.addEventListener('stop', () => {
        const audioBlob = new Blob(chunks, { type: 'audio/wav' });
        const audioURL = URL.createObjectURL(audioBlob);
        setAudioSrc(audioURL);
        const storageRef = ref(storage, `messagesVoice/${new Date().toISOString()}.wav`);
  
      uploadBytes(storageRef, audioBlob)
        .then(() => getDownloadURL(storageRef))
        .then((downloadURL) => {
          console.log('Download URL:', downloadURL);
          setVoiceUrl(downloadURL);
          // Here, you can use the 'downloadURL' as needed, e.g., save it to your database
        })
        .catch((error) => {
          console.error(error);
        });
        console.log('audio url', audioURL);
         // Log the generated URL
      });
    
      recorder.start();
      setRecording(true);
    };
    
    const stopRecording = () => {
      mediaRecorder.stop();
      setRecording(false);
    };
    
    
    

  const modifiedHandleSendMessage = () => {
    handleSendMessage();
    setFileUrl(null);
    setImageUrl(null); // Call the original handleSendMessage function
    setIsClosed(true);
    setAudioSrc(null);
    };
  const attachFile = () => {
    setIsPopoverOpen(false); // Close the Popover when attaching a file
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf,.doc,.docx'; // Set accepted file types: PDF, DOC, DOCX 
  
    input.onchange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
      console.log('File attached:', file.name);
      setIsClosed(false);
      const storageRef = ref(storage, `messagesFiles/${file.name}`);
  
      uploadBytes(storageRef, file)
        .then(() => getDownloadURL(storageRef))
        .then((downloadURL) => {
          console.log('Download URL:', downloadURL);
          setFileUrl(downloadURL);
          // Here, you can use the 'downloadURL' as needed, e.g., save it to your database
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
    input.click();
    onClose();
  };
  const handleClose = async (fileName) => {
    setIsClosed(true);
    
    const uniqueIdentifier = fileName.split('-').pop(); // Extract the identifier from the file name
    const storageRef = ref(storage, `messagesFiles/${fileName}`);
  
    try {
      // Delete the file from Firebase Storage
      await deleteObject(storageRef);
      console.log('File deleted successfully!');
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  
    setSelectedFile(null);
  };
  const handleDeleteVoice = async (fileName) => {
    setIsClosed(true);
    
    const storageRef = ref(storage, `messagesVoice/${fileName}`);
  
    try {
      // Delete the file from Firebase Storage
      await deleteObject(storageRef);
      console.log('Voice deleted successfully!');
    } catch (error) {
      console.error('Error deleting voice:', error);
    }
  
    setAudioSrc(null);
  };
  const handleCloseImage = async (fileName) => {
   setIsClosed(true);
    
    const uniqueIdentifier = fileName.split('-').pop(); // Extract the identifier from the file name
    const storageRef = ref(storage, `messagesImages/${fileName}`);
  
    try {
      // Delete the file from Firebase Storage
      await deleteObject(storageRef);
      console.log('File deleted successfully!');
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  
    setSelectedImage(null); 
  };
  const attachImage = async () => {
    setIsPopoverOpen(false); // Close the Popover when attaching a file

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*'; // Set the accepted image types
    input.onchange = (event) => {
      const image = event.target.files[0];
  setSelectedImage(image);
  console.log('Image attached:', image);
  //setIsClosed(false);
  const storageRef = ref(storage, `messagesImages/${image.name}`);
  uploadBytes(storageRef, image)
  .then(() => getDownloadURL(storageRef))
  .then((downloadURL) => {
    console.log('Download URL:', downloadURL);
    setPreviewImage(downloadURL);
    setImageUrl(downloadURL);
    imageUrl = downloadURL;
  });
    };
    input.click();
    onClose();
  };
  
  return (
    <Flex flexDirection='column' w='100%'>

   {selectedFile &&
    <MessageBubble fileName={selectedFile.name} fileSize={selectedFile.size} Isclosed={Isclosed} handleClose={() => handleClose(selectedFile.name)} />}
   {selectedImage &&
    <ImageBubble fileName={selectedImage.name} fileSize={selectedImage.size} Isclosed={Isclosed} handleClose={() => handleCloseImage(selectedImage.name)} imageAvatar={previewImage}/>}
    <Flex w="100%" mt="18px">

      <Popover  placement="top" isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}>
        <PopoverTrigger>
          <IconButton
            aria-label="Toggle Additional Buttons"
            bg={colorMode === 'light' ? 'brand.300' : 'brand.500'}
            color="white"
            borderRadius="50%"
            icon={<FaPlus />}
            _hover={{
              bg: colorMode === 'light' ? 'brand.600' : 'brand.600',
            }}
            mr={2}
          />
        </PopoverTrigger>
        <PopoverContent
          css={css`
            width: 11rem;
            height: 7rem;
            max-height: 300px;
          `}
          bg={colorMode === 'light' ? 'gray.100' : 'navy.800'}
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Flex
              direction="column"
              align="flex-start"
              justifyContent="flex-start"
            >
              <Flex align="center">
                <IconButton
                  aria-label="Attach File"
                  bg={colorMode === 'light' ? 'brand.300' : 'brand.500'}
                  color="white"
                  borderRadius="50%"
                  icon={<FaPaperclip />}
                  mr="0.5rem"
                  _hover={{
                    bg: colorMode === 'light' ? 'brand.600' : 'brand.600',
                  }}
                  onClick={attachFile}
                />
                <Text>{t('pièce jointe')}</Text>
              </Flex>
              <Flex align="center" mt="1rem">
                <IconButton
                  aria-label="Attach Image"
                  bg={colorMode === 'light' ? 'brand.300' : 'brand.500'}
                  color="white"
                  borderRadius="50%"
                  icon={<FaImage />}
                  mr="0.5rem"
                  _hover={{
                    bg: colorMode === 'light' ? 'brand.600' : 'brand.600',
                  }}
                  onClick={attachImage }
                />
                <Text>{t('image')}</Text>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {/* <ReactMic
        record={recording}
        onStop={onStop}
        onData={onData}
        strokeColor={colorMode === "light" ? "#000000" : "#ffffff"}
        backgroundColor={colorMode === "light" ? "#ffffff" : "#000000"}
      /> */}

    
      {recording ? (
      <>
        <AudioVisualizer colorMode={colorMode} />
      
      </>
    ) : audioSrc !== null ?  (
          // Display audio player if there's a recorded audio
     
      <Flex width="100%" borderRadius= '20px'>  
      <ReactPlayer
        url={audioSrc}
        width="100%"
        height="40px"
        playing={false}
        controls={true}
        style={{
        borderRadius: '20px',
        backgroundColor:colorMode === 'light' ? '#422AFB' : '#F5F6FA',
        overflow: 'hidden',
      }}
        
      />
      <IconButton
        ml={2}
        aria-label="delete"
        bg={colorMode === 'light' ? 'white' : 'red.300'}
        color={colorMode === 'light' ? 'red.500' : 'white'}
        borderRadius="50%"
        icon={<FaTrashAlt />}
        _hover={{
          bg: colorMode === 'light' ? 'red.400' : 'red.200',
          color: colorMode === 'light' ? 'white' : 'white'
        }}
        disabled={inputMessage.trim().length <= 0}
        onClick={handleDeleteVoice}
      />
      </Flex> 
        ):
      // Display input components if there's no recording
      <InputGroup flex="1">
        <Input
          variant="filled"
          placeholder="Type Something..."
          borderRadius="30px"
          bg={colorMode === 'light' ? 'gray.100' : 'navy.700'}
          color={colorMode === 'light' ? 'navy.700' : 'white'}
          py={4}
          px={4}
          _focus={{
            borderColor: 'brand.500',
          }}
          onKeyDown={handleKeyPress}
          value={inputMessage}
          onChange={e => setInputMessage(e.target.value)}
        />
      </InputGroup>
    }
   {audioSrc === null &&     <IconButton
        ml={2}
        color={colorMode === 'light' ? 'brand.500' : 'white'}
        icon={recording ? <FaStop /> : <FaMicrophone />}
        borderRadius="50%"
        onClick={recording ? stopRecording : startRecording}
        _hover={{
          bg: colorMode === 'light' ? 'brand.600' : 'brand.600',
          color: colorMode === 'light' ? 'white' : 'white',
        }}
      />}
  

      <IconButton
        ml={2}
        aria-label="Send"
        bg={colorMode === 'light' ? 'brand.300' : 'brand.500'}
        color="white"
        borderRadius="50%"
        icon={<FaPaperPlane />}
        _hover={{
          bg: colorMode === 'light' ? 'brand.600' : 'brand.600',
        }}
        disabled={inputMessage.trim().length <= 0}
        onClick={modifiedHandleSendMessage}
      />
    </Flex></Flex>
  );
};

export default Footer;
