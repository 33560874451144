import {
    InputGroup,
    InputRightElement,
    Input,
    Stack,
    Badge,
    IconButton,
    Text,
    useColorModeValue,
    Flex,
    Box,
    ButtonGroup,
    Button,
    Checkbox,
    TableCaption,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Image,
    Tab,
    TabList,
    Tabs,
    Icon,
  } from '@chakra-ui/react';
  import axios from 'axios';
  import React, { useState, useEffect, useMemo } from 'react';
  import Project from 'views/admin/profile/components/Project';
  import AdsCard from '../../../../components/sidebar/components/SidebarCard';
  import Nft3 from 'assets/img/nfts/Nft3.png';
  import { useHistory } from 'react-router-dom';
  import Swal from 'sweetalert2';
  import { t } from 'helpers/TransWrapper';
  import Card from 'components/card/Card.js';
  import ReactPaginate from 'react-paginate';
  import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
  import { CheckIcon } from '@chakra-ui/icons';
  
  import {
    MdNotificationAdd,
    MdCalendarMonth,
    MdOutlineEdit,
    MdDeleteOutline,
    MdAnalytics,
    MdDone,
    MdBallot,
    MdSettings,
    MdSupervisorAccount 
  } from 'react-icons/md';
  import { SearchIcon } from '@chakra-ui/icons';
  import { ExternalLinkIcon, DownloadIcon } from '@chakra-ui/icons';
import CreateNotif from '../variables/CreateNotif';
import History from '../variables/History';
import AudienceSegmentation from '../variables/AudienceSegmentation';
import NotificationAnalytics from '../variables/NotificationAnalytics';

  export default function Notifications(props) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const cardShadow = useColorModeValue(
      '0px 18px 40px rgba(112, 144, 176, 0.12)',
      'unset'
    );
    const bgItem = useColorModeValue('white', 'navy.700');
    const [searchQuery, setSearchQuery] = useState('');
  
    const [orders, setOrders] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [filtredOrders, setFiltredOrders] = useState([]);
    const [selectedTab, setSelectedTab] = useState('addNotif'); // Default is 'all', can be changed to 'published', 'draft', or 'validation'
    const [selectAll, setSelectAll] = useState(false);
    const [pageSize, setPageSize] = useState(10); // Number of orders per page
  
    const userData = localStorage.getItem('user-token');
    const currentUser = JSON.parse(userData);
    const history = useHistory();
    const [selectedOrders, setSelectedOrders] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
 
    
    // useEffect(() => {
    //   if (selectedTab === 'all') {
    //     setFiltredOrders(orders);
    //   } else {
    //     const filtered = orders.filter(order => order.status === selectedTab);
    //     setFiltredOrders(filtered);
    //   }
    // }, [orders, selectedTab]);


  
    const handleStatusChange = status => {
      setSelectedTab(status);
    };
  
    
  
    return (
      <Card mb={{ base: '0px', '2xl': '20px' }}>
        {/* <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          {t(`Gestion des commandes`)}
        </Text> */}
  
        <Box>
          <Flex w="100%" justify="space-between">
            <Tabs colorScheme="purple" variant="enclosed" flex="1">
              <TabList mb={{ base: '4px', md: '0' }}>
                <Tab
                  fontSize="sm"
                  fontWeight={selectedTab === 'addNotif' ? 'bold' : 'normal'}
                  onClick={() => handleStatusChange('addNotif')}
                  isSelected={selectedTab === 'addNotif'}
                > 
                <MdNotificationAdd size={20} />
                <Text ml={1}>Créer une Notification</Text>
                  
                </Tab>
                <Tab
                  fontSize="sm"
                  fontWeight={selectedTab === 'history' ? 'bold' : 'normal'}
                  onClick={() => handleStatusChange('history')}
                  isSelected={selectedTab === 'history'}
                >
                <MdCalendarMonth size={20} />
                   <Text ml={1}>Historique des Notifications</Text>
                  
                </Tab>
                <Tab
                  fontSize="sm"
                  fontWeight={selectedTab === 'segments' ? 'bold' : 'normal'}
                  onClick={() => handleStatusChange('segments')}
                  isSelected={selectedTab === 'segments'}
                >
                <MdSupervisorAccount  size={20} />
                   <Text ml={1}>Segmentation de l'Audience</Text>
                  
                </Tab>
                <Tab
                  fontSize="sm"
                  fontWeight={selectedTab === 'analytics' ? 'bold' : 'normal'}
                  onClick={() => handleStatusChange('analytics')}
                  isSelected={selectedTab === 'analytics'}
                >
                 <MdAnalytics size={20} />
                   <Text ml={1}>Analyses</Text>
                  
                </Tab>
          
              </TabList>
            </Tabs>
       
          </Flex>
        </Box>
  
        {selectedTab === 'addNotif' && <CreateNotif />}
        {selectedTab === 'history' && <History />}
         {selectedTab === 'segments' && <AudienceSegmentation />}
      
        {selectedTab === 'analytics' && <NotificationAnalytics />}
        {/* {selectedTab === 'settings' && <Settings />}  */}

      </Card>
    );
  }
  