import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Input,
  Heading,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Badge
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import { t } from "helpers/TransWrapper";

import { useColorMode } from "@chakra-ui/react";
import { Skeleton } from '@chakra-ui/react';
import { useDispatch,useSelector } from 'react-redux';
import {setCategories} from '../../../state/categories/categoriesSlice'
import { setAdsCount } from "../../../state/adsCount/adsCountSlice";
// Custom components
import Shop from './components/Banner';
import banner from 'assets/img/shopBanner.png';

// Assets
import Nft4 from 'assets/img/nfts/Nft4.png';
import { useHistory } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
export default function Shops() {
  //const [categories, setCategories] = useState([]);
  
  const [shops, setShops] = useState([]);
  const [searchByName, setSearchByName] = useState('');
  const [searchByTag, setSearchByTag] = useState('');
    const [filteredShops, setFilteredShops] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const res = await axios.get(`${process.env.REACT_APP_API}/shops/shop/public/all`);
        const shopsData = res.data;
        console.log("shops",shops);

        // Fetch ads count for each shop
        const shopsWithAdsCount = await Promise.all(
          shopsData.map(async (shop) => {
            const adsResponse = await axios.get(
              `${process.env.REACT_APP_API}/shops/shop/${shop._id}/ads/count`
            );
            const adsCount = adsResponse.data.adsCount;
            const ownerResponse = await axios.get(
                `${process.env.REACT_APP_API}/users/${shop.owner}`
              );
              const ownerShop = ownerResponse.data;
            return {
              ...shop,
              adsCount,
              ownerShop, 
            };
          })
        );

        setShops(shopsWithAdsCount);
        setFilteredShops(shopsWithAdsCount);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    // Filter shops based on name and tag
    const filteredByName = shops.filter(shop =>
      shop.name.toLowerCase().includes(searchByName.toLowerCase())
    );

    const filteredByTag = shops.filter(shop =>
      shop.tags.some(tag =>
        tag.toLowerCase().includes(searchByTag.toLowerCase())
      )
    );

    const filteredShops = searchByName
      ? filteredByName
      : searchByTag
      ? filteredByTag
      : shops;

    setFilteredShops(filteredShops);
  }, [searchByName, searchByTag, shops]);

  const handleNameSearch = event => {
    setSearchByName(event.target.value);
  };

  const handleTagSearch = event => {
    setSearchByTag(event.target.value);
  };

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const { colorMode } = useColorMode();

  return (
    <>
        <Box pt={{ base: '100px', md: '80px', xl: '80px' }}>
      {/* Search Input */}
      <Card shadow="lg" mb={5} p={4}>
      <Flex  justifyContent="space-between" alignItems="center"  direction={{ base: 'column', md: 'row' }}>
        <Text p={1} mb={2} fontSize="20px">
        {t('Explorez une Galerie Unique de Boutiques Exquises')} <Badge ml={1}
                colorScheme="green"
                fontSize="xs"
                mr="2"
                borderRadius="md"
                px="2"
                py="1"
              >
                NEW
              </Badge>
                </Text>
        <Flex alignItems="center">
          <InputGroup  mr={4}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color="gray.300" />
            </InputLeftElement>
            <Input
            variant='auth'
              type="text"
              value={searchByName}
              onChange={handleNameSearch}
              placeholder={t("Recherche par nom...")}
            />
          </InputGroup>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color="gray.300" />
            </InputLeftElement>
            <Input
                        variant='auth'

              type="text"
              value={searchByTag}
              onChange={handleTagSearch}
              placeholder={t("Recherche par tag...")}
            />
          </InputGroup>
        </Flex>
      </Flex>
      </Card>
  
   {isLoading ? (
  <Box >
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      gap="20px"
      mb={{ base: '20px', xl: '0px' }}
    >
      {[...Array(9)].map((_, index) => (
        <Box key={index} borderRadius="lg" overflow="hidden" className='Box'>
          <Skeleton
            height={{ base: '120px', md: '200px' }}
            width="100%"
            startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
            endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
          />
          <Box p="6">
            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
              color={textColor}
            >
              <Skeleton
                height="10px"
                width={{ base: '50%', md: '30%' }}
                mb={2}
                startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
                endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
              />
            </Box>
            <Box d="flex" alignItems="baseline" color={textColorBrand} fontSize="sm">
              <Skeleton
                height="10px"
                width={{ base: '20%', md: '10%' }}
                mr={2}
                startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
                endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
              />
            </Box>
            <Box d="flex" alignItems="baseline" color={textColorBrand} fontSize="sm">
 
          </Box>
          <Button isLoading loadingText={t("Loading...")} />
        
          </Box>
          
        </Box>
      ))}
    </SimpleGrid>
  </Box>
) : (
  <Box >
    <SimpleGrid
      columns={{ base: 1, md: 2, xl: 3, "2xl": 4 }}
      gap="20px"
      mb={{ base: '20px', xl: '0px' }}
    >
      {filteredShops.map(shop => {
      
        const reviews = shop?.reviews || [];
  const positiveReviews = reviews.filter(review => review.rating >= 4);
  const totalReviews = reviews.length;
  const totalPositiveReviews = positiveReviews.length;

  // Calculate positive reviews percentage, considering the possibility of division by zero
  const positiveReviewsPercentage =
    totalReviews === 0 ? 'N/A' : ((totalPositiveReviews / totalReviews) * 100).toFixed(2);
        return (
            <Shop
          shopId={shop?._id}
          posts={shop?.adsCount}
          avatar={shop?.logo}
          name={shop?.name}
          userId={shop?.owner}
          banner={banner}
          memberShip={shop?.ownerShop.memberShip}
          positiveReviews={positiveReviewsPercentage}
          createdAt={shop?.createdAt}
        />
        );
      })}
    </SimpleGrid>
  </Box>
)}
</Box>
  </>
  );
}
