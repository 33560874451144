import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import '../NFT.css';
import moment from 'moment';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Divider,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  InputLeftElement,
  VStack,
  List,
  ListItem,
  ListIcon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Select,
  Grid,
} from '@chakra-ui/react';

// Custom components
import Banner from 'views/shop/components/Banner';
import ShopInfoCard from 'views/shop/components/ShopInfoCard';
import { useParams } from 'react-router-dom';
import NFT from 'components/card/NFT';
import Card from 'components/card/Card.js';
import { SmallCloseIcon } from '@chakra-ui/icons';
// Custom components
import { FaCheckCircle } from 'react-icons/fa';
import CountryList from 'country-list-with-dial-code-and-flag';
import { ToastContainer, toast } from 'react-toastify';

import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
// Assets
import HistoryItem from 'views/ads/recentAds/components/HistoryItem';
import {
  MdLanguage,
  MdPhone,
  MdEmail,
  MdSearch,
  MdPerson,
} from 'react-icons/md';
import { t } from "helpers/TransWrapper";
import Nft3 from 'assets/img/nfts/Nft3.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';

import { useColorMode } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
import { createSelector } from 'reselect';
import { MdWorkspacePremium, MdStars } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { addAds } from '../../../state/auth/authSlice';
import banner from 'assets/img/shopBanner.png';
import { SearchIcon } from '@chakra-ui/icons';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase';
export default function UpdateShop() {
  // Chakra Color Mode
  const { name } = useParams();

  const [owner, setOwner] = useState(null);
  const [shopAds, setShopAds] = useState(null);
  const dispatch = useDispatch();
  const TestAds = state => state.auth.Test;
  // const TestAds = useSelector((state) => state.Test);
  const [likers, setLikers] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const [likedAds, setLikedAds] = useState([]);
  const premium = useColorModeValue('#6f432a', 'gold');
  const liked = useColorModeValue('#960018', 'gold');
  const [adsCount, setAdsCount] = useState();
  const [adData, setAdData] = useState([]);
  const [like, setLike] = useState();
  const [SelectedAd, setSelectedAd] = useState([]);
  const history = useHistory();
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [isLoading, setIsLoading] = useState(false);
  const NFTMemoized = React.memo(NFT);
  const [user, setUser] = useState({});
  const [promotedAds, setPromotedAds] = useState([]);
  const [shop, setShop] = useState({
    owner: '',
    logo: '',
    name: '',
    description: '',
    rc: '',
    admins: [],
    tags: [],
    contactInfo: {
      phone: '',
      email: '',
      socialMedia: {
        facebook: '',
        instagram: '',
        twitter: '',
      },
    },
    address: '',
    paymentMethods: [],
    ads: [],
    delivery: false,
  });
  function revertFormattedName(originalName) {
    // Replace dashes with spaces
    const revertedName = originalName.replace(/-/g, ' ');

    return revertedName;
  }

  const shopName = revertFormattedName(name);
  const processedTestAds = createSelector([TestAds], testAds => {
    return testAds;
  });
  const selectProcessedTestAds = useSelector(processedTestAds);
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  const [tags, setTags] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [shopCreated, setShopCreated] = useState(false);
  const updateShopState = fetchedShopData => {
    setShop(prevShop => ({
      ...prevShop,

      _id: fetchedShopData._id ?? prevShop._id,
      owner: fetchedShopData.owner ?? prevShop.owner,
      logo: fetchedShopData.logo ?? prevShop.logo,
      name: fetchedShopData.name ?? prevShop.name,
      description: fetchedShopData.description ?? prevShop.description,
      rc: fetchedShopData.rc ?? prevShop.rc,
      admins: fetchedShopData.admins ?? prevShop.admins,
      tags: fetchedShopData.tags ?? prevShop.tags,
      contactInfo: {
        phone: fetchedShopData.contactInfo?.phone ?? prevShop.contactInfo.phone,
        email: fetchedShopData.contactInfo?.email ?? prevShop.contactInfo.email,
        socialMedia: {
          facebook:
            fetchedShopData.contactInfo?.socialMedia?.facebook ??
            prevShop.contactInfo.socialMedia.facebook,
          instagram:
            fetchedShopData.contactInfo?.socialMedia?.instagram ??
            prevShop.contactInfo.socialMedia.instagram,
          twitter:
            fetchedShopData.contactInfo?.socialMedia?.twitter ??
            prevShop.contactInfo.socialMedia.twitter,
        },
      },
      address: fetchedShopData.address ?? prevShop.address,
      paymentMethods: fetchedShopData.paymentMethods ?? prevShop.paymentMethods,
      delivery: fetchedShopData.delivery ?? prevShop.delivery,
      ads: fetchedShopData.ads ?? prevShop.ads,
    }));
  };
  const fetchShopByName = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/name/${name}`
      );
      const fetchedShopData = response.data;
      updateShopState(fetchedShopData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchShopByName();
  }, [name]); // Assuming 'name' is a dependency that triggers fetching shop data

  const fetchAdsForShop = async shop => {
    try {
      const validAdIds = shop.ads.filter(adId => adId);
      const adPromises = validAdIds.map(async adId => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/ads/ad/${adId}`
          );
          return response.data;
        } catch (error) {
          console.error(`Error fetching ad with ID ${adId}:`, error);
          // Handle specific error for this ad ID fetch failure if needed
          return null; // Return null for the failed fetch
        }
      });

      const ads = await Promise.all(adPromises);
      // 'ads' is an array containing all the fetched ad data for the shop
      const filteredAds = ads.filter(ad => ad !== null);
      const filteredValidAds = filteredAds.filter(
        ad => ad.status === 'Validée'
      );
      setShopAds(filteredValidAds);
      console.log('ads', filteredAds);
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  useEffect(() => {
    if (shop) {
      fetchAdsForShop(shop);
    }
  }, [shop]);
  useEffect(() => {
    const fetchOwner = async () => {
      try {
        if (shop && shop.owner) {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/users/${shop.owner}`
          );
          const owner = response.data;
          setOwner(owner);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOwner();
  }, [shop]);
  console.log('ads', shopAds);
  const countAds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/count/ads`
      );
      setAdsCount(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleTagsChange = e => {
    const tagsFromInput = e.target.value.split(','); // Split the input value by commas
    setShop(prevShop => ({
      ...prevShop,
      tags: tagsFromInput.map(tag => tag.trim()), // Update the tags array with trimmed tags
    }));
  };
  const [selectedCountryCode, setSelectedCountryCode] = useState('+212');

  const formatPhoneNumber = number => {
    // Remove leading 0
    const formattedNumber = number.replace(/^0+/, '');

    // Add country code
    return selectedCountryCode + formattedNumber;
  };

  const [file, setFile] = useState();

  const removeTag = indexToRemove => {
    const updatedTags = shop.tags.filter((_, index) => index !== indexToRemove);
    setShop(prevShop => ({
      ...prevShop,
      tags: updatedTags,
    }));
  };
  const onFileChange = event => {
    setShop({
      ...shop, //spread operator
      logo: event.target.files[0],
    });

    setFile(URL.createObjectURL(event.target.files[0]));
  };
  const DeleteImg = event => {
    // Update the state
    setFile();
    setShop({
      ...shop, //spread operator
      logo: '',
    });
  };

  const handleDeliveryChange = e => {
    setShop({
      ...shop,
      delivery: e.target.checked,
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    console.log('Name:', name);
    console.log('Value:', value);
    if (name === 'phone') {
      const formattedPhoneNumber = formatPhoneNumber(value);

      setShop({
        ...shop,
        contactInfo: {
          ...shop.contactInfo,
          phone: formattedPhoneNumber,
        },
      });
    } else if (name === 'email') {
      setShop({
        ...shop,
        contactInfo: {
          ...shop.contactInfo,
          email: value,
        },
      });
    } else {
      setShop({
        ...shop,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    console.log('shop', shop);
  }, [shop]);
  const handleCheckboxChange = tagsSelectionnes => {
    setShop({
      ...shop,
      paymentMethods: tagsSelectionnes,
    });
  };

  const handleSocialMediaChange = (plateforme, valeur) => {
    setShop({
      ...shop,
      contactInfo: {
        ...shop.contactInfo,
        socialMedia: {
          ...shop.contactInfo.socialMedia,
          [plateforme]: valeur,
        },
      },
    });
  };
  const countries = CountryList.getAll();
  const countryCodes = countries.map(country => ({
    value: country.dial_code,
    label: country.code + ' ' + country.dial_code,
  }));
  const [searchText, setSearchText] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);

  const formatShopNameForURL = shopName => {
    // Replace spaces with hyphens and convert to lowercase
    return shopName.replace(/\s+/g, '-').toLowerCase();
  };
  const removeUser = userId => {
    const updatedUsers = selectedUsers.filter(shop => shop.id !== userId);
    setSelectedUsers(updatedUsers);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      if (
        !shop.name ||
        !shop.description ||
        !shop.contactInfo.phone ||
        !shop.contactInfo.email ||
        !shop.address ||
        !shop.paymentMethods ||
        (shop.tags && shop.tags.length === 0)
      ) {
        // Empty fields messages
        const errorMessages = {
          name: t('Veuillez entrer le nom du magasin'),
          description: t('Veuillez ajouter une description'),

          address: t('Veuillez entrer une adresse'),
          paymentMethods:
            t('Veuillez sélectionner au moins une méthode de paiement'),
          tags: t('Veuillez ajouter des tags'),
        };

        // Check each field and trigger specific toast messages
        Object.entries(errorMessages).forEach(([field, message]) => {
          if (!shop[field]) {
            toast.error(message, {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        });

        // Prevent submission if any required fields are empty
      }

      const shopData = {
        owner: shop.owner,
        logo: '',
        name: shop.name,
        description: shop.description,
        rc: shop.rc,
        contactInfo: {
          phone: shop.contactInfo.phone,
          email: shop.contactInfo.email,
          socialMedia: {
            facebook: shop.contactInfo.socialMedia.facebook,
            instagram: shop.contactInfo.socialMedia.instagram,
            twitter: shop.contactInfo.socialMedia.twitter,
          },
        },
        address: shop.address,
        paymentMethods: shop.paymentMethods,
        delivery: shop.delivery,
        tags: tags,
      };

      // Upload the file to storage
      const storageRef = ref(
        storage,
        `ShopsLogos/${shop.name}/${shop.logo.name}`
      );
      await uploadBytes(storageRef, shop.logo);
      const downloadURL = await getDownloadURL(storageRef);

      // Append the logo downloadURL to shop data
      shopData.logo = downloadURL;

      console.log('shop submitted', shopData);
      const response = await axios.patch(
        `https://jindoo.ma:6001/shops/shop/${shop._id}`,
        shopData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      ); // Adjust the API endpoint

      if (response.data) {
        // Shop created successfully
        toast.success(t('Shop updated successfully'), {
          // Toast success message
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        const user = await axios.patch(
          `https://jindoo.ma:6001/users/update/${currentUser._id}`,
          {
            idShop: response.data._id, // Assuming your response structure includes the created shop's ID
            shop: response.data.name,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const idShop = user.data.idShop;
        const shop = user.data.shop;
        currentUser.idShop = idShop;
        currentUser.shop = shop;
        localStorage.setItem('user-token', JSON.stringify(currentUser));
        // Redirect to shop page after shop creation
        setShopCreated(true);
      } else {
        // Error creating shop
        toast.error(t('Failed to update shop'), {
          // Toast error message
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      // Handle any unexpected errors during shop creation
      console.error('Error updating shop:', error);
      toast.error(t('An error occurred while updating the shop'), {
        // Toast error message
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  console.log(JSON.stringify(adData)); // JSON.stringify
  const getLikers = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${id}/like`
      );

      const likerIds = response.data;

      const likerData = [];

      for (const likerId of likerIds) {
        const userDataResponse = await axios.get(
          `${process.env.REACT_APP_API}/users/${likerId}`
        );
        const userData = userDataResponse.data;
        likerData.push(userData);

        console.log('User Data:', userData); // Log the user data for debugging
      }

      const profilePictures = likerData.map(liker => liker.profilePicture);

      console.log('Profile Pictures:', profilePictures); // Log profile pictures for debugging

      return profilePictures;
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  useEffect(() => {
    const likedAds = async () => {
      try {
        // Fetch ads and user data in a single request
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/home/likedAds`
        );
        const likedAds = response.data;

        setLikedAds(likedAds);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    likedAds();
  }, []);

  const PromotedAdsWrapper = ({ ad, user }) => {
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <HistoryItem
        id={ad._id}
        name={ad.name}
        click={handleClick}
        author={user.firstName + ' ' + user.lastName}
        date={moment(ad.createdAt).fromNow()}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        price={ad.price === 0 ? t('Non défini') : ad.price + t(` MAD`)}
      />
    );
  };
  const NFTWrapper = ({ ad }) => {
    const [likersForAd, setLikersForAd] = useState([]);

    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }

      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <NFT
        id={ad._id}
        bidders={likersForAd}
        name={ad.name}
        author={`${user.firstName} ${user.lastName}`}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        category={ad.categoryLabel}
        propart={ad?.ProPart}
        currentbid={ad.price === 0 ? t('Non défini') : ad.price + t(` MAD`)}
        Click={handleClick}
        city={ad.city}
        dateCreated={moment(ad.createdAt).fromNow()}
        nbrPictures={ad.adPictures.length + t(` images `)}
      />
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectProcessedTestAds.length < 1) {
        setIsLoading(true);

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/ads/Adsm/home`
          );
          const ads = response.data;

          const adData = await Promise.all(
            ads.map(async ad => {
              const userResponse = await axios.get(
                `${process.env.REACT_APP_API}/users/${ad.userId}`
              );
              const user = userResponse.data;
              return user ? { ad, user } : null;
            })
          );

          const filteredAdData = adData.filter(Boolean);
          dispatch(addAds(filteredAdData));
          setAdData(filteredAdData);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [TestAds.length]);

  useEffect(() => {
    countAds();
  }, []);

  const getAdById = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/ad/${id}`
      );
      setSelectedAd(response.data);

      history.push(`/ads/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const items = Array.from({ length: 12 });

  const { colorMode } = useColorMode();

  const bgColor = colorMode === 'dark' ? 'rgb(17, 28, 68)' : 'gray.200';
  const reviews = shop?.reviews || [];
  const positiveReviews = reviews.filter(review => review.rating >= 4);
  const totalReviews = reviews.length;
  const totalPositiveReviews = positiveReviews.length;
  const [searchTerm, setSearchTerm] = useState('');

  // Assuming shopAds is your array of ads

  // Function to filter ads based on search term
  const filteredAds = shopAds?.filter(ad =>
    ad.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Calculate positive reviews percentage, considering the possibility of division by zero
  const positiveReviewsPercentage =
    totalReviews === 0 ? 'N/A' : (totalPositiveReviews / totalReviews) * 100;
  console.log('ad', adData);
  return (
    <Box pt={{ base: '100px', md: '80px', xl: '90px' }}>
      {' '}
      {/* Main Fields */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
        gap={{ base: '20px', xl: '20px' }}
        display={{ base: 'block', xl: 'grid' }}
      >
        <Banner
          shopId={shop?._id}
          posts={shopAds?.length}
          avatar={shop?.logo}
          name={shop?.name}
          userId={shop?.owner}
          banner={banner}
          memberShip={owner?.memberShip}
          positiveReviews={positiveReviewsPercentage}
        />
        <ShopInfoCard
          description={shop?.description}
          phone={shop?.contactInfo.phone}
          email={shop?.contactInfo.email}
          address={shop?.address}
          delivers={shop?.delivery}
          facebook={shop?.contactInfo.socialMedia.facebook}
          instagram={shop?.contactInfo.socialMedia.instagram}
          twitter={shop?.contactInfo.socialMedia.twitter}
          createdAt={shop?.createdAt}
          tags={shop?.tags}
          paymentMethods={shop?.paymentMethods}
        />
        <Card
          shadow="lg"
          flexDirection="column"
          gridArea={{ xl: '4 / 3 / 1 / 1', '2xl': '1 / 1 / 2 / 2' }}
        >
          {!shopCreated ? (
            <FormControl>
              {/* Section Identification */}
              <FormLabel>{t('Identification')}</FormLabel>
              <SimpleGrid columns={2} gap={4}>
                <Stack direction={['column', 'row']} spacing={6} mb="20px">
                  <Center>
                    <Avatar size="lg" bg="#11047A" src={shop.logo}>
                      <AvatarBadge
                        as={IconButton}
                        size="sm"
                        rounded="full"
                        top="-10px"
                        colorScheme="red"
                        aria-label={t("Remove Image")}
                        icon={<SmallCloseIcon onClick={DeleteImg} />}
                      />
                    </Avatar>
                  </Center>

                  <Center w="full">
                    <Input
                      size="lg"
                      color={textColor}
                      variant="auth"
                      name="logo"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      pt="10px"
                      mb="24px"
                      fontWeight="500"
                      type="file"
                      onChange={onFileChange}
                    />
                  </Center>
                </Stack>

                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<MdPerson />}
                    mt={1}
                  />
                  <Input
                    variant="auth"
                    value={shop.name}
                    size="lg"
                    color={textColor}
                    name="name"
                    placeholder={t("Nom du magasin")}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                <Textarea
                  value={shop.description}
                  name="description"
                  placeholder={t("Description")}
                  onChange={handleInputChange}
                />
                <Input
                  value={shop.rc}
                  variant="auth"
                  size="lg"
                  color={textColor}
                  name="rc"
                  placeholder={t("Registre de commerce (RC)")}
                  onChange={handleInputChange}
                />
              </SimpleGrid>
              <Divider my={4} />

              {/* Section Infos de contact */}
              <FormLabel>{t('Infos de contact')}</FormLabel>
              <SimpleGrid columns={2} gap={4}>
                {/* Telephone */}
                <Stack spacing={4}>
                  <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<MdPhone />}
                      ml={105}
                      mt={1}
                    />
                    <Select
                      mr={1}
                      size="lg"
                      fontSize="sm"
                      w="150px"
                      variant="auth"
                      value={selectedCountryCode}
                      onChange={e => setSelectedCountryCode(e.target.value)}
                    >
                      {countryCodes.map(country => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </Select>

                    <Input
                      value={shop.contactInfo.phone}
                      variant="auth"
                      size="lg"
                      color={textColor}
                      name="phone"
                      placeholder={t("Téléphone")}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Stack>

                {/* Email */}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<MdEmail />}
                    mt={1}
                  />
                  <Input
                    value={shop.contactInfo.email}
                    variant="auth"
                    size="lg"
                    color={textColor}
                    name="email"
                    placeholder={t("Email")}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {/* ... Add more contact fields with icons */}
              </SimpleGrid>
              <Divider my={4} />

              {/* Section Adresse */}
              <FormLabel>{t('Adresse')}</FormLabel>
              <Textarea
                value={shop.address}
                name="address"
                placeholder={t("Adresse")}
                onChange={handleInputChange}
              />
              {/* Ajoutez plus de champs d'adresse si nécessaire */}
              <Divider my={4} />

              {/* Section Horaires d'ouverture */}

              <Divider my={4} />

              {/* Section Réseaux sociaux */}
              <FormLabel>{t('Réseaux sociaux')}</FormLabel>
              <SimpleGrid columns={2} gap={4}>
                {/* Facebook */}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaFacebookF />}
                    mt={1}
                  />{' '}
                  {/* Facebook Icon */}
                  <Input
                    value={shop.contactInfo.socialMedia.facebook}
                    variant="auth"
                    size="lg"
                    color={textColor}
                    name="facebook"
                    placeholder={t("Facebook")}
                    onChange={e =>
                      handleSocialMediaChange('facebook', e.target.value)
                    }
                  />
                </InputGroup>

                {/* Instagram */}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaInstagram />}
                    mt={1}
                  />{' '}
                  {/* Instagram Icon */}
                  <Input
                    value={shop.contactInfo.socialMedia.instagram}
                    variant="auth"
                    size="lg"
                    color={textColor}
                    name="instagram"
                    placeholder={t("Instagram")}
                    onChange={e =>
                      handleSocialMediaChange('instagram', e.target.value)
                    }
                  />
                </InputGroup>

                {/* Twitter */}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaTwitter />}
                    mt={1}
                  />{' '}
                  {/* Twitter Icon */}
                  <Input
                    value={shop.contactInfo.socialMedia.twitter}
                    variant="auth"
                    size="lg"
                    color={textColor}
                    name="twitter"
                    placeholder={t("Twitter")}
                    onChange={e =>
                      handleSocialMediaChange('twitter', e.target.value)
                    }
                  />
                </InputGroup>
                {/* ... Add more social fields with icons */}
              </SimpleGrid>
              <Divider my={4} />

              {/* Section Tags */}
              <FormLabel>{t('Tags')}</FormLabel>
              <Input
                value={shop.tags.join(',')} // Join the tags array into a string for the input value
                variant="auth"
                size="lg"
                color={textColor}
                name="tags"
                placeholder={t("Ajouter des tags (séparés par des virgules)")}
                onChange={handleTagsChange}
              />
              <Wrap spacing={2} mt={2}>
                {shop.tags.map(
                  (
                    tag,
                    index // Iterate over shop.tags instead of 'tags'
                  ) => (
                    <WrapItem key={index}>
                      <Tag
                        size="md"
                        borderRadius="full"
                        variant="solid"
                        colorScheme="blue"
                      >
                        <TagLabel>{tag}</TagLabel>
                        <TagCloseButton onClick={() => removeTag(index)} />
                      </Tag>
                    </WrapItem>
                  )
                )}
              </Wrap>

              <Divider my={4} />
              {/* Section Admins */}
              {/* <FormLabel>Admins</FormLabel> */}

              {/* Display matching users as a menu */}
              {/* <Select
        isMulti
        cacheOptions
        onInputChange={handleInputChangeSelect}
        options={filteredOptions}
        onChange={handleUserSelect}
        value={selectedUsers}
        placeholder="Search users by email"
      /> */}

              {/* Display selected users */}

              {/* Section Payment Methods */}
              {/* Section Payment Methods */}
              {/* Section Payment Methods */}
              {/* Section Payment Methods and Delivery */}
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={5}>
                <VStack align="start" spacing={4}>
                  <FormLabel>{t('Methodes de payement')}</FormLabel>
                  <CheckboxGroup
                    onChange={checkedValues =>
                      handleCheckboxChange(checkedValues)
                    }
                    value={shop.paymentMethods}
                  >
                    <VStack align="start" spacing={2}>
                      <Checkbox value="Paypal">{t('Paypal')}</Checkbox>
                      <Checkbox value="Carte bancaire">{t('Carte bancaire')}</Checkbox>
                      <Checkbox value="Cash a la livraison">
                        {t('Cash à la livraison')}
                      </Checkbox>
                      {/* Add more payment methods as needed */}
                    </VStack>
                  </CheckboxGroup>
                </VStack>

                {/* Divider for separation */}

                {/* Section Delivery */}
                <VStack align="start" spacing={4}>
                  <FormLabel>{t('Livraison')}</FormLabel>
                  <Checkbox
                    isChecked={shop.delivery}
                    name="delivery"
                    colorScheme="brand"
                    onChange={handleDeliveryChange}
                  >
                    {t('Proposez-vous la livraison ?')}
                  </Checkbox>
                </VStack>
              </SimpleGrid>
              {/* Boutons de soumission */}
              <Button
                onClick={handleSubmit}
                //mt={5}
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
              >
                {t('Modifier ma boutique')}
              </Button>
            </FormControl>
          ) : (
            <VStack spacing={8} align="center">
              <FaCheckCircle size={50} color="green" />
              <Heading as="h2" size="xl">
                {t('Boutique modifiée !')}
              </Heading>
              <Text fontSize="lg">{t(`Merci d'avoir modifié votre boutique.`)}</Text>
              <Text fontSize="md">
                {t('Vous pouvez maintenant profiter de votre boutique en ligne.')}
              </Text>
              <Button
                colorScheme="brand"
                size="lg"
                onClick={() => {
                  history.push(
                    `/shop/shop/${formatShopNameForURL(currentUser.shop)}`
                  );
                }}
              >
                {t('Afficher ma boutique')}
              </Button>
            </VStack>
          )}
        </Card>{' '}
      </Grid>{' '}
    </Box>
  );
}
