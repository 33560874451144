import {
    Flex,
    Input,
    Table,
    Progress,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Box,
    FormLabel,
    Select,
    Button,
    Switch,
    Grid
  } from '@chakra-ui/react';
  import React, { useMemo, useState, useEffect, useCallback } from 'react';
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from 'react-table';
  
  // Custom components
  import Card from 'components/card/Card';
  import Menu from 'components/menu/MainMenu';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  // Assets
  import { MdCheckCircle, MdCancel, MdOutlineError } from 'react-icons/md';
  import axios from 'axios';
  const PriceInput = React.memo(({ day, dayPrice, setDayPrice }) => {
    const handlePriceChange = e => {
      const price =
        e.target.value !== '' ? parseFloat(e.target.value) : undefined;
      setDayPrice(day, price);
    };
  
    return (
      <Flex >
      <Box borderWidth="1px" borderRadius="15px" p="4" shadow="md">
      <FormLabel>Période de {day} jours </FormLabel>
      <Input
        type="number"
        variant='filled'
        value={dayPrice || ''}
        onChange={handlePriceChange}
        placeholder={`Prix pour une période de ${day} jours`}
      />
    </Box>
    </Flex>
    );
  });
  export default function SubsData() {
    const [categories, setCategories] = useState([]);
  
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
  
    const [name, setName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');
    const [promotionDays, setPromotionDays] = useState(); // Initialize with default value
    const [repostsDays, setRepostsDays] = useState(); // Initialize with default value
    const [price, setPrice] = useState(); // Initialize with default value
    const [promotionChecked, setPromotionChecked] = useState(false);
    const [renouvellementChecked, setRenouvellementChecked] = useState(false);
    const [dayPrices, setDayPrices] = useState({}); // State to hold prices for each day
    const [days, setDays] = useState([]); // State to hold prices for each day
  
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [initialRender, setInitialRender] = useState(true);
    const HandleChange = (e) => {
      setName(e.target.value);
      const selectedValue = e.target.value;
  
      if (selectedValue === 'BRONZE') {
        setRenouvellementChecked(true);
        setPromotionChecked(false);
        setDays([1, 3, 7, 14, 30]); // Set days for BRONZE
      } else if (selectedValue === 'SILVER') {
        setPromotionChecked(true);
        setRenouvellementChecked(false);
        setDays([1, 3, 7, 14, 30]); // Set days for SILVER
      } else if (selectedValue === 'GOLD') {
        setPromotionChecked(true);
        setRenouvellementChecked(true);
        setDays([1, 3, 7, 14, 30]); // Set days for GOLD
      }
    };
    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_API}/categories`)
        .then(res => setCategories(res.data))
        .catch(err => console.error(err));
    }, []);
  
    useEffect(() => {
      // After the initial render, setInitialRender will be false
      setInitialRender(false);
    }, []);
    
    useEffect(() => {
      let repostsData = [];
      let daysData = [];
  
      if (renouvellementChecked) {
        repostsData = [1, 3, 7, 14, 30];
      } else {
        repostsData = [];
      }
  
      if (promotionChecked) {
        daysData = [1, 3, 7, 14, 30];
        setDays(daysData);
      } else {
        daysData = [];
      }
  
      const data = {
        name: name,
        reposts: repostsData,
        days: daysData,
        price: dayPrices,
        idCategory: selectedCategoryId
      };
  
      console.log('selectedCategoryId', selectedCategoryId);
      console.log(data);
  
      // Additional logic or side effects based on the updated data can be handled here
  
    }, [renouvellementChecked, promotionChecked, name, price, selectedCategoryId]);
  
    const handleSetDayPrice = useCallback((day, price) => {
      setDayPrices((prevPrices) => ({
        ...prevPrices,
        [day]: price !== '' ? parseFloat(price) : undefined,
      }));
    }, []);
  
  
  
    const handlePromotionChange = () => {
      setPromotionChecked(!promotionChecked);
    };
  
    const handleRenouvellementChange = () => {
      setRenouvellementChecked(!renouvellementChecked);
    };
  
    const handleSubmit = async (e) => {
      const configuration = {
        method: 'post',
        url: `${process.env.REACT_APP_API}/subscriptions/create`,
        data: {
          name: name,
          reposts: renouvellementChecked ? [1, 3, 7, 14, 30] : 0,
          days: promotionChecked ? [1, 3, 7, 14, 30] : 0,
          price: dayPrices,
          idCategory: selectedCategoryId
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      e.preventDefault();
      try {
        await axios(configuration);
        toast('Plan ajouté avec succes!', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } catch (error) {
        console.error(error);
        toast.error('Une erreur est survenue lors de l\'ajout du plan.', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    };
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    return (
      <>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Flex px="25px" justify="space-between" mb="10px" align="center" py={5}>
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Configuration des plans d'annonce
            </Text>
          </Flex>
          <Flex px="20px" justify="space-between" mb="1px" align="center">
            <Box w="100%" m={1}>
              <FormLabel fontSize="sm" fontWeight="500" mb="1">
                Catégorie du plan
              </FormLabel>
              <Select
                w="100%"
                m={1}
                id="category"
                name="categoryName"
                isRequired={true}
                fontSize="sm"
                mb="24px"
                size="lg"
                variant="auth"
                onChange={e => {
                  const selectedCategoryId =
                    e.target.options[e.target.selectedIndex].dataset.id;
                  const selectedCategory = categories.find(
                    category => category._id === selectedCategoryId
                  );
                  setSelectedCategory(selectedCategory.name);
                  setSelectedCategoryId(selectedCategoryId);
                  setSelectedCategoryLabel(selectedCategory.label);
                }}
                placeholder="Choisir une categorie"
              >
                {categories.map(category => (
                  <option
                    key={category._id}
                    value={category.name}
                    name={category.label}
                    data-id={category._id}
                  >
                    {category.label}
                  </option>
                ))}
              </Select>
            </Box>
  
            <Flex ml={1} w="100%" justify="space-around">
              <FormLabel fontSize="md" fontWeight="700" htmlFor="Promotion">
                Promotion
              </FormLabel>
              <Switch
                colorScheme="brand"
                size="lg"
                onChange={handlePromotionChange}
                isChecked={promotionChecked}
                isDisabled={true}
              />
              <FormLabel fontSize="md" fontWeight="700" htmlFor="Renouvellement">
                Renouvellement
              </FormLabel>
              <Switch
                colorScheme="brand"
                size="lg"
                onChange={handleRenouvellementChange}
                isChecked={renouvellementChecked}
                isDisabled={true}
              />
            </Flex>
          </Flex>
          <Flex px="20px" justify="space-between" mb="1px" align="center">
            <Box w="100%" m={1}>
              <FormLabel fontSize="sm" fontWeight="500" mb="1">
                Nom du plan
              </FormLabel>
              <Select
                m={1}
                id="name"
                name="name"
                isRequired={true}
                fontSize="sm"
                mb="24px"
                size="lg"
                variant="auth"
                onChange={(e) => HandleChange(e)}
                placeholder="Choisir un nom pour le plan"
              >
                <option value="BRONZE">BRONZE</option>
                <option value="SILVER">SILVER</option>
                <option value="GOLD">GOLD</option>
              </Select>
            </Box>
  
  
          </Flex>
          {(renouvellementChecked || promotionChecked) && days.length > 0 &&(
        <Grid templateColumns="repeat(5, 1fr)" gap={2} mb={5}>
          {days.map((day) => (
            <PriceInput
              key={day}
              day={day}
              dayPrice={dayPrices[day]}
              setDayPrice={handleSetDayPrice}
            />
          ))}
        </Grid>
      )} 
  
          <Flex px="20px" justify="space-between" mb="1px" align="center">
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleSubmit}
            >
              Ajouter{' '}
            </Button>{' '}
          </Flex>
        </Card>
      </>
    );
  }
  