import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FiChevronDown } from 'react-icons/fi';
import MultiRangeSlider from 'multi-range-slider-react';
import MultiselectCheckboxes from 'react-multiselect-checkboxes';
import LoadingScreen from './LoadingScreen';
import './Button.css';
import { IoMdPricetag } from 'react-icons/io';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  HStack,
  Icon,
  Wrap,
  WrapItem,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  Select,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  VStack,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Grid,
  InputLeftElement,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  InputLeftAddon,
  Tooltip,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useColorMode } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
// Custom components
import Banner from 'views/admin/marketplace/components/Banner';
import TableTopCreators from 'views/admin/marketplace/components/TableTopCreators';
import HistoryItem from 'views/admin/marketplace/components/HistoryItem';
import NFT from 'components/card/NFT';
import Card from 'components/card/Card.js';
import SearchableSelectWithSearchInput from 'components/searchableSelect/SearchableSelect.js';
import moment from 'moment';

// Assets
import Nft1 from 'assets/img/nfts/Nft1.png';
import Nft2 from 'assets/img/nfts/Nft2.png';
import Nft3 from 'assets/img/nfts/Nft3.png';
import Nft4 from 'assets/img/nfts/Nft4.png';
import Nft5 from 'assets/img/nfts/Nft5.png';
import Nft6 from 'assets/img/nfts/Nft6.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import { AiOutlineIdcard, AiOutlineOrderedList } from 'react-icons/ai';
import { MdSearch, MdCategory, MdLocationPin } from 'react-icons/md';
import { FaDollarSign } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import tableDataTopCreators from 'views/admin/marketplace/variables/tableDataTopCreators.json';
import { tableColumnsTopCreators } from 'views/admin/marketplace/variables/tableColumnsTopCreators';
import AdAsList from 'views/ads/recentAds/components/AdAsList';
import { SearchIcon, RepeatIcon } from '@chakra-ui/icons';
import { t } from 'helpers/TransWrapper';
import LazyLoad from 'react-lazyload';
import { AiFillAppstore, AiFillDatabase } from 'react-icons/ai';

export default function RecentAds() {
  // Chakra Color Mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isChecked, setIsChecked] = useState(false);

  const searchText = searchParams.get('text');
  const searchSubcategory = searchParams.get('subcategory');
  const searchSecteur = searchParams.get('secteur');
  const searchCategory = searchParams.get('category');
  const searchCity = searchParams.getAll('cities');
  const searchRegion = searchParams.get('region');
  const searchMaxPrice = searchParams.get('maxPrice');
  const searchMinPrice = searchParams.get('minPrice');

  const [range, setRange] = useState([0, 100000]);
  const [repeat, setRepeat] = useState('repeat(1, 1fr)');
  const [maxPrice, setMaxPrice] = useState();
  const [minPrice, setMinPrice] = useState();
  // Custom Fields
  const customFields = [];
  searchParams.forEach((value, key) => {
    if (key.startsWith('customFields')) {
      const fieldId = searchParams.get(`${key}[field_id]`);
      const fieldName = searchParams.get(`${key}[field_name]`);
      const fieldValue = searchParams.get(`${key}[value]`);
      customFields.push({
        field_id: fieldId,
        field_name: fieldName,
        value: fieldValue,
      });
    }
  });
  const subCustomFields = [];
  searchParams.forEach((value, key) => {
    if (key.startsWith('customFields')) {
      const fieldId = searchParams.get(`${key}[field_id]`);
      const fieldName = searchParams.get(`${key}[field_name]`);
      const fieldValue = searchParams.get(`${key}[value]`);
      subCustomFields.push({
        field_id: fieldId,
        field_name: fieldName,
        value: fieldValue,
      });
    }
  });

  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const colourOptions = [
    { value: 'blue', label: 'Blue', color: '#0052CC' },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630' },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
  ];
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];
  const [adsCount, setAdsCount] = useState();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const [ads, setAds] = useState([]);
  const [SelectedAd, setSelectedAd] = useState([]);
  const history = useHistory();
  const [showLList, setshowList] = useState(false);
  const [showCard, setshowCard] = useState(true);
  const [text, setText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [tableFields, setTableFields] = useState([]);
  const [tableSubFields, setTableSubFields] = useState([]);
  const [tableFieldsCat, setTableFieldsCat] = useState([]);
  const [fieldsValues, setFieldsValues] = useState([]);
  const [subFieldsValues, setSubFieldsValues] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');
  const [selectedCityOption, setSelectedCityOption] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedSecteurOption, setSelectedSecteurOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedSubcategoryLabel, setSelectedSubcategoryLabel] = useState('');

  const [length, setLength] = useState(selectedCityOption.length);
  const [cities, setCities] = useState([]);

  const [selectedCityId, setSelectedCityId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [secteurs, setSecteurs] = useState([]);
  const [selectedSecteur, setSelectedsecteur] = useState('');

  const [selectedCity, setSelectedCity] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const bg = useColorModeValue('white', 'navy.700');
  const inputStyles = css`
    &::placeholder {
      color: gray.700; /* Set the desired placeholder color */
    }
  `;
  // Add a state to toggle the dropdown menu
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [dropdownStates, setDropdownStates] = useState({}); 
  const [isDropdownOpenCity, setIsDropdownOpenCity] = useState(false);
  const [reset, SetReset] = useState(false);
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const placeholder = useColorModeValue('gray.600', 'gray.200');
  const border = useColorModeValue('gray.200', 'gray.100');
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const [selectedType, setSelectedType] = useState(
    selectedCategoryLabel === "Demande d'emploi et stages" ||
      selectedCategoryLabel === "Offres d'emploi et stages"
      ? null
      : 'offre'
  );
  const onChangeText = e => {
    setText(e.target.value);
  };
  useEffect(() => {
    if (selectedCategory) {
      axios
        .get(
          `${process.env.REACT_APP_API}/customFields/get/category/${selectedCategory}`
        )
        .then(response => {
          setTableFieldsCat(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedCategory]);
  const handleRadioChange = value => {
    console.log('Selected value:', value);
    setSelectedType(value);
  };
  useEffect(() => {
    if (selectedSubcategory) {
      axios
        .get(
          `${process.env.REACT_APP_API}/customFields/get/subcategory/${selectedSubcategory}`
        )
        .then(response => {
          setTableFields(response.data); // set the first object in the array as the state value
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedSubcategory]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/categories`)
      .then(res => setCategories(res.data))
      .catch(err => console.error(err));
  }, []);
  useEffect(() => {
    if (selectedCategoryId) {
      axios
        .get(`${process.env.REACT_APP_API}/subcategories/${selectedCategoryId}`)
        .then(res => setSubcategories(res.data))
        .catch(err => console.error(err));
    }
  }, [selectedCategoryId]);
  console.log(selectedCategoryId);
  useEffect(() => {
    // let cachedData = localStorage.getItem('citiesData');

    // if (cachedData) {
    //   setCities(JSON.parse(cachedData));
    // } else {
    let url = `${process.env.REACT_APP_API}/cities?name=${searchQuery}`;

    axios
      .get(url)
      .then(res => {
        console.log('API Response:', res.data); // Check the response data
        setCities(res.data);
        // localStorage.setItem('citiesData', JSON.stringify(res.data)); // Cache the response data
      })
      .catch(err => console.error(err));
  }, [searchQuery]);
  console.log(selectedCityId);
  console.log(secteurs);
  function fetchSecteurs(selectedCityId) {
    if (selectedCityId) {
      axios
        .get(`${process.env.REACT_APP_API}/secteurs/${selectedCityId}`)
        .then(res => {
          setSecteurs(res.data); // Update the sectors state with the fetched data
        })
        .catch(err => {
          console.error(err);
          // Handle the error (e.g., show an error message, handle fallback data)
        });
    }
  }
  useEffect(() => {
    console.log('Updated dropdownStates:', dropdownStates); // Log the updated state

  }, [dropdownStates]);

  const handleDropdownToggle = (id) => {
    console.log(`Toggling dropdown for id: ${id}`);
    setDropdownStates(prevState => {
      const newState = {
        ...prevState,
        [id]: !prevState[id], // Toggle the dropdown state for this specific subfield
      };
      console.log('Updated dropdownStates:', dropdownStates); // Log the updated state
      return newState;
    });
  };
  const getLikers = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${id}/like`
      );

      const likerIds = response.data;

      const likerData = [];

      for (const likerId of likerIds) {
        const userDataResponse = await axios.get(
          `${process.env.REACT_APP_API}/users/${likerId}`
        );
        const userData = userDataResponse.data;
        likerData.push(userData);

        console.log('User Data:', userData); // Log the user data for debugging
      }

      const profilePictures = likerData.map(liker => liker.profilePicture);

      console.log('Profile Pictures:', profilePictures); // Log profile pictures for debugging

      return profilePictures;
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const handleSearchQueryChange = event => {
    setSearchQuery(event.target.value);
    setSelectedCityOption([]);
  };
  const handleFieldsChange = (fieldId, fieldType, e) => {
    console.log('Field ID:', fieldId);
    console.log('Field Type:', fieldType);
    console.log('Field Name:', e?.target?.name);
    console.log('Event:', e);
  
    let value;
  
    switch (fieldType) {
      case 'radio':
        value = e; // Directly use e as the value for radio buttons
        break;
  
      case 'select':
        value = e?.target?.value || ''; // Extract value from the target for select fields
        break;
  
      case 'multi':
        if (!e || !Array.isArray(e)) {
          console.error('Unexpected value for multi field:', e);
          value = [];
        } else {
          value = Array.from(e);
        }
        break;
  
      default:
        value = e?.target?.value || '';
        break;
    }
  
    setFieldsValues((prevFieldsValues) => {
      const updatedValues = [...prevFieldsValues];
      const index = updatedValues.findIndex((item) => item.field_id === fieldId);
      if (index >= 0) {
        updatedValues[index].value = value;
      } else {
        updatedValues.push({
          field_id: fieldId,
          field_name: e?.target?.name,
          value: value,
        });
      }
      console.log(updatedValues);
      return updatedValues;
    });
  };
  

  useEffect(() => {
    console.log(fieldsValues);
  }, [fieldsValues]);
  const matchingField = tableFields.filter(field => {
    const matchingValue = fieldsValues.find(
      item => item.field_id === field._id
    );
    return matchingValue !== undefined;
  });
  useEffect(() => {
    fieldsValues.forEach(f => {
      if (f.value) {
        axios
          .get(
            `${process.env.REACT_APP_API}/subCustomFields/get/customField/value/${f.value}/${selectedSubcategory}`
          )
          .then(response => {
            setTableSubFields(prevSubFields => {
              // Filter out existing subfield values to avoid duplicates
              const filteredSubFields = prevSubFields.filter(
                subField =>
                  !response.data.some(
                    newSubField => newSubField.id === subField.id
                  )
              );
              // Merge the filtered previous subfield values with the new ones
              return [...filteredSubFields, ...response.data];
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }, [fieldsValues]);

  const handleSubFieldsChange = (fieldId, fieldType, e) => {
    console.log('Field ID:', fieldId);
    console.log('Field Type:', fieldType);
    console.log('Field Name:', e?.target?.name);
    console.log('Event:', e);
  
    let value;
  
    switch (fieldType) {
      case 'radio':
        value = e; // Directly use e as the value for radio buttons
        break;
  
      case 'select':
        value = e?.target?.value || ''; // Extract value from the target for select fields
        break;
  
      case 'multi':
        if (!e || !Array.isArray(e)) {
          console.error('Unexpected value for multi field:', e);
          value = [];
        } else {
          value = Array.from(e);
        }
        break;
  
      default:
        value = e?.target?.value || '';
        break;
    }
  
    setSubFieldsValues((prevFieldsValues) => {
      const updatedValues = [...prevFieldsValues];
      const index = updatedValues.findIndex((item) => item.field_id === fieldId);
  
      if (index >= 0) {
        updatedValues[index].value = value;
      } else {
        updatedValues.push({
          field_id: fieldId,
          field_name: e?.target?.name,
          value: value,
        });
      }
  
      console.log('Updated Values:', updatedValues);
      return updatedValues;
    });
  };
  
  
  const getAdById = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/ad/${id}`
      );
      setSelectedAd(response.data);

      history.push(`/ads/${id}`);
    } catch (error) {
      console.error(error);
    }
  };
  const [test, setTest] = useState(true);

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      SetReset(true);
      const params = new URLSearchParams();
      params.append('category', selectedCategory);
      params.append('type', selectedType);
      params.append('subcategory', selectedSubcategory);
      selectedCityOption.forEach(city => {
        params.append('cities', city);
      });
      params.append('text', text);
      if (isChecked) {
        params.append('minPrice', minPrice);

        params.append('maxPrice', maxPrice);
      }

      fieldsValues.forEach((field, index) => {
        params.append(`customFields[${index}][field_name]`, field.field_name);
        params.append(`customFields[${index}][value]`, field.value);
      });

      subFieldsValues.forEach((field, index) => {
        params.append(
          `subCustomFields[${index}][field_name]`,
          field.field_name
        );
        params.append(`subCustomFields[${index}][value]`, field.value);
      });

      const queryString = params.toString();
      const url = `${process.env.REACT_APP_API}/ads/search?${queryString}`;

      const response = await axios.get(url);
      console.log('data search', response.data);
      setAds(response.data);
      if (response.data.length === 0) {
        toast.error(`Aucun résultat n'a été trouvé.`, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log('ads search', ads);
  }, [ads]);
  const NFTWrapper = ({ ad }) => {
    const [likersForAd, setLikersForAd] = useState([]);

    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <NFT
        id={ad._id}
        bidders={likersForAd}
        name={ad.name}
        author={ad.firstName + ' ' + ad.lastName}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        category={ad.categoryLabel}
        propart={ad.shop ? 'pro' : 'part'}
        currentbid={ad.price === 0 ? t('Non défini') : `${ad.price} MAD`}
        Click={handleClick}
        city={ad.city}
        nbrPictures={`${ad.adPictures.length} images `}
        dateCreated={moment(ad.createdAt).fromNow()}
      />
    );
  };

  const items = Array.from({ length: 12 });
  const ListWrapper = ({ ad }) => {
    const [likersForAd, setLikersForAd] = useState([]);

    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }

      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <AdAsList
        id={ad._id}
        title={ad.name}
        category={ad.categoryLabel}
        //link={ad.name}
        price={ad.price === 0 ? t('Non défini') : `${ad.price} MAD`}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        city={ad.city}
        bidders={likersForAd}
        dateCreated={moment(ad.createdAt).fromNow()}
        propart={ad.shop ? 'pro' : 'part'}
        nbrPictures={`${ad.adPictures.length} images `}
        Click={handleClick}
      />
    );
  };
  // function handleCityOptionChange(newValues) {
  useEffect(() => {
    if (selectedCityOption.length > 0) {
      const selectedCity = cities.find(
        city => city.name === selectedCityOption[0]
      );
      if (selectedCity) {
        fetchSecteurs(selectedCity.id); // Fetch sectors for the selected city
      }
    }
  }, [selectedCityOption, cities]);

  const dropdownRef = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        (!selectRef.current || !selectRef.current.contains(event.target))
      ) {
        setIsDropdownOpenCity(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef, selectRef]);

  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    setShowButton(scrollPosition > 2);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? 'rgb(17, 28, 68)' : 'gray.200';

  return (
    <Box mb={5}>
      {isLoading ? (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }} className="Box">
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            gap="20px"
            mb={{ base: '20px', xl: '0px' }}
          >
            {[...Array(9)].map((_, index) => (
              <Box
                key={index}
                borderRadius="lg"
                overflow="hidden"
                className="Box"
              >
                <Skeleton
                  height={{ base: '120px', md: '200px' }}
                  width="100%"
                  startColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
                  endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                />
                <Box p="6">
                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                    color={textColor}
                  >
                    <Skeleton
                      height="10px"
                      width={{ base: '50%', md: '30%' }}
                      mb={2}
                      startColor={
                        colorMode === 'dark' ? 'gray.700' : 'gray.200'
                      }
                      endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                    />
                  </Box>
                  <Box
                    d="flex"
                    alignItems="baseline"
                    color={textColorBrand}
                    fontSize="sm"
                  >
                    <Skeleton
                      height="10px"
                      width={{ base: '20%', md: '10%' }}
                      mr={2}
                      startColor={
                        colorMode === 'dark' ? 'gray.700' : 'gray.200'
                      }
                      endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <Box pt={{ base: '11vh', md: '80px', xl: '20px' }}>
          <Flex mb="40px" flexDir="column">
            <Flex direction="row" w="100%" alignContent="center">
              <Card mb={5}>
                <Flex
                  align="center"
                  mt={{ base: '10px', md: '10px' }}
                  direction="column"
                  w="100%"
                  maxW="100%"
                  background="transparent"
                  borderRadius="15px"
                  me="auto"
                >
                  <FormControl>
                    <Box mb="20px">
                      <InputGroup>
                        <InputLeftElement
                          children={
                            <IconButton
                              mt={2}
                              bg="inherit"
                              borderRadius="inherit"
                              _hover="none"
                              _active={{
                                bg: 'inherit',
                                transform: 'none',
                                borderColor: 'transparent',
                              }}
                              _focus={{
                                boxShadow: 'none',
                              }}
                              icon={
                                <SearchIcon
                                  color={searchIconColor}
                                  w="15px"
                                  h="15px"
                                />
                              }
                            />
                          }
                        />
                        <Input
                          variant="auth"
                          name="text"
                          size="lg"
                          onChange={onChangeText}
                          w="100%"
                          fontSize="sm"
                          bg={inputBg}
                          color={inputText}
                          fontWeight="200"
                          _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                          borderRadius="10px"
                          placeholder={t('Que Recherchez-vous ?')}
                        />
                      </InputGroup>
                    </Box>
                    <Grid
                      templateColumns={{
                        base: '1fr',
                        md: '1fr',
                        xl:
                          selectedCityOption.length === 1
                            ? 'repeat(2, 1fr)'
                            : '1fr',
                      }}
                      gap={4}
                      mb={4}
                    >
                      <Box>
                        {/* Choisir Ville  */}
                        <Box position="relative">
                          <InputGroup>
                            <Input
                              ref={selectRef}
                              placeholder={t('Choisissez votre ville')}
                              _placeholder={{
                                color: placeholder,
                                fontWeight: '300', // Set the desired placeholder color
                              }}
                              variant="auth"
                              w="100%"
                              borderColor={border}
                              value={selectedCityOption}
                              readOnly
                              cursor="pointer"
                              onClick={() =>
                                setIsDropdownOpenCity(!isDropdownOpenCity)
                              }
                              pr="3rem" // Add padding-right to make space for the icon
                            />
                            <InputRightElement
                              pointerEvents="none"
                              top="50%" // Vertically align the icon
                              right="0.5rem" // Adjust the positioning of the icon
                              transform="translateY(-50%)" // Vertically center the icon
                              cursor="pointer"
                            >
                              <Icon as={FiChevronDown} />
                            </InputRightElement>
                          </InputGroup>

                          {isDropdownOpenCity && (
                            <Box
                              ref={dropdownRef}
                              position="absolute"
                              top="100%"
                              left={0}
                              zIndex={2}
                              width="100%"
                              bg={bg}
                              p={2}
                              borderWidth={2}
                              boxShadow="md"
                              maxHeight="400px" // Set the maximum height for scrollable content
                              overflowY="scroll"
                              overflowX="hidden"
                            >
                              <Input
                                type="text"
                                variant="auth"
                                value={searchQuery}
                                onChange={handleSearchQueryChange}
                                placeholder="Rechercher une ville"
                                mb={2}
                              />
                              {/* <CheckboxGroup
                            colorScheme="blue"
                            value={selectedCityOption}
                            onChange={values => {
                              setSelectedCityOption(values);
                              const SelectedOne = cities.find(
                                city => city.name === selectedCityOption[0]
                              );
                              if (SelectedOne) {
                                setSelectedCityId(SelectedOne.id);
                                // do something with the selected city id
                              }
                            }}
                          > */}
                              {/* <ul align="start" spacing={2}>
                              {cities.map(city => (
                                <li key={city.name} value={city.name} 
                                onClick={() => handleCityOptionChange([city.name])}>
                                  {city.name}
                                </li>
                              ))}
                            </ul> */}

                              <ul>
                                {cities.map(city => (
                                  <li
                                    key={city.name}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <label>
                                      <input
                                        style={{ marginRight: '6px' }}
                                        type="checkbox"
                                        value={city.name}
                                        checked={selectedCityOption.includes(
                                          city.name
                                        )}
                                        onChange={e => {
                                          const value = e.target.value;
                                          const checked = e.target.checked;
                                          if (checked) {
                                            const newValues = [
                                              ...selectedCityOption,
                                              value,
                                            ];
                                            setSelectedCityOption(newValues);
                                          } else if (!checked) {
                                            const newValues =
                                              selectedCityOption.filter(
                                                v => v !== value
                                              );
                                            setSelectedCityOption(newValues);
                                          }

                                          // if(newValues.length===1)
                                          // handleCityOptionChange(newValues);
                                        }}
                                      />
                                      {city.name}
                                    </label>
                                  </li>
                                ))}
                              </ul>
                              {/* </CheckboxGroup> */}
                            </Box>
                          )}
                        </Box>
                        {/* <MultiselectCheckboxes
                      width={{ base: '400px', md: '1000px' }}
      options={test}
      placeholder="Select cities"
      selectedValues={selectedCityOption}
      onSelectedValuesChange={handleCityOptionChange}
    /> */}

                        {/* <InputRightElement
                          pointerEvents="none"
                          top="50%" // Vertically align the icon
                          right="0.5rem" // Adjust the positioning of the icon
                          transform="translateY(-50%)" // Vertically center the icon
                          cursor="pointer"
                        >
                          <Icon as={FiChevronDown} />
                        </InputRightElement> */}
                      </Box>
                      <Box>
                        {selectedCityOption.length === 1 ? (
                          <InputGroup>
                            <Select
                              id="sector"
                              name="sector"
                              variant="auth"
                              ms={{ base: '0px', md: '0px' }}
                              placeholder={
                                selectedCityOption[0]
                                  ? t(
                                      `Choisissez le secteur de la ville de ${selectedCityOption[0]}`
                                    )
                                  : t(`Choisissez le secteur`)
                              }
                              size="md"
                              onChange={e => {
                                setSelectedsecteur(e.target.value);
                              }}
                            >
                              {secteurs?.map(option => (
                                <option key={option._id} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                          </InputGroup>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid
                      templateColumns={{
                        base: '1fr',
                        md: '1fr',
                        xl: 'repeat(2, 1fr)',
                      }}
                      gap={4}
                    >
                      <Box>
                        <InputGroup>
                          <Select
                            id="category"
                            name="categoryName"
                            isRequired={true}
                            size="md"
                            variant="auth"
                            onChange={e => {
                              const selectedCategoryId =
                                e.target.options[e.target.selectedIndex].dataset
                                  .id;
                              const selectedCategory = categories.find(
                                category => category._id === selectedCategoryId
                              );
                              setSelectedSubcategory(null);
                              setSelectedCategory(selectedCategory?.name);
                              setSelectedCategoryId(selectedCategoryId);
                              setSelectedCategoryLabel(selectedCategory?.label);
                            }}
                            placeholder={t('Choisir une categorie')}
                          >
                            {categories.map(category => (
                              <option
                                key={category._id}
                                value={category.name}
                                name={category.label}
                                data-id={category._id}
                              >
                                {category.label}
                              </option>
                            ))}
                          </Select>
                        </InputGroup>
                      </Box>
                      <Box>
                        <InputGroup>
                          <Select
                            id="category"
                            name="categoryName"
                            isRequired={true}
                            mb="24px"
                            size="md"
                            variant="auth"
                            onChange={e => {
                              setSelectedSubcategory(e.target.value);
                              setSelectedSubcategoryLabel(
                                e.target.options[e.target.selectedIndex].text
                              );
                            }}
                            value={selectedSubcategory}
                            placeholder={t('Choisir une sous-categorie')}
                          >
                            {subcategories.map(subcategory => (
                              <option
                                key={subcategory._id}
                                value={subcategory.name}
                                name={subcategory.label}
                              >
                                {' '}
                                {subcategory.label}{' '}
                              </option>
                            ))}
                          </Select>
                        </InputGroup>
                      </Box>
                    </Grid>
                    <Grid
                      templateColumns={{
                        base: '1fr',
                        md: '1fr',
                        xl: 'repeat(2, 1fr)',
                      }}
                      gap={4}
                    >
                      <Box>
                        <Checkbox
                          size="lg"
                          colorScheme="green"
                          mb={4}
                          isChecked={isChecked}
                          onChange={e => setIsChecked(e.target.checked)}
                        >
                          <FormLabel
                            mt={2}
                            fontSize="md"
                            fontWeight="500"
                            color={textColor}
                          >
                            Interval de prix (MAD)
                          </FormLabel>
                        </Checkbox>
                        {isChecked ? (
                          <Box>
                            <HStack spacing="15px" justify="center">
                              <Text>
                                Min &nbsp;
                                <Input
                                  variant="auth"
                                  type="number"
                                  onChange={e => {
                                    const newMinPrice = parseInt(
                                      e.target.value
                                    );
                                    const newRange = [newMinPrice, range[1]];
                                    setRange(newRange);
                                    setMinPrice(newMinPrice);
                                    if (newRange[1] < newMinPrice) {
                                      setRange([newMinPrice, newMinPrice]);
                                    }
                                  }}
                                  value={range[0]}
                                />
                              </Text>

                              <Text>
                                Max &nbsp;
                                <Input
                                  variant="auth"
                                  type="number"
                                  onChange={e => {
                                    const newMaxPrice = parseInt(
                                      e.target.value
                                    );
                                    const newRange = [range[0], newMaxPrice];
                                    setRange(newRange);
                                    setMaxPrice(newMaxPrice);
                                    if (newRange[0] > newMaxPrice) {
                                      setRange([newMaxPrice, newMaxPrice]);
                                    }
                                  }}
                                  value={range[1]}
                                />
                              </Text>
                            </HStack>

                            <RangeSlider
                              aria-labelledby="Price Range"
                              value={range}
                              min={0}
                              max={100000}
                              mt={6}
                              mb={4}
                              step={5000}
                              onChange={value => {
                                setRange(value);
                                setMaxPrice(range[1]);
                                setMinPrice(range[0]);
                              }}
                            >
                              <RangeSliderTrack>
                                <RangeSliderFilledTrack bg="purple.300" />
                              </RangeSliderTrack>
                              <RangeSliderThumb index={0} boxSize={6}>
                                <Tooltip
                                  label={range[0]}
                                  hasArrow
                                  placement="top"
                                >
                                  <Flex align="center" direction="column">
                                    <Icon
                                      as={IoMdPricetag}
                                      boxSize={4}
                                      color="purple"
                                    />
                                  </Flex>
                                </Tooltip>
                              </RangeSliderThumb>
                              <RangeSliderThumb index={1} boxSize={6}>
                                <Tooltip
                                  label={range[1]}
                                  hasArrow
                                  placement="top"
                                >
                                  <Flex align="center" direction="column">
                                    <Icon
                                      as={IoMdPricetag}
                                      boxSize={4}
                                      color="purple"
                                    />
                                  </Flex>
                                </Tooltip>
                              </RangeSliderThumb>
                            </RangeSlider>
                          </Box>
                        ) : null}
                      </Box>
                      {selectedCategoryLabel === "Demande d'emploi et stages" ||
                      selectedCategoryLabel ===
                        "Offres d'emploi et stages" ? null : (
                        <Box height="90px">
                          <FormLabel
                            ms="4px"
                            fontSize="md"
                            fontWeight="500"
                            color={textColor}
                            display="flex"
                          >
                            {t(`Choisissez le type de votre annonce`)}{' '}
                            <Text color={brandStars}> * </Text>{' '}
                          </FormLabel>
                          <RadioGroup
                            name="type"
                            variant="auth"
                            onChange={handleRadioChange}
                            value={selectedType}
                            mb="20px"
                          >
                            <Stack direction="row">
                              <Radio colorScheme="red" size="lg" value="offre">
                                {t('Offre')}
                              </Radio>
                              <Radio
                                colorScheme="green"
                                size="lg"
                                value="demande"
                              >
                                {t('Demande')}
                              </Radio>
                            </Stack>
                          </RadioGroup>{' '}
                        </Box>
                      )}
                    </Grid>{' '}
                    {selectedSubcategory && (
                      <>
                        {tableFields.map(field => (
                          <>
                            <Box mb={2} key={field._id}>
                              <FormLabel
                                ms="4px"
                                fontSize="md"
                                fontWeight="600"
                                color={textColor}
                                display="flex"
                              >
                                {field.name}
                              </FormLabel>
                              <FormControl isRequired={true}>
                                {field.type === 'text' && (
                                  <InputGroup size="md">
                                    <Input
                                      name={field.name}
                                      fontSize="sm"
                                      placeholder="Enter text"
                                      mb="24px"
                                      size="lg"
                                      variant="auth"
                                      value={
                                        fieldsValues.find(
                                          item => item.field_id === field._id
                                        )?.value
                                      }
                                      onChange={value =>
                                        handleFieldsChange(
                                          field._id,
                                          field.type,
                                          value
                                        )
                                      }
                                    />
                                  </InputGroup>
                                )}
                                {field.type === 'textarea' && (
                                  <Textarea
                                    fontSize="sm"
                                    placeholder="Enter text"
                                    mb="24px"
                                    size="lg"
                                    variant="auth"
                                    name={field.name}
                                    value={
                                      fieldsValues.find(
                                        item => item.field_id === field._id
                                      )?.value
                                    }
                                    onChange={value =>
                                      handleFieldsChange(
                                        field._id,
                                        field.type,
                                        value
                                      )
                                    }
                                  />
                                )}
                                {field.type === 'radio' && (
                                  <RadioGroup
                                    flexWrap="wrap"
                                    variant="auth"
                                    name={field.name}
                                    value={
                                      fieldsValues.find(
                                        item => item.field_id === field._id
                                      )?.value
                                    }
                                    onChange={value =>
                                      handleFieldsChange(
                                        field._id,
                                        field.type,
                                        value
                                      )
                                    }
                                    size="lg"
                                  >
                                    <Wrap
                                      mb="20px"
                                      direction="row"
                                      flexWrap="wrap"
                                    >
                                      {field.options.map(option => (
                                        <WrapItem key={option}>
                                          <Radio value={option}>{option}</Radio>
                                        </WrapItem>
                                      ))}
                                    </Wrap>
                                  </RadioGroup>
                                )}

                                {field.type === 'select' && (
                                  <Select
                                    sx={{
                                      // Custom CSS styles go here
                                      borderRadius: '8px',
                                      border: '2px solid navy',
                                      color: 'navy',
                                      // You can add more custom styles as needed
                                    }}
                                    placeholder="Select option"
                                    name={field.name}
                                    value={
                                      fieldsValues.find(
                                        item => item.field_id === field._id
                                      )?.value
                                    }
                                    onChange={value =>
                                      handleFieldsChange(
                                        field._id,
                                        field.type,
                                        
                                        value
                                      )
                                    }
                                    fontSize="sm"
                                    mb="24px"
                                    mt={4}
                                    size="lg"
                                    variant="auth"
                                  >
                                    {field.options.map(option => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                                {field.type === 'multi' && (
                               <FormControl isRequired={true}>
                               <Box position="relative">
                                 <InputGroup>
                                   <Input
                                     placeholder="Select options"
                                     variant="auth"
                                     value={selectedOptions[field._id]?.join(', ') || ''}
                                     readOnly
                                     cursor="pointer"
                                     onClick={() => {
                                      console.log('Toggling dropdown for id:', field._id); // Log here to confirm the click event
                                      handleDropdownToggle(field._id); // Call the toggle function
                                    }}
                                     pr="3rem" // Add padding-right to make space for the icon
                                   />
                                   <InputRightElement
                                     pointerEvents="none"
                                     top="50%" // Vertically align the icon
                                     right="0.5rem" // Adjust the positioning of the icon
                                     transform="translateY(-50%)" // Vertically center the icon
                                     cursor="pointer"
                                   >
                                     <Icon
                                       as={FiChevronDown}
                                     />
                                   </InputRightElement>
                                 </InputGroup>

                                 {dropdownStates[
                                   field._id
                                 ] && (
                                   <Box
                                     position="absolute"
                                     top="100%"
                                     left={0}
                                     zIndex={1}
                                     width="100%"
                                     bg={bg}
                                     p={2}
                                     borderWidth={1}
                                     boxShadow="sm"
                                   >
                                     <CheckboxGroup
                                       colorScheme="blue"
                                       value={
                                         selectedOptions[
                                           field._id
                                         ] || []
                                       }
                                       onChange={values => {
                                        console.log('CheckboxGroup onChange values:', values);
                                        handleSubFieldsChange(
                                           field._id,
                                           field.type,
                                           values
                                         );
                                         setSelectedOptions(
                                           prev => ({
                                             ...prev,
                                             [field._id]:
                                               values, // Update selected options for this field
                                           })
                                         );
                                       }}
                                     >
                                       <VStack
                                         align="start"
                                         spacing={2}
                                       >
                                         {field.options.map(
                                           option => (
                                             <Checkbox
                                               key={option}
                                               value={option}
                                             >
                                               {option}
                                             </Checkbox>
                                           )
                                         )}
                                       </VStack>
                                     </CheckboxGroup>
                                   </Box>
                                 )}
                               </Box>
                             </FormControl>
                                )}
                                {field.type === 'number' && (
                                  <InputGroup size="md">
                                    <Input
                                      name={field.name}
                                      fontSize="sm"
                                      placeholder="Entrer la valeure"
                                      mb="24px"
                                      size="lg"
                                      variant="auth"
                                      type="number"
                                      aria-label="Entrez un nombre"
                                      value={
                                        fieldsValues.find(
                                          item => item.field_id === field._id
                                        )?.value
                                      }
                                      onChange={value =>
                                        handleFieldsChange(
                                          field._id,
                                          field.type,
                                          value
                                        )
                                      }
                                    />
                                  </InputGroup>
                                )}
                              </FormControl>
                            </Box>

                            {tableSubFields
                              .filter(
                                subField => subField.customFieldId === field._id
                              )
                              .map(subField => (
                                <Box key={subField._id}>
                                  <FormLabel
                                    ms="4px"
                                    fontSize="md"
                                    fontWeight="600"
                                    color={textColor}
                                    display="flex"
                                  >
                                    {subField.name}
                                  </FormLabel>
                                  <FormControl isRequired={true}>
                                    {subField.type === 'text' && (
                                      <InputGroup size="md">
                                        <Input
                                          fontSize="sm"
                                          placeholder="Enter text"
                                          mb="24px"
                                          size="lg"
                                          variant="auth"
                                          value={
                                            subFieldsValues.find(
                                              item =>
                                                item.field_id === subField._id
                                            )?.value
                                          }
                                          onChange={value =>
                                            handleFieldsChange(
                                              subField._id,
                                              subField.type,
                                              value
                                            )
                                          }
                                          name={subField.name}
                                        />
                                      </InputGroup>
                                    )}
                                    {subField.type === 'textarea' && (
                                      <Textarea
                                        fontSize="sm"
                                        placeholder="Enter text"
                                        mb="24px"
                                        size="lg"
                                        variant="auth"
                                        name={subField.name}
                                        value={
                                          subFieldsValues.find(
                                            item =>
                                              item.field_id === subField._id
                                          )?.value
                                        }
                                        onChange={value =>
                                          handleSubFieldsChange(
                                            subField._id,
                                            subField.type,
                                            value
                                          )
                                        }
                                      />
                                    )}
                                    {subField.type === 'radio' && (
                                      <RadioGroup
                                        flexWrap="wrap"
                                        variant="auth"
                                        name={subField.name}
                                        value={
                                          subFieldsValues.find(
                                            item =>
                                              item.field_id === subField._id
                                          )?.value
                                        }
                                        onChange={value =>
                                          handleSubFieldsChange(
                                            subField._id,
                                            subField.type,
                                            value
                                          )
                                        }
                                        mb="40px"
                                        size="lg"
                                      >
                                        <Wrap
                                          mb={5}
                                          direction="row"
                                          flexWrap="wrap"
                                        >
                                          {field.options.map(option => (
                                            <WrapItem key={option}>
                                              <Radio value={option}>
                                                {option}
                                              </Radio>
                                            </WrapItem>
                                          ))}
                                        </Wrap>
                                      </RadioGroup>
                                    )}
                                    {subField.type === 'select' && (
                                      <Select
                                        placeholder="Select option"
                                        name={subField.name}
                                        sx={{
                                          // Custom CSS styles go here
                                          borderRadius: '8px',
                                          border: '2px solid navy',
                                          color: 'navy',
                                          // You can add more custom styles as needed
                                        }}
                                        value={
                                          subFieldsValues.find(
                                            item =>
                                              item.field_id === subField._id
                                          )?.value
                                        }
                                        onChange={value =>
                                          handleSubFieldsChange(
                                            subField._id,
                                            subField.type,
                                            value
                                          )
                                        }
                                        fontSize="sm"
                                        mb="24px"
                                        mt={5}
                                        size="lg"
                                        variant="auth"
                                      >
                                        {subField.options.map(option => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </Select>
                                    )}
                                    {subField.type === 'multi' && (
                                     <FormControl isRequired={true}>
                                     <Box position="relative">
                                       <InputGroup>
                                         <Input
                                           placeholder="Select options"
                                           variant="auth"
                                           value={selectedOptions[subField._id]?.join(', ') || ''}
                                           readOnly
                                           cursor="pointer"
                                           onClick={() => {
                                            console.log('Toggling dropdown for id:', subField._id); // Log here to confirm the click event
                                            handleDropdownToggle(subField._id); // Call the toggle function
                                          }}
                                           pr="3rem" // Add padding-right to make space for the icon
                                         />
                                         <InputRightElement
                                           pointerEvents="none"
                                           top="50%" // Vertically align the icon
                                           right="0.5rem" // Adjust the positioning of the icon
                                           transform="translateY(-50%)" // Vertically center the icon
                                           cursor="pointer"
                                         >
                                           <Icon
                                             as={FiChevronDown}
                                           />
                                         </InputRightElement>
                                       </InputGroup>

                                       {dropdownStates[
                                         subField._id
                                       ] && (
                                         <Box
                                           position="absolute"
                                           top="100%"
                                           left={0}
                                           zIndex={1}
                                           width="100%"
                                           bg={bg}
                                           p={2}
                                           borderWidth={1}
                                           boxShadow="sm"
                                         >
                                           <CheckboxGroup
                                             colorScheme="blue"
                                             value={
                                               selectedOptions[
                                                 subField._id
                                               ] || []
                                             }
                                             onChange={values => {
                                              console.log('CheckboxGroup onChange values:', values);
                                              handleSubFieldsChange(
                                                 subField._id,
                                                 subField.type,
                                                 values
                                               );
                                               setSelectedOptions(
                                                 prev => ({
                                                   ...prev,
                                                   [subField._id]:
                                                     values, // Update selected options for this subField
                                                 })
                                               );
                                             }}
                                           >
                                             <VStack
                                               align="start"
                                               spacing={2}
                                             >
                                               {subField.options.map(
                                                 option => (
                                                   <Checkbox
                                                     key={option}
                                                     value={option}
                                                   >
                                                     {option}
                                                   </Checkbox>
                                                 )
                                               )}
                                             </VStack>
                                           </CheckboxGroup>
                                         </Box>
                                       )}
                                     </Box>
                                   </FormControl>
                                    )}
                                    {subField.type === 'number' && (
                                      <InputGroup size="md">
                                        <Input
                                          name={field.name}
                                          fontSize="sm"
                                          placeholder="Entrer la valeure"
                                          mb="24px"
                                          size="lg"
                                          variant="auth"
                                          type="number"
                                          aria-label="Entrez un nombre"
                                          value={
                                            fieldsValues.find(
                                              item =>
                                                item.field_id === field._id
                                            )?.value
                                          }
                                          onChange={value =>
                                            handleSubFieldsChange(
                                              field._id,
                                              field.type,
                                              value
                                            )
                                          }
                                        />
                                      </InputGroup>
                                    )}
                                  </FormControl>
                                </Box>
                              ))}
                          </>
                        ))}
                      </>
                    )}
                    {selectedCategory && (
                      <>
                        {tableFieldsCat.map(field => (
                          <Box key={field._id} p="6px">
                            <FormLabel
                              fontSize="md"
                              fontWeight="600"
                              color={textColor}
                              display="flex"
                            >
                              {field.name}
                            </FormLabel>
                            <FormControl isRequired={true}>
                              {field.type === 'text' && (
                                <InputGroup size="md">
                                  <Input
                                    name={field.name}
                                    fontSize="sm"
                                    placeholder="Enter text"
                                    mb="24px"
                                    size="lg"
                                    variant="auth"
                                    value={
                                      fieldsValues.find(
                                        item => item.field_id === field._id
                                      )?.value
                                    }
                                    onChange={value =>
                                      handleFieldsChange(
                                        field._id,
                                        field.type,
                                        value
                                      )
                                    }
                                  />
                                </InputGroup>
                              )}
                              {field.type === 'textarea' && (
                                <Textarea
                                  fontSize="sm"
                                  placeholder="Enter text"
                                  mb="24px"
                                  size="lg"
                                  variant="auth"
                                  name={field.name}
                                  value={
                                    fieldsValues.find(
                                      item => item.field_id === field._id
                                    )?.value
                                  }
                                  onChange={value =>
                                    handleFieldsChange(
                                      field._id,
                                      field.type,
                                      value
                                    )
                                  }
                                />
                              )}
                              {field.type === 'radio' && (
                                <RadioGroup
                                  variant="auth"
                                  name={field.name}
                                  value={
                                    fieldsValues.find(
                                      item => item.field_id === field._id
                                    )?.value
                                  }
                                  onChange={value =>
                                    handleFieldsChange(
                                      field._id,
                                      field.type,
                                      value
                                    )
                                  }
                                  mb="40px"
                                  size="lg"
                                >
                                  <Wrap mb={5} direction="row" flexWrap="wrap">
                                    {field.options.map(option => (
                                      <WrapItem key={option}>
                                        <Radio value={option}>{option}</Radio>
                                      </WrapItem>
                                    ))}
                                  </Wrap>
                                </RadioGroup>
                              )}

                              {field.type === 'select' && (
                                <Select
                                  placeholder="Select option"
                                  name={field.name}
                                  value={
                                    fieldsValues.find(
                                      item => item.field_id === field._id
                                    )?.value
                                  }
                                  sx={{
                                    // Custom CSS styles go here
                                    borderRadius: '8px',
                                    border: '2px solid navy',
                                    color: 'navy',
                                    // You can add more custom styles as needed
                                  }}
                                  onChange={value =>
                                    handleFieldsChange(
                                      field._id,
                                      field.type,
                                      value
                                    )
                                  }
                                  fontSize="sm"
                                  mb="24px"
                                  size="lg"
                                  variant="auth"
                                >
                                  {field.options.map(option => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Select>
                              )}
                              {field.type === 'multi' && (
                               <FormControl isRequired={true}>
                               <Box position="relative">
                                 <InputGroup>
                                   <Input
                                     placeholder="Select options"
                                     variant="auth"
                                     value={selectedOptions[field._id]?.join(', ') || ''}
                                     readOnly
                                     cursor="pointer"
                                     onClick={() => {
                                      console.log('Toggling dropdown for id:', field._id); // Log here to confirm the click event
                                      handleDropdownToggle(field._id); // Call the toggle function
                                    }}
                                     pr="3rem" // Add padding-right to make space for the icon
                                   />
                                   <InputRightElement
                                     pointerEvents="none"
                                     top="50%" // Vertically align the icon
                                     right="0.5rem" // Adjust the positioning of the icon
                                     transform="translateY(-50%)" // Vertically center the icon
                                     cursor="pointer"
                                   >
                                     <Icon
                                       as={FiChevronDown}
                                     />
                                   </InputRightElement>
                                 </InputGroup>

                                 {dropdownStates[
                                   field._id
                                 ] && (
                                   <Box
                                     position="absolute"
                                     top="100%"
                                     left={0}
                                     zIndex={1}
                                     width="100%"
                                     bg={bg}
                                     p={2}
                                     borderWidth={1}
                                     boxShadow="sm"
                                   >
                                     <CheckboxGroup
                                       colorScheme="blue"
                                       value={
                                         selectedOptions[
                                           field._id
                                         ] || []
                                       }
                                       onChange={values => {
                                        console.log('CheckboxGroup onChange values:', values);
                                        handleFieldsChange(
                                           field._id,
                                           field.type,
                                           
                                           values
                                         );
                                         setSelectedOptions(
                                           prev => ({
                                             ...prev,
                                             [field._id]:
                                               values, // Update selected options for this field
                                           })
                                         );
                                       }}
                                     >
                                       <VStack
                                         align="start"
                                         spacing={2}
                                       >
                                         {field.options.map(
                                           option => (
                                             <Checkbox
                                               key={option}
                                               value={option}
                                             >
                                               {option}
                                             </Checkbox>
                                           )
                                         )}
                                       </VStack>
                                     </CheckboxGroup>
                                   </Box>
                                 )}
                               </Box>
                             </FormControl>
                              )}
                              {field.type === 'number' && (
                                <InputGroup size="md">
                                  <Input
                                    name={field.name}
                                    fontSize="sm"
                                    placeholder="Entrer la valeure"
                                    mb="24px"
                                    size="lg"
                                    variant="auth"
                                    type="number"
                                    aria-label="Entrez un nombre"
                                    value={
                                      fieldsValues.find(
                                        item => item.field_id === field._id
                                      )?.value
                                    }
                                    onChange={value =>
                                      handleFieldsChange(
                                        field._id,
                                        field.type,
                                        value
                                      )
                                    }
                                  />
                                </InputGroup>
                              )}
                            </FormControl>
                          </Box>
                        ))}
                      </>
                    )}
                    {reset === false ? (
                      <Button
                        onClick={handleSubmit}
                        variant="brand"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        mb="15px"
                      >
                        {t('Rechercher')}
                      </Button>
                    ) : (
                      <Flex p="10px">
                        <Button
                          onClick={handleSubmit}
                          variant="brand"
                          fontWeight="500"
                          w="100%" // Adjust the width for responsiveness
                          h="50px"
                          mb="15px"
                          mr="10px" // add margin-right to create space between the buttons
                        >
                          {t('Rechercher')}
                        </Button>
                        {/* <Button
    onClick={handleClick}
    variant="solid" // Use a different variant or style for the reset button
    colorScheme="pink" // Change the colorScheme to red (or any other color you prefer)
    fontWeight="500"
    w={{ base: '100%', md: '25%' }} // Adjust the width for responsiveness
    h="50px"
    mb="15px"
    ml="10px" // add margin-left to create space between the buttons
    leftIcon={<RepeatIcon />} // Add the RepeatIcon to the left of the button text
  >
    {t('Réinitialiser')}
  </Button> */}
                      </Flex>
                    )}
                  </FormControl>
                </Flex>{' '}
              </Card>{' '}
            </Flex>{' '}
            <Flex flexDirection="Column" w="100%">
              {loading ? (
                // Display the loading skeleton while fetching data
                <>
                  <SimpleGrid
                    columns={{ base: 2, md: 4 }}
                    gap="20px"
                    mb={{ base: '20px', xl: '0px' }}
                  >
                    {items.map((_, index) => {
                      return (
                        <Flex key={index} className="nft">
                          <Box borderRadius="lg" overflow="hidden" bg={bgColor}>
                            <Skeleton
                              height={{ base: '200px', md: '200px' }}
                              width="600px"
                              startColor={
                                colorMode === 'dark' ? 'gray.700' : 'gray.200'
                              }
                              endColor={
                                colorMode === 'dark' ? 'gray.600' : 'gray.300'
                              }
                            />
                            {/* <Box height="" width="600px"></Box> */}
                            <Box p="6">
                              <Box
                                mt="1"
                                fontWeight="semibold"
                                as="h4"
                                lineHeight="tight"
                                isTruncated
                                color={textColor}
                              >
                                <Skeleton
                                  height="10px"
                                  width={{ base: '50%', md: '30%' }}
                                  mb={2}
                                  startColor={
                                    colorMode === 'dark'
                                      ? 'gray.700'
                                      : 'gray.200'
                                  }
                                  endColor={
                                    colorMode === 'dark'
                                      ? 'gray.600'
                                      : 'gray.300'
                                  }
                                />
                                <Box
                                  bg={bgColor}
                                  height="20px"
                                  width="50%"
                                  mb={2}
                                ></Box>
                                <Skeleton
                                  height="10px"
                                  width={{ base: '20%', md: '10%' }}
                                  mr={2}
                                  startColor={
                                    colorMode === 'dark'
                                      ? 'gray.700'
                                      : 'gray.200'
                                  }
                                  endColor={
                                    colorMode === 'dark'
                                      ? 'gray.600'
                                      : 'gray.300'
                                  }
                                />
                              </Box>
                              <Box
                                d="flex"
                                alignItems="baseline"
                                color={textColor}
                                fontSize="sm"
                              >
                                <Box
                                  bg={bgColor}
                                  height="10px"
                                  width="20%"
                                  mr={2}
                                ></Box>
                                <Box
                                  bg={bgColor}
                                  height="10px"
                                  width="30%"
                                  mr={2}
                                ></Box>
                              </Box>
                            </Box>
                          </Box>
                        </Flex>
                      );
                    })}
                  </SimpleGrid>{' '}
                </>
              ) : ads && ads.length > 0 ? (
                // Display the ads
                <>
                  <Card mb={5}>
                    <Flex w="100%" justify="space-between">
                      <Flex>
                        {' '}
                        {showCard ? (
                          <>
                            <Button
                              mr={2}
                              w={100}
                              h={10}
                              borderRadius={20}
                              leftIcon={<AiFillAppstore />}
                              colorScheme="brand"
                              variant="solid"
                              onClick={() => [
                                setshowCard(true),
                                setshowList(false),
                                setTest(true),
                              ]}
                            >
                              Cartes{' '}
                            </Button>{' '}
                            <IconButton
                              w={15}
                              h={10}
                              borderRadius={20}
                              icon={<AiFillDatabase />}
                              colorScheme="navy"
                              variant="solid"
                              onClick={() => [
                                setshowCard(false),
                                setshowList(true),
                                setTest(false),
                              ]}
                            />
                          </>
                        ) : (
                          <>
                            <IconButton
                              w={15}
                              mr={2}
                              h={10}
                              borderRadius={20}
                              icon={<AiFillAppstore />}
                              colorScheme="navy"
                              variant="solid"
                              onClick={() => [
                                setshowCard(true),
                                setshowList(false),
                                setTest(true),
                              ]}
                            />{' '}
                            <Button
                              w={100}
                              h={10}
                              borderRadius={20}
                              leftIcon={<AiFillDatabase />}
                              colorScheme="brand"
                              variant="solid"
                              onClick={() => [
                                setshowCard(false),
                                setshowList(true),
                                setTest(false),
                              ]}
                            >
                              Listes{' '}
                            </Button>
                          </>
                        )}
                      </Flex>

                      <Text mt={2} ml={2} fontWeight={700} color="navy">
                        {adsCount} Résultats{' '}
                      </Text>
                    </Flex>{' '}
                  </Card>

                  {showLList &&
                    (ads.length > 0
                      ? ads.map( ad  => {
                          const handleClick = () => {
                            getAdById(ad._id); // Get the ad by its id when the component is clicked
                          };

                          return (
                            <LazyLoad height={200} offset={100}>
                              <Flex
                                textAlign="start"
                                mb={5}
                                key={ad._id}
                                cursor="pointer"
                              >
                                <ListWrapper ad={ad} />
                              </Flex>
                            </LazyLoad>
                          );
                        })
                      : null)}

                  {showCard && (
                    <SimpleGrid
                      columns={{ base: 1, md: 3 }}
                      gap="20px"
                      mb={{ base: '20px', xl: '0px' }}
                    >
                      {ads.length > 0
                        ? ads.map(ad => (
                            <LazyLoad key={ad.id} height={200} offset={100}>
                              <Flex className="nft">
                                <NFTWrapper ad={ad} />
                              </Flex>
                            </LazyLoad>
                          ))
                        : null}
                    </SimpleGrid>
                  )}
                </>
              ) : (
                // Display the message if there are no ads
                <Box textAlign="center" mt={8}>
                  <Text fontSize="xl" fontWeight="bold">
                    {t('Faites une recherche pour obtenir des annonces.')}
                  </Text>
                </Box>
              )}
            </Flex>
            {/* <Flex flexDirection="Column" align="end">
                            <Card w={{ base: '100%', xl: '95%' }} p="0px" mb="20px">
                              <Flex
                                align={{ sm: 'flex-start', lg: 'center' }}
                                justify="space-between"
                                w="100%"
                                px="22px"
                                py="18px"
                              >
                                <Text color={textColor} fontSize="xl" fontWeight="600">
                                  Sponsorisées
                                </Text>
                                <Button variant="action">Voir plus</Button>
                              </Flex>

                              <HistoryItem
                                name="Annonce sponsorise 1"
                                author="achraf ait beni ifit"
                                image={Nft5}
                                price="1000 MAD"
                              />
                              <HistoryItem
                                name="Annonce sponsorise 1"
                                author="achraf ait beni ifit"
                                image={Nft5}
                                price="1000 MAD"
                              />
                              <HistoryItem
                                name="Annonce sponsorise 1"
                                author="achraf ait beni ifit"
                                image={Nft5}
                                price="1000 MAD"
                              />
                              <HistoryItem
                                name="Annonce sponsorise 1"
                                author="achraf ait beni ifit"
                                image={Nft5}
                                price="1000 MAD"
                              />
                            </Card>
                          </Flex> */}{' '}
          </Flex>
          {/* <button
      className='btn'
      onClick={handleClick}
      style={{ display: showButton ? "block" : "none" }}
    >
      Resete Serche
    </button> */}{' '}
        </Box>
      )}
    </Box>
  );
}
