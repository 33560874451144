import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Text,
  Spinner,
  Input,
  Button,
  HStack,
  useColorModeValue,
  InputLeftElement,
  InputGroup,
  Checkbox
} from '@chakra-ui/react';
import { FiMoreVertical, FiEdit, FiTrash, FiRepeat } from 'react-icons/fi';
import { SearchIcon } from '@chakra-ui/icons';
import Swal from 'sweetalert2';

const History = () => {
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const brand = useColorModeValue('brand.500', 'pink.200');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgItem = useColorModeValue('white', 'navy.700');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const itemsPerPage = 10;
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/pushNotifications`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      let data = response.data.data;
      data = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setNotifications(data);
      setFilteredNotifications(data);

      const userPromises = data.map(notif =>
        fetchUser(notif.createdBy).then(user => ({ [notif.createdBy]: user }))
      );

      const usersData = await Promise.all(userPromises);
      const usersObject = usersData.reduce(
        (acc, user) => ({ ...acc, ...user }),
        {}
      );
      setUsers(usersObject);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchUser = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${id}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching user:', error);
      return null;
    }
  };
  const deleteNotification = async id => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/pushNotifications/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      fetchNotifications();
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectNotification = NotificationId => {
    setSelectedNotifications(prevSelectedNotifications => {
      if (prevSelectedNotifications.includes(NotificationId)) {
        return prevSelectedNotifications.filter(id => id !== NotificationId);
      } else {
        return [...prevSelectedNotifications, NotificationId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedNotifications(selectAll ? [] : paginatedNotifications.map(notification => notification._id));
  };

  const handleDeleteSelectedNotifications = async () => {
    if (selectedNotifications.length === 0) return;

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await Promise.all(
            selectedNotifications.map(async NotificationId => {
              await deleteNotification(NotificationId);
            })
          );

          Swal.fire(
            'Supprimée !',
            'Vos notifications sélectionnées ont été supprimées.',
            'success'
          );
        } catch (error) {
          console.error(error);
          Swal.fire(
            'Erreur',
            "Une erreur s'est produite lors de la suppression des notifications.",
            'error'
          );
        }

        setSelectedNotifications([]);
      }
    });
  };
  const handleDelete = id => {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(result => {
      if (result.isConfirmed) {
        deleteNotification(id);
        Swal.fire('Supprimée !', 'Votre notification a été supprimé.', 'success');
      }
    });
  };
  useEffect(() => {
    

    fetchNotifications();
  }, []);

  const handleSearch = event => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = notifications.filter(notif =>
      notif.title?.toLowerCase().includes(value)
    );
    setFilteredNotifications(filtered);
    setCurrentPage(1); // Reset to the first page
  };

  const totalPages = Math.ceil(filteredNotifications.length / itemsPerPage);
  const paginatedNotifications = filteredNotifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  const handlePageChange = page => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={10}>
        <Spinner size="lg" />
        <Text mt={4} fontSize="lg" fontWeight="semibold">
          Loading notifications...
        </Text>
      </Box>
    );
  }

  return (
    <Box p={6} minH="100vh">
      <HStack justifyContent="space-between" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">
          Historique des Notifications
        </Text>

        <InputGroup width="300px">
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input
            variant="search"
            fontSize="sm"
            value={searchTerm}
            onChange={handleSearch}
            bg={inputBg}
            color={inputText}
            fontWeight="500"
            _placeholder={{ color: 'gray.400', fontSize: '14px' }}
            borderRadius="30px"
            placeholder="Rechercher par Titre..."
          />
        </InputGroup>
        {selectedNotifications.length > 0 && (
        <Box mb={4} mt={4} p={4} borderRadius="md" bg={bgItem} shadow="lg">
          <Flex justify="space-between" align="center">
            <Text color="red" fontWeight="bold">
              {selectedNotifications.length} notifications
              {selectedNotifications.length > 1 ? 's' : ''} sélectionnée
              {selectedNotifications.length > 1 ? 's' : ''}
            </Text>
            <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteSelectedNotifications}
            >
              Supprimer
            </Button>
          </Flex>
        </Box>
      )}
      </HStack>
      {filteredNotifications.length === 0 ? (
        <Box textAlign="center" mt={10}>
          <Text fontSize="lg" fontWeight="semibold">
            No notifications available.
          </Text>
        </Box>
      ) : (
        <>
          <Box overflowX="auto" borderRadius="md" boxShadow="md">
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                <Th width="20px">
                    {' '}
                    <Checkbox
                      colorScheme="blue"
                      isChecked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </Th>
                  <Th>Admin</Th>
                  <Th>Titre</Th>
                  <Th>Msg</Th>
                  <Th>Cat.</Th>
                  <Th>Statut</Th>
                  <Th>Audience</Th>
                  <Th>Envoi</Th>
                  <Th>Prog.</Th>
                  <Th>Réc.</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedNotifications.map(notif => {
                  const capitalizeFirstLetter = string => {
                    // Handle empty or undefined strings
                    return string.charAt(0).toUpperCase() + string.slice(1);
                  };

                  const user = users[notif.createdBy] || {};
                  const formattedSentAt = notif.sentAt
                    ? new Date(notif.sentAt).toLocaleDateString()
                    : 'Not Sent';
                  const formattedSchedule = notif.scheduleDate
                    ? new Date(notif.scheduleDate).toLocaleDateString()
                    : 'No Schedule';

                  return (
                    <Tr key={notif._id}>
                          <Td display={{ base: 'none', md: 'table-cell' }}>
                        <Checkbox
                          colorScheme="blue"
                          isChecked={selectedNotifications.includes(notif._id)}
                          onChange={() => handleSelectNotification(notif._id)}
                        />
                      </Td>
                      <Td>{user?.firstName || 'N/A'}</Td>
                      <Td isTruncated maxWidth="100px">
                        {notif.title || 'No Title'}
                      </Td>
                      <Td isTruncated maxWidth="150px">
                        {notif.message || 'No Message'}
                      </Td>
                      <Td>
                        {capitalizeFirstLetter(notif.category) || 'No Category'}
                      </Td>
                      <Td>
                        {capitalizeFirstLetter(notif.status) || 'No Status'}
                      </Td>
                      <Td>
                        {capitalizeFirstLetter(notif.targetAudience.type) ||
                          'No Audience'}
                      </Td>
                      <Td>{formattedSentAt}</Td>
                      <Td>{formattedSchedule}</Td>
                      <Td>
                        {capitalizeFirstLetter(notif.recurrence?.type) ||
                          'No Recurrence'}
                      </Td>
                      <Td>
                        <Menu>
                          <Tooltip label="Actions" placement="top">
                            <MenuButton
                              as={IconButton}
                              icon={<FiMoreVertical />}
                              variant="action"
                              aria-label="Options"
                            />
                          </Tooltip>
                          <MenuList>
                            <MenuItem  icon={<FiEdit />}>Update</MenuItem>
                            <MenuItem icon={<FiTrash />}
                              onClick={() => handleDelete(notif._id)}
                            
                            >Delete</MenuItem>
                            <MenuItem icon={<FiRepeat />}>
                              Delete Recurrence
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <HStack justifyContent="center" mt={4}>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                colorScheme={index + 1 === currentPage ? 'brand' : 'gray'}
              >
                {index + 1}
              </Button>
            ))}
          </HStack>
        </>
      )}
    </Box>
  );
};

export default History;
