import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import './NFT.css';
import moment from 'moment';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Avatar,
} from '@chakra-ui/react';

// Custom components
import Banner from 'views/shop/components/Banner';
import ShopInfoCard from 'views/shop/components/ShopInfoCard';
import { useParams } from 'react-router-dom';
import NFT from 'components/card/NFT';
import Card from 'components/card/Card.js';
import Rating from 'react-rating-stars-component';

// Assets
import HistoryItem from 'views/ads/recentAds/components/HistoryItem';

import Nft3 from 'assets/img/nfts/Nft3.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import { t } from 'helpers/TransWrapper';
import { useColorMode } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
import { createSelector } from 'reselect';
import { MdWorkspacePremium, MdStars } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { addAds } from '../../state/auth/authSlice';
import banner from 'assets/img/shopBanner.png';
import { SearchIcon } from '@chakra-ui/icons';

export default function Shop() {
  // Chakra Color Mode
  const { name } = useParams();
  const [shop, setShop] = useState(null);
  const [owner, setOwner] = useState(null);
  const [shopAds, setShopAds] = useState(null);
  const [filteredShopAds, setFilteredShopAds] = useState(null);
  const dispatch = useDispatch();

  const TestAds = state => state.auth.Test;
  // const TestAds = useSelector((state) => state.Test);
  const [likers, setLikers] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const [likedAds, setLikedAds] = useState([]);
  const premium = useColorModeValue('#6f432a', 'gold');
  const liked = useColorModeValue('#960018', 'gold');
  const [adsCount, setAdsCount] = useState();
  const [adData, setAdData] = useState([]);
  const [like, setLike] = useState();
  const [SelectedAd, setSelectedAd] = useState([]);
  const history = useHistory();
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [isLoading, setIsLoading] = useState(false);
  const NFTMemoized = React.memo(NFT);
  const [user, setUser] = useState({});
  const [reviewsWithUsers, setReviewsWithUsers] = useState([]);
  const [reviews, setReviews] = useState([]);

  const [promotedAds, setPromotedAds] = useState([]);
  function revertFormattedName(originalName) {
    // Replace dashes with spaces
    const revertedName = originalName.replace(/-/g, ' ');

    return revertedName;
  }
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const shopName = revertFormattedName(name);
  const processedTestAds = createSelector([TestAds], testAds => {
    return testAds;
  });
  const selectProcessedTestAds = useSelector(processedTestAds);
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  console.log(JSON.stringify(adData)); // JSON.stringify
  const getLikers = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${id}/like`
      );

      const likerIds = response.data;

      const likerData = [];

      for (const likerId of likerIds) {
        const userDataResponse = await axios.get(
          `${process.env.REACT_APP_API}/users/${likerId}`
        );
        const userData = userDataResponse.data;
        likerData.push(userData);

        console.log('User Data:', userData); // Log the user data for debugging
      }

      const profilePictures = likerData.map(liker => liker.profilePicture);

      console.log('Profile Pictures:', profilePictures); // Log profile pictures for debugging

      return profilePictures;
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  useEffect(() => {
    const likedAds = async () => {
      try {
        // Fetch ads and user data in a single request
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/home/likedAds`
        );
        const likedAds = response.data;

        setLikedAds(likedAds);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    likedAds();
  }, []);

  const tags = ['Fashion', 'Accessories', 'Sale', 'New Arrivals', 'Women'];

  const PromotedAdsWrapper = ({ ad, user }) => {
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <HistoryItem
        id={ad._id}
        name={ad.name}
        click={handleClick}
        author={user.firstName + ' ' + user.lastName}
        date={moment(ad.createdAt).fromNow()}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        price={ad.price === 0 ? t('Non défini') : ad.price + t(` MAD`)}
      />
    );
  };
  const NFTWrapper = ({ ad }) => {
    const [likersForAd, setLikersForAd] = useState([]);

    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }

      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <NFT
        id={ad._id}
        discountShop={ad.discountShop}
        isShopPromoted={ad.isShopPromoted}
        promotedPrice={ ad.promotedPrice + t(` MAD`)}
        bidders={likersForAd}
        name={ad.name}
        author={`${user.firstName} ${user.lastName}`}
        image={
          ad.adPictures[0] && Object.keys(ad.adPictures[0]).length
            ? ad.adPictures[0]
            : Nft3
        }
        category={ad.categoryLabel}
        propart={ad?.ProPart}
        currentbid={ad.price === 0 ? t('Non défini') : ad.price + t(` MAD`)}
        Click={handleClick}
        city={ad.city}
        dateCreated={moment(ad.createdAt).fromNow()}
        nbrPictures={ad.adPictures.length + t(` images `)}
      />
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectProcessedTestAds.length < 1) {
        setIsLoading(true);

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/ads/Adsm/home`
          );
          const ads = response.data;

          const adData = await Promise.all(
            ads.map(async ad => {
              const userResponse = await axios.get(
                `${process.env.REACT_APP_API}/users/${ad.userId}`
              );
              const user = userResponse.data;
              return user ? { ad, user } : null;
            })
          );

          const filteredAdData = adData.filter(Boolean);
          dispatch(addAds(filteredAdData));
          setAdData(filteredAdData);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [TestAds.length]);
  const fetchShopByName = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/name/${name}`
      );
      const shopData = response.data;
      console.log('shopData', shopData);
      setShop(shopData);
      setReviews(shopData.reviews);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchShopByName();
  }, [name]); // Assuming 'name' is a dependency that triggers fetching shop data

  const fetchAdsForShop = async shop => {
    try {
      const validAdIds = shop.ads.filter(adId => adId);
      const adPromises = validAdIds.map(async adId => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/ads/ad/${adId}`
          );
          return response.data;
        } catch (error) {
          console.error(`Error fetching ad with ID ${adId}:`, error);
          // Handle specific error for this ad ID fetch failure if needed
          return null; // Return null for the failed fetch
        }
      });

      const ads = await Promise.all(adPromises);
      // 'ads' is an array containing all the fetched ad data for the shop
      const filteredAds = ads.filter(ad => ad !== null);
      const filteredValidAds = filteredAds.filter(
        ad => ad.status === 'Validée'
      );
      setShopAds(filteredValidAds);
      const filteredShopAds = filteredValidAds.filter(ad => ad.isShopPromoted);
      setFilteredShopAds(filteredShopAds);

      console.log('ads', filteredAds);
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  useEffect(() => {
    if (shop) {
      fetchAdsForShop(shop);
    }
  }, [shop]);
  useEffect(() => {
    const fetchOwner = async () => {
      try {
        if (shop && shop.owner) {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/users/${shop.owner}`
          );
          const owner = response.data;
          setOwner(owner);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOwner();
  }, [shop]);
  console.log('ads', shopAds);
  const countAds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/count/ads`
      );
      setAdsCount(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    countAds();
  }, []);

  const getAdById = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/ad/${id}`
      );
      setSelectedAd(response.data);

      history.push(`/ads/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const items = Array.from({ length: 12 });

  const { colorMode } = useColorMode();

  const bgColor = colorMode === 'dark' ? 'rgb(17, 28, 68)' : 'gray.200';
  const positiveReviews = reviews.filter(review => review.rating >= 4);
  const totalReviews = reviews.length;
  const totalPositiveReviews = positiveReviews.length;
  const [searchTerm, setSearchTerm] = useState('');
  const fetchUserDetails = async userId => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${userId}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error fetching user details for user ${userId}:`, error);
      return null;
    }
  };
  const getRatingEmoji = rating => {
    if (rating >= 4) {
      return '😍'; // Emoji for high rating
    } else if (rating >= 3) {
      return '😊'; // Emoji for medium rating
    } else {
      return '😐'; // Emoji for low rating
    }
  };
  const fetchUserDetailsForReviews = async () => {
    const userDetailsPromises = reviews.map(review =>
      fetchUserDetails(review.user)
    );
    const userDetails = await Promise.all(userDetailsPromises);
    return userDetails;
  };
  const loadReviews = async () => {
    const userDetails = await fetchUserDetailsForReviews();
    const reviewsWithUsers = reviews.map((review, index) => ({
      review,
      user: userDetails[index],
    }));
    // Now, set or render the reviewsWithUsers in your component state or JSX
    // For example, you might want to set them in your component state
    setReviewsWithUsers(reviewsWithUsers);
  };
  useEffect(() => {
    loadReviews();
  }, [reviews]);
  // Assuming shopAds is your array of ads

  // Function to filter ads based on search term
  const filteredAds = shopAds?.filter(ad =>
    ad.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Calculate positive reviews percentage, considering the possibility of division by zero
  const positiveReviewsPercentage =
    totalReviews === 0
      ? 'N/A'
      : ((totalPositiveReviews / totalReviews) * 100).toFixed(2);
  console.log('ad', adData);
  return (
    <Box pt={{ base: '100px', md: '80px', xl: '90px' }}>
      {' '}
      {/* Main Fields */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
        gap={{ base: '20px', xl: '20px' }}
        display={{ base: 'block', xl: 'grid' }}
      >
        <Banner
          shopId={shop?._id}
          posts={shopAds?.length}
          promoAds ={filteredShopAds ? filteredShopAds.length : 0}
          avatar={shop?.logo}
          name={shopName}
          userId={shop?.owner}
          banner={banner}
          memberShip={owner?.memberShip}
          positiveReviews={positiveReviewsPercentage}
        />
        <ShopInfoCard
          description={shop?.description}
          phone={shop?.contactInfo.phone}
          email={shop?.contactInfo.email}
          address={shop?.address}
          delivers={shop?.delivery}
          facebook={shop?.contactInfo.socialMedia.facebook}
          instagram={shop?.contactInfo.socialMedia.instagram}
          twitter={shop?.contactInfo.socialMedia.twitter}
          createdAt={shop?.createdAt}
          tags={shop?.tags}
          paymentMethods={shop?.paymentMethods}
        />
        {/* <Flex flexDirection="column">
          <Card p="0px" mb="20px">
            <Flex
              align={{ sm: 'flex-start', lg: 'center' }}
              justify="space-between"
              w="100%"
              px="22px"
              py="18px"
              direction={{ base: 'column', md: 'row' }}
            >
              <Text color={textColor} fontSize="xl" fontWeight="600">
                Les mieux notées{' '}
              </Text>
              <Flex>
                <MdStars color={liked} size={30} />
                <MdStars color={liked} size={30} />
                <MdStars color={liked} size={30} />
              </Flex>
            </Flex>
            <SimpleGrid
              overflowY="auto"
              height="930px"
              columns={{ base: 1, md: 1 }}
              gap="20px"
            >
              {likedAds && likedAds.length > 0
                ? likedAds.map(ad => {
                    const handleClick = () => {
                      getAdById(ad._id); // Get the ad by its id when the component is clicked
                    };

                    return (
                      <Flex key={ad._id}>
                        <HistoryItem
                          id={ad._id}
                          name={ad.name}
                          click={handleClick}
                          author={ad.firstName + ' ' + ad.lastName}
                          date={moment(ad.createdAt).fromNow()}
                          image={
                            ad.adPictures[0] &&
                            Object.keys(ad.adPictures[0]).length
                              ? ad.adPictures[0]
                              : Nft3
                          }
                          price={
                            ad.price === 0 ? t('Non défini') : `${ad.price} MAD`
                          }
                        />
                      </Flex>
                    );
                  })
                : null}
            </SimpleGrid>
          </Card>
        </Flex> */}
        <Flex
          flexDirection="column"
          gridArea={{ xl: '4 / 3 / 1 / 1', '2xl': '1 / 1 / 2 / 2' }}
        >
          <Flex mb={5} direction="column">
            <Card p={1} mb={3}>
              {' '}
              <Flex
                align={{ sm: 'flex-start', lg: 'center' }}
                justify="space-between"
                w="100%"
                px="22px"
                py="18px"
              >
                <Text color={textColor} fontSize="xl" fontWeight="600">
                  {t('Annonces en promotions')} ({filteredShopAds ? filteredShopAds.length : 0})
                </Text>{' '}
              </Flex>{' '}
            </Card>{' '}
            <Flex overflowX="auto" p={4} mb={2}>
              {filteredShopAds?.map(ad => {
                const likersForAd = getLikers(ad._id);

                const handleClick = () => {
                  getAdById(ad._id);
                };

                return (
                  <LazyLoad key={ad._id} height={200} offset={100}>
                    <Flex
                      minWidth="250px" // Set a fixed width for each ad
                      mr={5}
                      key={ad._id}
                      className="nft"
                    >
                      <NFTWrapper ad={ad} />
                    </Flex>
                  </LazyLoad>
                );
              })}
            </Flex>
            <Card p={1} mb={5}>
              <Flex
                align={{ sm: 'flex-start', lg: 'center' }}
                justify="space-between"
                w="100%"
                px="22px"
                py="18px"
              >
                <Text color={textColor} fontSize="xl" fontWeight="600">
                  {t('Ajoutées récemment')} ({shopAds ? shopAds.length : 0})
                </Text>
                {/* Filter input for searching ads */}
                <InputGroup w={{ base: '100%', md: '300px' }}>
                  <InputLeftElement
                    children={
                      <IconButton
                        bg="inherit"
                        borderRadius="inherit"
                        _hover="none"
                        _active={{
                          bg: 'inherit',
                          transform: 'none',
                          borderColor: 'transparent',
                        }}
                        _focus={{
                          boxShadow: 'none',
                        }}
                        icon={
                          <SearchIcon
                            color={searchIconColor}
                            w="15px"
                            h="15px"
                          />
                        }
                      >
                        {' '}
                      </IconButton>
                    }
                  />{' '}
                  <Input
                    variant="search"
                    fontSize="sm"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    bg={inputBg}
                    color={inputText}
                    fontWeight="500"
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    borderRadius="30px"
                    placeholder={t('Rechercher...')}
                  />{' '}
                </InputGroup>
              </Flex>
              {/* Render your ads using the filteredAds array */}
            </Card>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              gap="20px"
              mb={{ base: '20px', xl: '0px' }}
            >
              <>
                {isLoading === false ? (
                  shopAds?.map(ad => {
                    const likersForAd = getLikers(ad._id);
                    console.log('likers', likersForAd);

                    const handleClick = () => {
                      getAdById(ad._id);
                    };

                    return (
                      <LazyLoad height={200} offset={100}>
                        <Flex key={ad._id} className="nft">
                          <NFTWrapper ad={ad} />
                        </Flex>
                      </LazyLoad>
                    );
                  })
                ) : (
                  <>
                    {items.map((_, index) => {
                      return (
                        <Flex key={index} className="nft">
                          <Box borderRadius="lg" overflow="hidden" bg={bgColor}>
                            <Skeleton
                              height={{ base: '200px', md: '200px' }}
                              width="600px"
                              startColor={
                                colorMode === 'dark' ? 'gray.700' : 'gray.200'
                              }
                              endColor={
                                colorMode === 'dark' ? 'gray.600' : 'gray.300'
                              }
                            />
                            {/* <Box height="" width="600px"></Box> */}
                            <Box p="6">
                              <Box
                                mt="1"
                                fontWeight="semibold"
                                as="h4"
                                lineHeight="tight"
                                isTruncated
                                color={textColor}
                              >
                                <Skeleton
                                  height="10px"
                                  width={{ base: '50%', md: '30%' }}
                                  mb={2}
                                  startColor={
                                    colorMode === 'dark'
                                      ? 'gray.700'
                                      : 'gray.200'
                                  }
                                  endColor={
                                    colorMode === 'dark'
                                      ? 'gray.600'
                                      : 'gray.300'
                                  }
                                />
                                <Box
                                  bg={bgColor}
                                  height="20px"
                                  width="50%"
                                  mb={2}
                                ></Box>
                                <Skeleton
                                  height="10px"
                                  width={{ base: '20%', md: '10%' }}
                                  mr={2}
                                  startColor={
                                    colorMode === 'dark'
                                      ? 'gray.700'
                                      : 'gray.200'
                                  }
                                  endColor={
                                    colorMode === 'dark'
                                      ? 'gray.600'
                                      : 'gray.300'
                                  }
                                />
                              </Box>
                              <Box
                                d="flex"
                                alignItems="baseline"
                                color={textColor}
                                fontSize="sm"
                              >
                                <Box
                                  bg={bgColor}
                                  height="10px"
                                  width="20%"
                                  mr={2}
                                ></Box>
                                <Box
                                  bg={bgColor}
                                  height="10px"
                                  width="30%"
                                  mr={2}
                                ></Box>
                              </Box>
                            </Box>
                          </Box>
                        </Flex>
                      );
                    })}
                  </>
                )}
              </>
            </SimpleGrid>{' '}
          </Flex>{' '}
          <Card mb="10px" shadow="lg" maxHeight="400px">
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="15px"
            >
              {t(`Liste des avis`)}
            </Text>

            <Box overflowY="auto" maxHeight="400px">
              {reviewsWithUsers.map(({ review, user }) => (
                <Card
                  shadow="lg"
                  borderColor="gray.300"
                  mb="10px"
                  key={review._id}
                >
                  <Flex w="100%" align="start" justify="space-between" mb={2}>
                    <Flex align="start">
                      <Avatar size="md" src={user.profilePicture} />
                      <Box ml={4}>
                        <Text>{`${user.firstName} ${user.lastName}`}</Text>
                        <Text fontSize="sm" color="gray.500">
                          {moment(review.createdAt).format('MMM D, YYYY')}
                        </Text>
                      </Box>
                    </Flex>

                    <Box>
                      <Flex align="center">
                        <Rating
                          count={5}
                          value={review.rating}
                          size={25}
                          edit={false}
                          activeColor="#F9B710"
                        />
                        <Box mt={1} fontSize="20px" ml="1">
                          {getRatingEmoji(review.rating)}
                        </Box>
                      </Flex>
                    </Box>
                    {/* IconButton and Tooltip for sending email */}
                  </Flex>
                  <Text>{review.comment}</Text>
                </Card>
              ))}
            </Box>
          </Card>
        </Flex>{' '}
      </Grid>{' '}
    </Box>
  );
}
