import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Text,
  Skeleton,
  useColorModeValue,
  Divider,
  Stack,
} from '@chakra-ui/react';
import { Line } from 'react-chartjs-2';
import ColumnChart from 'components/charts/BarChart';

const ShopAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cardBg = useColorModeValue('#ffffff', '#7551FF');
  const BarBg = useColorModeValue('#7551FF', '#ffffff');
  const cardShadow = useColorModeValue('lg', 'lg');
  const tooltipColor = useColorModeValue('light', 'dark');
  const fetchAnalyticsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/get/analytics`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setAnalyticsData(response.data);
    } catch (error) {
      setError('Error fetching analytics data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalyticsData();
  }, []);

  if (loading) {
    return (
      <Container maxW="container.lg" mt={8}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          {[...Array(6)].map((_, idx) => (
            <Skeleton key={idx} height="100px" borderRadius="md" />
          ))}
        </SimpleGrid>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxW="container.lg" mt={8}>
        <Text color="red.500" textAlign="center">
          {error}
        </Text>
      </Container>
    );
  }

  const {
    totalShops,
    visibleShops,
    privateShops,
    shopPlanDistributionWithMembership,
    salesData,
    numberOfAds,
    numberOfPromotions,
    totalViews,
  } = analyticsData;

  const salesChartData = {
    labels: salesData.map(data => new Date(data._id).toLocaleDateString()),
    datasets: [
      {
        label: 'Total Revenue',
        data: salesData.map(data => data.totalRevenue),
        fill: false,
        borderColor: 'rgba(66, 42, 251, 0.7)',
        tension: 0.3,
        borderWidth: 2,
        pointRadius: 4,
        backgroundColor: 'rgba(66, 42, 251, 0.2)',
      },
    ],
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Heading as="h1" size="xl" mb={6} textAlign="center" color={BarBg}>
        Shop Analytics
      </Heading>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} mb={8}>
        {[
          {
            label: 'Total Shops',
            value: totalShops,
            help: 'Shops in the system',
          },
          {
            label: 'Visible Shops',
            value: visibleShops,
            help: 'Shops with public visibility',
          },
          {
            label: 'Private Shops',
            value: privateShops,
            help: 'Shops with private visibility',
          },
          {
            label: 'Number of Ads',
            value: numberOfAds,
            help: 'Active ads in the system',
          },
          {
            label: 'Promotions',
            value: numberOfPromotions,
            help: 'Active promotions',
          },
          {
            label: 'Total Views',
            value: totalViews,
            help: 'Overall shop views',
          },
        ].map((stat, idx) => (
          <Stat
            key={idx}
            p={5}
            borderRadius="lg"
            bg={cardBg}
            boxShadow={cardShadow}
            transition="transform 0.2s"
            _hover={{ transform: 'scale(1.05)' }}
          >
            <StatLabel>{stat.label}</StatLabel>
            <StatNumber fontSize="2xl">{stat.value}</StatNumber>
            <StatHelpText>{stat.help}</StatHelpText>
          </Stat>
        ))}
      </SimpleGrid>

      {salesData.length > 0 && (
        <Box mb={8} bg={cardBg} p={5} borderRadius="lg" boxShadow={cardShadow}>
          <Heading as="h2" size="md" mb={4}>
            Sales Data
          </Heading>
          <Line
            data={salesChartData}
            options={{
              responsive: true,
              plugins: {
                legend: { display: true, position: 'top' },
                title: { display: false },
              },
            }}
          />
        </Box>
      )}

      <Box bg={cardBg} p={5} borderRadius="lg" boxShadow={cardShadow}>
        <Heading as="h2" size="md" mb={4}>
          Shop Plan Distribution
        </Heading>
        <ColumnChart
          chartData={[
            {
              name: 'Number of Shops',
              data: shopPlanDistributionWithMembership.map(item => item.count),
            },
          ]}
          chartOptions={{
            chart: {
              id: 'plan-distribution',
              type: 'bar',
            },
            colors: [BarBg],
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '50%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 0,
            },
            tooltip: {
              theme: tooltipColor, // Adjust tooltip theme if needed
            },
            xaxis: {
              categories: shopPlanDistributionWithMembership.map(
                item => item.plan
              ),
              labels: {
                style: {
                  colors: BarBg,
                  fontSize: '14px',
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: BarBg,
                  fontSize: '14px',
                },
              },
            },
          }}
        />
      </Box>
    </Container>
  );
};

export default ShopAnalytics;
