/*eslint-disable*/
import React from 'react';
import ReactStoreBadges from 'react-store-badges';
import StoreBadge from 'react-store-badge';

import {
  Box,
  Image,
  Flex,
  Link,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
  Divider,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import {
  MdScreenSearchDesktop,
  MdOutlineAddToQueue,
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdFacebook,
  MdOutlineEmail,
} from 'react-icons/md';

import {
  BsGithub,
  BsDiscord,
  BsPerson,
  BsInstagram,
  BsLinkedin,
  BsYoutube,
  BsTwitter,
} from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import Brand from '../../components/sidebar/components/Brand';
import Links from '../../components/sidebar/components/Links';
import { t } from 'helpers/TransWrapper';
import { AppStoreBadge, GooglePlayBadge } from 'react-app-store-badge';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import apple from '../../assets/img/apple.png';
import google from '../../assets/img/google.png';
import box from '../../assets/img/box.png';

export default function Footer(props) {
  const bg = useColorModeValue('gray.150', 'navy.900');
  const link = useColorModeValue('brand.600', 'white');
  const { routes } = props;

  const textColor = useColorModeValue('gray.400', 'white');
  const { toggleColorMode } = useColorMode();
  return (
    <footer>
      <VStack spacing={2} ml={1}>
        <SimpleGrid
          columns={[1, 1, 1, 4]}
          w="full"
          gap={8}
          justifyContent="space-between"
        >
          <VStack align="center">
            <Brand />
            <Box maxW="300px">
              <Text maxW={250} textAlign="left">
                {t(
                  'JINDOO votre Marketplace 100% digitale Toutes vos transactions en toute sécurité et sans Intermédiaire'
                )}
              </Text>
            </Box>
            <HStack
              mt={2}
              mb={5}
              spacing={1}
              px={3}
              alignItems="center"
              justify="center"
              flexWrap="wrap"
            >
              <Link target="_blank" href="https://facebook.com/jumatikads">
                <IconButton
                  aria-label="facebook"
                  variant="action"
                  size="md"
                  isRound={true}
                  icon={<MdFacebook size="25px" />}
                />
              </Link>
              <Link target="_blank" href="https://instagram.com/jumatikmaroc">
                <IconButton
                  aria-label="Instagram"
                  variant="action"
                  size="md"
                  isRound={true}
                  icon={<BsInstagram size="22px" />}
                />
              </Link>
              <IconButton
                aria-label="Linkedin"
                variant="action"
                size="md"
                isRound={true}
                icon={<BsLinkedin size="22px" />}
              />
              <Link
                target="_blank"
                href="https://www.youtube.com/@jumatikmaroc3885"
              >
                <IconButton
                  aria-label="Youtube"
                  variant="action"
                  size="md"
                  isRound={true}
                  icon={<BsYoutube size="22px" />}
                />
              </Link>
              <Link target="_blank" href="https://twitter.com/JumatikMaroc">
                <IconButton
                  aria-label="Twitter"
                  variant="action"
                  size="md"
                  isRound={true}
                  icon={<BsTwitter size="22px" />}
                />
              </Link>
              <IconButton
                aria-label="Twitter"
                variant="action"
                size="md"
                isRound={true}
                icon={<FaTiktok size="22px" />}
              />
            </HStack>
          </VStack>
          <VStack align="left">
            <Heading size="sm" textTransform="uppercase">
              {t('Sitemap')}
            </Heading>
            <Links routes={routes} />
          </VStack>
          <VStack align="left">
            <Heading size="sm" textTransform="uppercase">
              {t('Infos Légales')}
            </Heading>
            <Link
              fontWeight="500"
              color={textColor}
              href="mailto:contact@jumatik.ma"
            >
              {t('Support')}
            </Link>
            <Link
              fontWeight="500"
              color={textColor}
              href="/infos/licence"
              target="_blank"
            >
              {t('Conditions générales de vente')}
            </Link>
            <Link
              fontWeight="500"
              color={textColor}
              href="/infos/termofuse"
              target="_blank"
            >
              {t(`Termes d'utilisation`)}
            </Link>
            <Link
              fontWeight="500"
              color={textColor}
              href="/infos/mentions"
              target="_blank"
            >
              {t(`Mentions Légales`)}
            </Link>
            <Link
              fontWeight="500"
              color={textColor}
              href="/infos/contact"
              target="_blank"
            >
              {t(`Contact`)}
            </Link>
          </VStack>
          <VStack align="center">
            <Heading size="sm" textTransform="uppercase">
              {t(`Application mobile`)}
            </Heading>
            <Link
              href="https://play.google.com/store/apps/details?id=com.marocapps.jumatik.sellandbuy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={google} // Replace with your App Store badge image URL
                alt="App Store"
                width={137} // Adjust the width as needed
                height="60px" // Adjust the height as needed
              />{' '}
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.marocapps.jumatik.sellandbuy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={apple} // Replace with your App Store badge image URL
                alt="App Store"
                width={120} // Adjust the width as needed
                height={10} // Adjust the height as needed
              />{' '}
            </Link>
          </VStack>
        </SimpleGrid>
        <Divider mx="auto" />
        <Flex w="100%" justify="space-between">
          <Text
            color={textColor}
            textAlign={{
              base: 'center',
              xl: 'start',
            }}
            ml={2}
            mt={2}
          >
            &copy; {1900 + new Date().getYear()} JINDOO. All Rights Reserved.
            Powered by
            <Link
              mx="3px"
              color={link}
              href="https://benifit.io"
              target="_blank"
              fontWeight="700"
            >
              Benifit.io
            </Link>
          </Text>
          <Image
            mr={2}
            src={box}
            width={400} // Adjust the width as needed
            height="40px"
          />
        </Flex>
      </VStack>
    </footer>
  );
}
