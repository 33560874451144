import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Text,
  Spinner,
  Input,
  Button,
  HStack,
  useColorModeValue,
  InputLeftElement,
  InputGroup,
  Checkbox
} from '@chakra-ui/react';
import { FiMoreVertical, FiEdit, FiTrash, FiRepeat } from 'react-icons/fi';
import { SearchIcon } from '@chakra-ui/icons';
import Swal from 'sweetalert2';
import {

    useHistory,
  } from 'react-router-dom';
const ListShops = () => {
    const history = useHistory();

  const [shops, setShops] = useState([]);
  const [filteredShops, setFilteredShops] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const brand = useColorModeValue('brand.500', 'pink.200');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgItem = useColorModeValue('white', 'navy.700');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedShops, setSelectedShops] = useState([]);
  const itemsPerPage = 10;
  const fetchShops = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      let data = response.data;
      data = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setShops(data);
      setFilteredShops(data);

      const userPromises = data.map(shop =>
        fetchUser(shop.owner).then(user => ({ [shop.owner]: user }))
      );

      const usersData = await Promise.all(userPromises);
      const usersObject = usersData.reduce(
        (acc, user) => ({ ...acc, ...user }),
        {}
      );
      setUsers(usersObject);
    } catch (error) {
      console.error('Error fetching shops:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchUser = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${id}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching user:', error);
      return null;
    }
  };
  const deleteShop = async id => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/shops/shop/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      fetchShops();
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectShop = ShopId => {
    setSelectedShops(prevSelectedShops => {
      if (prevSelectedShops.includes(ShopId)) {
        return prevSelectedShops.filter(id => id !== ShopId);
      } else {
        return [...prevSelectedShops, ShopId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedShops(selectAll ? [] : paginatedShops.map(shop => shop._id));
  };

  const handleDeleteSelectedShops = async () => {
    if (selectedShops.length === 0) return;

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await Promise.all(
            selectedShops.map(async ShopId => {
              await deleteShop(ShopId);
            })
          );

          Swal.fire(
            'Supprimée !',
            'Vos shops sélectionnées ont été supprimées.',
            'success'
          );
        } catch (error) {
          console.error(error);
          Swal.fire(
            'Erreur',
            "Une erreur s'est produite lors de la suppression des shops.",
            'error'
          );
        }

        setSelectedShops([]);
      }
    });
  };
  const handleDelete = id => {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(result => {
      if (result.isConfirmed) {
        deleteShop(id);
        Swal.fire('Supprimée !', 'Votre shop a été supprimé.', 'success');
      }
    });
  };
  const handleView = name => {
    history.push(`/shop/shop/${name}}`)
  };
  useEffect(() => {
    

    fetchShops();
  }, []);

  const handleSearch = event => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = shops.filter(shop =>
      shop.name?.toLowerCase().includes(value)
    );
    setFilteredShops(filtered);
    setCurrentPage(1); // Reset to the first page
  };

  const totalPages = Math.ceil(filteredShops.length / itemsPerPage);
  const paginatedShops = filteredShops.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  const handlePageChange = page => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={10}>
        <Spinner size="lg" />
        <Text mt={4} fontSize="lg" fontWeight="semibold">
          Loading shops...
        </Text>
      </Box>
    );
  }

  return (
    <Box p={6} minH="100vh">
      <HStack justifyContent="space-between" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">
          Liste des Boutiques JINDOO
        </Text>

        <InputGroup width="300px">
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input
            variant="search"
            fontSize="sm"
            value={searchTerm}
            onChange={handleSearch}
            bg={inputBg}
            color={inputText}
            fontWeight="500"
            _placeholder={{ color: 'gray.400', fontSize: '14px' }}
            borderRadius="30px"
            placeholder="Rechercher par Titre..."
          />
        </InputGroup>
     
      </HStack>
      {selectedShops.length > 0 && (
        <Box mb={4} mt={4} p={4} borderRadius="md" bg={bgItem} shadow="lg">
          <Flex justify="space-between" align="center">
            <Text color="red" fontWeight="bold">
              {selectedShops.length} shops
              {selectedShops.length > 1 ? 's' : ''} sélectionnée
              {selectedShops.length > 1 ? 's' : ''}
            </Text>
            <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteSelectedShops}
            >
              Supprimer
            </Button>
          </Flex>
        </Box>
      )}
      {filteredShops.length === 0 ? (
        <Box textAlign="center" mt={10}>
          <Text fontSize="lg" fontWeight="semibold">
            No shops available.
          </Text>
        </Box>
      ) : (
        <>
          <Box overflowX="auto" borderRadius="md" boxShadow="md">
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                <Th width="20px">
                    {' '}
                    <Checkbox
                      colorScheme="blue"
                      isChecked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </Th>
                  <Th>Name</Th>
                  <Th>Owner</Th>
                  <Th>Plan</Th>
                  <Th>nbr ads</Th>
                  <Th>visibility</Th>
                  <Th>Billing period</Th>
                  <Th>Expiration date</Th>
                  <Th>Creation date</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedShops.map(shop => {
                  const capitalizeFirstLetter = string => {
                    // Handle empty or undefined strings
                    return string.charAt(0).toUpperCase() + string.slice(1);
                  };

                  const user = users[shop.owner] || {};
                  

                  return (
                    <Tr key={shop._id}>
                          <Td display={{ base: 'none', md: 'table-cell' }}>
                        <Checkbox
                          colorScheme="blue"
                          isChecked={selectedShops.includes(shop._id)}
                          onChange={() => handleSelectShop(shop._id)}
                        />
                      </Td>
                      <Td isTruncated maxWidth="100px">{shop?.name || 'N/A'}</Td>
                      <Td isTruncated maxWidth="100px">
                        {user.firstName + ' ' + user.lastName || 'No name'}
                      </Td>
                      <Td isTruncated maxWidth="100px">
                        {user.memberShip}
                      </Td>
                      <Td isTruncated maxWidth="150px">
                        {shop.ads.length || 'No ads'}
                      </Td>
                      <Td>
                        {capitalizeFirstLetter(shop.visibility) || 'No visibility'}
                      </Td>
                      <Td>
                        {user.billingCycle + ' Months ' || 'No visibility'}
                      </Td>
                      <Td>
                        {new Date(user?.memberShipPeriod?.endDate).toLocaleDateString()}
                      </Td>
                      <Td>
                        {new Date(shop.createdAt).toLocaleDateString() }
                      </Td>
                    
                      <Td>
                        <Menu>
                          <Tooltip label="Actions" placement="top">
                            <MenuButton
                              as={IconButton}
                              icon={<FiMoreVertical />}
                              variant="action"
                              aria-label="Options"
                            />
                          </Tooltip>
                          <MenuList>
                            <MenuItem   onClick={() => handleView(shop.name)} icon={<FiEdit />}>Voir</MenuItem>
                            
                            <MenuItem icon={<FiTrash />}
                              onClick={() => handleDelete(shop._id)}
                            
                            >Supprimer</MenuItem>
                        
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <HStack justifyContent="center" mt={4}>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                colorScheme={index + 1 === currentPage ? 'brand' : 'gray'}
              >
                {index + 1}
              </Button>
            ))}
          </HStack>
        </>
      )}
    </Box>
  );
};

export default ListShops;
