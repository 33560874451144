import { Text, Flex, Box, Tab, TabList, Tabs } from '@chakra-ui/react';
import React, { useState } from 'react';
import Card from 'components/card/Card.js';
import { MdCalendarMonth,MdShoppingBag, MdAnalytics } from 'react-icons/md';
import ListTransactions from '../variables/ListTransactions';
import ShopAnalytics from '../variables/ShopAnalytics';


export default function PaymentMethods() {
  const [selectedTab, setSelectedTab] = useState('history'); // Default is 'all', can be changed to 'published', 'draft', or 'validation'
  const handleStatusChange = status => {
    setSelectedTab(status);
  };
  return (
    <Card mb={{ base: '0px', '2xl': '20px' }}>
      <Box>
        <Flex w="100%" justify="space-between">
          <Tabs colorScheme="purple" variant="enclosed" flex="1">
            <TabList mb={{ base: '4px', md: '0' }}>
              <Tab
                fontSize="sm"
                fontWeight={selectedTab === 'history' ? 'bold' : 'normal'}
                onClick={() => handleStatusChange('history')}
                isSelected={selectedTab === 'history'}
              >
                <MdCalendarMonth size={20} />
                <Text ml={1}>Listes des Transactions</Text>
              </Tab>
    
              <Tab
                fontSize="sm"
                fontWeight={selectedTab === 'analytics' ? 'bold' : 'normal'}
                onClick={() => handleStatusChange('analytics')}
                isSelected={selectedTab === 'analytics'}
              >
                <MdAnalytics size={20} />
                <Text ml={1}>Analyses et stats</Text>
              </Tab>
            </TabList>
          </Tabs>
        </Flex>
      </Box>

      {selectedTab === 'history' && <ListTransactions />}

      

      {selectedTab === 'analytics' && <ShopAnalytics />}
    </Card>
  );
}
