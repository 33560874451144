import {
  Box,
  Flex,
  Text,
  Select,
  Button,
  Input,
  Textarea,
  useColorModeValue,
  Stack,
  Switch,
  Icon,
  Image,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Jindoo from 'assets/img/jindoo icon.png';
import { MdNotifications, MdSchedule, MdSend, MdSave } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// Import the styles for the toast
export default function CreateNotif(props) {
  const { ...rest } = props;
  const user = localStorage.getItem('user-token');
  const CurrentUser = JSON.parse(user);

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const cardColor = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );

  // State variables
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [targetAudience, setTargetAudience] = useState('all');
  const [schedule, setSchedule] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [platform, setPlatform] = useState('all');
  const [category, setCategory] = useState('alert'); // New state for category
  const [recurrence, setRecurrence] = useState('none'); // New state for recurrence
  const [usersToken, setUsersTokens] = useState([]);
  const [pushNotif, setPushNotif] = useState({});
  const [audiences, setAudiences] = useState([]);

  // Handlers
  const fetchDeviceTokens = async () => {
    try {
      // Make the API call to your backend to fetch users with device tokens
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/get/tokens`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Pass the token if needed
          },
        }
      );

      if (response.data.deviceTokens) {
        // Set the fetched tokens to state
        setUsersTokens(response.data.deviceTokens);
      }
    } catch (error) {
      console.error('Error fetching device tokens:', error.message);
    }
  };
  const fetchAudiences = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/audiences`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setAudiences(response.data.audiences);
    } catch (error) {
      toast.error('Failed to fetch audiences.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error fetching audiences:', error);
    }
  };
  useEffect(() => {
    fetchAudiences();
  }, []);

  // Fetch device tokens when the component is mounted
  useEffect(() => {
    fetchDeviceTokens();
  }, []);
  const handleSaveDraft = async () => {
    const notificationDetails = {
      title,
      message,
      targetAudience,
      platform,
      category,
      schedule,
      scheduleDate: schedule ? scheduleDate : null,
      recurrence,
      createdBy: CurrentUser._id,
      createdAt: new Date(),
      status: 'draft', // Set initial status as draft
      deliveryReport: {
        success: 0, // Start with 0 success
        failed: 0, // Start with 0 failed
        logs: [], // Start with empty logs
      },
    };
    let createdNotification;
    // Step 1: Create the notification in the backend
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/pushNotifications/create`,
        notificationDetails,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data.success) {
        toast.success('Notification created as Draft');
        createdNotification = response.data.data; // This will hold the created notification's details
        setPushNotif(createdNotification);

        handleClearForm();
        console.log('Notification created in backend:', createdNotification);
      } else {
        toast.error(
          'Failed to create notification in backend: ' + response.data.message
        );
        console.error(
          'Failed to create notification in backend:',
          response.data.message
        );
        return; // Exit early if creation fails
      }
    } catch (error) {
      toast.error('Error creating notification in backend: ' + error.message);
      console.error('Error creating notification in backend:', error.message);
      return;
    }
  };

  const handleClearForm = () => {
    setTitle('');
    setMessage('');
    setTargetAudience('all');
    setSchedule(false);
    setScheduleDate(new Date());
    setPlatform('all');
    setCategory('alert'); // Reset category
  };

  const handleSendNotification = async () => {
    const notificationDetails = {
      title,
      message,
      targetAudience,
      platform,
      category,
      schedule,
      scheduleDate: schedule ? scheduleDate : null,
      recurrence,
      createdBy: CurrentUser._id,
      createdAt: new Date(),
      status: 'draft', // Set initial status as draft
      deliveryReport: {
        success: 0, // Start with 0 success
        failed: 0, // Start with 0 failed
        logs: [], // Start with empty logs
      },
    };
    let createdNotification;
    console.log('notificationDetails',notificationDetails);
    // Step 1: Create the notification in the backend
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/pushNotifications/create`,
        notificationDetails,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data.success) {
        toast.success('Notification created in backend');
        createdNotification = response.data.data; // This will hold the created notification's details
        setPushNotif(createdNotification);

        handleClearForm();
        console.log('Notification created in backend:', createdNotification);
      } else {
        toast.error(
          'Failed to create notification in backend: ' + response.data.message
        );
        console.error(
          'Failed to create notification in backend:',
          response.data.message
        );
        return; // Exit early if creation fails
      }
    } catch (error) {
      toast.error('Error creating notification in backend: ' + error.message);
      console.error('Error creating notification in backend:', error.message);
      return;
    }

    if (targetAudience === 'all') {
      // Send notifications to all tokens
      if (schedule && scheduleDate) {
        // Schedule the notification to be sent at the specific time
        await updateDeliveryReport(
          createdNotification._id, // Use the ID of the created notification
          0,
          0,
          [],
          null,
          'scheduled' // Set the status to 'sent' after sending
        );

        scheduleNotification(scheduleDate, createdNotification, recurrence);
        return; // Exit early and don't send immediately
      }
      const notificationData = usersToken.map(token => ({
        fcmToken: token,
        ...notificationDetails, // Include all the notification details for FCM
      }));

      try {
        const fcmPromises = notificationData.map(notification =>
          axios
            .post(`${process.env.REACT_APP_API}/notifications/fcm/send`, {
              title: notification.category + ': ' + notification.title,
              body: notification.message,
              type: notification.category,
              token: notification.fcmToken,
            })
            .then(result => {
              if (result.status === 200) {
                return { success: true, token: notification.fcmToken };
              } else {
                return {
                  success: false,
                  token: notification.fcmToken,
                  error: `Failed with status: ${result.status}`,
                };
              }
            })
            .catch(error => {
              return {
                success: false,
                token: notification.fcmToken,
                error: error.message,
              };
            })
        );

        // Wait for all the FCM promises to resolve
        const fcmResults = await Promise.all(fcmPromises);
        console.log('FCM results:', JSON.stringify(fcmResults));

        // Step 3: Initialize success and failure counters and logs
        let successCount = 0;
        let failedCount = 0;
        const logs = [];

        fcmResults.forEach(result => {
          if (result.success) {
            successCount++;
            logs.push(
              `Notification sent successfully to token: ${result.token}`
            );
          } else {
            failedCount++;
            logs.push(
              `Failed to send notification to token: ${result.token} - Error: ${result.error}`
            );
          }
        });

        // Step 4: Update the delivery report in the backend with success and failure counts
        const updateResponse = await updateDeliveryReport(
          createdNotification._id, // Use the ID of the created notification
          successCount,
          failedCount,
          logs,
          new Date(),
          'sent' // Set the status to 'sent' after sending
        );

        if (updateResponse.data.success) {
          toast.success(
            `Notification sent! ${successCount} sent, ${failedCount} failed.`
          );
        } else {
          toast.error('Failed to update notification in backend.');
        }
      } catch (error) {
        toast.error('Error sending notifications: ' + error.message);
        console.error('Error sending notifications:', error.message);
      }
    } else {
      // Send notifications to specific tokens
      if (schedule && scheduleDate) {
        // Schedule the notification to be sent at the specific time
        await updateDeliveryReport(
          createdNotification._id, // Use the ID of the created notification
          0,
          0,
          [],
          null,
          'scheduled' // Set the status to 'sent' after sending
        );

        scheduleNotification(scheduleDate, createdNotification, recurrence);
        return; // Exit early and don't send immediately
      }
      const notificationData = targetAudience?.users?.map(user => ({
        fcmToken: user.deviceToken,
        ...notificationDetails, // Include all the notification details for FCM
      }));

      try {
        const fcmPromises = notificationData.map(notification =>
          axios
            .post(`${process.env.REACT_APP_API}/notifications/fcm/send`, {
              title: notification.category + ': ' + notification.title,
              body: notification.message,
              type: notification.category,
              token: notification.fcmToken,
            })
            .then(result => {
              if (result.status === 200) {
                return { success: true, token: notification.fcmToken };
              } else {
                return {
                  success: false,
                  token: notification.fcmToken,
                  error: `Failed with status: ${result.status}`,
                };
              }
            })
            .catch(error => {
              return {
                success: false,
                token: notification.fcmToken,
                error: error.message,
              };
            })
        );

        // Wait for all the FCM promises to resolve
        const fcmResults = await Promise.all(fcmPromises);
        console.log('FCM results:', JSON.stringify(fcmResults));

        // Step 3: Initialize success and failure counters and logs
        let successCount = 0;
        let failedCount = 0;
        const logs = [];

        fcmResults.forEach(result => {
          if (result.success) {
            successCount++;
            logs.push(
              `Notification sent successfully to token: ${result.token}`
            );
          } else {
            failedCount++;
            logs.push(
              `Failed to send notification to token: ${result.token} - Error: ${result.error}`
            );
          }
        });

        // Step 4: Update the delivery report in the backend with success and failure counts
        const updateResponse = await updateDeliveryReport(
          createdNotification._id, // Use the ID of the created notification
          successCount,
          failedCount,
          logs,
          new Date(),
          'sent' // Set the status to 'sent' after sending
        );

        if (updateResponse.data.success) {
          toast.success(
            `Notification sent! ${successCount} sent, ${failedCount} failed.`
          );
        } else {
          toast.error('Failed to update notification in backend.');
        }
      } catch (error) {
        toast.error('Error sending notifications: ' + error.message);
        console.error('Error sending notifications:', error.message);
      }
    }
  };
  const scheduleNotification = async (
    scheduleDate,
    createdNotification,
    recurrence
  ) => {
    const now = new Date();
    let delay = scheduleDate.getTime() - now.getTime();

    if (delay <= 0) {
      toast.error('Scheduled time must be in the future.');
      return; // Ensure the scheduled date is in the future
    } else {
      toast.info(
        `Notification scheduled for : ${new Date(
          scheduleDate
        )} and will be sent :${recurrence == 'none' ? 'one time' : recurrence}`
      );
    }

    // Set the recurrence interval based on the selected option
    let recurrenceInterval = null;
    switch (recurrence) {
      case 'daily':
        recurrenceInterval = 24 * 60 * 60 * 1000; // 24 hours
        break;
      case 'weekly':
        recurrenceInterval = 7 * 24 * 60 * 60 * 1000; // 7 days
        break;
      case 'monthly':
        recurrenceInterval = 30 * 24 * 60 * 60 * 1000; // 30 days (approximate)
        break;
      case 'none':
      default:
        recurrenceInterval = null; // Only one-time notification
        break;
    }

    // Set the initial notification to be sent after the delay
    setTimeout(async () => {
      // Send the notification (same as your existing notification send logic)
      try {
        const notificationData = targetAudience?.users?.map(user => ({
          fcmToken: user.deviceToken,
          ...createdNotification, // Include all the notification details for FCM
        }));

        const fcmPromises = notificationData.map(notification =>
          axios
            .post(`${process.env.REACT_APP_API}/notifications/fcm/send`, {
              title: notification.category + ': ' + notification.title,
              body: notification.message,
              type: notification.category,
              token: notification.fcmToken,
            })
            .then(result => {
              if (result.status === 200) {
                return { success: true, token: notification.fcmToken };
              } else {
                return {
                  success: false,
                  token: notification.fcmToken,
                  error: `Failed with status: ${result.status}`,
                };
              }
            })
            .catch(error => {
              return {
                success: false,
                token: notification.fcmToken,
                error: error.message,
              };
            })
        );

        const fcmResults = await Promise.all(fcmPromises);
        let successCount = 0;
        let failedCount = 0;
        const logs = [];

        fcmResults.forEach(result => {
          if (result.success) {
            successCount++;
            logs.push(
              `Notification sent successfully to token: ${result.token}`
            );
          } else {
            failedCount++;
            logs.push(
              `Failed to send notification to token: ${result.token} - Error: ${result.error}`
            );
          }
        });

        const updateResponse = await updateDeliveryReport(
          createdNotification._id,
          successCount,
          failedCount,
          logs,
          new Date(),
          'sent'
        );

        if (updateResponse.data.success) {
          toast.success(
            `Notification sent! ${successCount} sent, ${failedCount} failed.`
          );
        } else {
          toast.error('Failed to update notification in backend.');
        }

        // If recurrence is set, reschedule the notification
        if (recurrenceInterval) {
          const nextScheduleDate = new Date(
            scheduleDate.getTime() + recurrenceInterval
          );
          scheduleNotification(
            nextScheduleDate,
            createdNotification,
            recurrence
          ); // Recursively schedule the next notification
        }
      } catch (error) {
        toast.error('Error sending notifications: ' + error.message);
        console.error('Error sending notifications:', error.message);
      }
    }, delay);
  };

  // Function to update delivery report in the backend
  const updateDeliveryReport = async (
    notificationId,
    successCount,
    failedCount,
    logs,
    sentAt,
    status
  ) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/pushNotifications/updateDeliveryReport`,
        {
          notificationId,
          successCount,
          failedCount,
          logs,
          sentAt,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data.success) {
        console.log('Delivery report updated!');
        return response; // Ensure we return the response
      } else {
        console.error(
          'Failed to update delivery report:',
          response.data.message
        );
      }
    } catch (error) {
      console.error('Error updating delivery report:', error.message);
      throw error;
    }
  };

  return (
    <Card p="30px" w="100%" borderRadius="lg" boxShadow={cardShadow} {...rest}>
      <Text fontSize="2xl" fontWeight="bold" color={textColor} mb="20px">
        Créer une Notification
      </Text>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        spacing="10px"
        w="100%"
        align="flex-start"
      >
        {/* Form Section (Left) */}
        <Box
          w={{ base: '100%', md: '50%' }}
          p="20px"
          borderRadius="lg"
          boxShadow={cardShadow}
        >
          <Stack spacing="20px" w="100%">
            {/* Notification Category Select */}
            <Select
              placeholder="Catégorie de la notification"
              value={category}
              onChange={e => setCategory(e.target.value)}
              bg={cardColor}
              color={textColor}
              borderRadius="md"
              focusBorderColor="blue.500"
            >
              <option value="alert">Alerte</option>
              <option value="promotion">Promotion</option>
              <option value="update">Mise à jour</option>
            </Select>

            <Input
              placeholder="Titre"
              value={title}
              onChange={e => setTitle(e.target.value)}
              bg={cardColor}
              color={textColor}
              borderRadius="md"
              focusBorderColor="blue.500"
              _focus={{ boxShadow: '0 0 0 1px #3182ce' }}
            />

            <Textarea
              placeholder="Message"
              value={message}
              onChange={e => setMessage(e.target.value)}
              bg={cardColor}
              color={textColor}
              size="sm"
              borderRadius="md"
              focusBorderColor="blue.500"
              _focus={{ boxShadow: '0 0 0 1px #3182ce' }}
            />

            <Select
              placeholder="Audience cible"
              value={targetAudience === 'all' ? 'all' : targetAudience?.name} // Display the correct value of the selected audience
              onChange={e => {
                let selectedAudience;
                const selectedValue = e.target.value;
                if (selectedValue === 'all') {
                  setTargetAudience('all'); // Set the target to 'all' when 'all' is selected
                } else {
                  // Find the audience object based on the _id when a specific audience is selected
                   selectedAudience = audiences.find(
                    audience => audience.name === selectedValue
                  );
                  setTargetAudience(selectedAudience); // Set the full audience object as the target
                }
                console.log(
                  'targetAudience',
                  selectedValue === 'all' ? selectedValue : selectedAudience
                );
              }}
              bg={cardColor}
              color={textColor}
              borderRadius="md"
              focusBorderColor="blue.500"
            >
              {/* Predefined options */}
              <option value="all">Tous les utilisateurs</option>

              {/* Dynamically generated options from the audience array */}
              {audiences.map(audience => (
                <option key={audience._id} value={audience.name}>
                  {' '}
                  {/* Use _id as the value */}
                  {audience.name}
                </option>
              ))}
            </Select>

            <Select
              placeholder="Plateforme"
              value={platform}
              onChange={e => setPlatform(e.target.value)}
              bg={cardColor}
              color={textColor}
              borderRadius="md"
              focusBorderColor="blue.500"
            >
              <option value="all">Toutes les plateformes</option>
            </Select>

            <Flex align="center" justify="space-between">
              <Text color={textColor}>Envoyer plus tard</Text>
              <Switch
                colorScheme="brand"
                isChecked={schedule}
                onChange={() => setSchedule(!schedule)}
              />
            </Flex>

            {schedule && (
              <DatePicker
                selected={scheduleDate}
                onChange={date => setScheduleDate(date)}
                showTimeSelect
                dateFormat="Pp"
                customInput={<Input bg={cardColor} color={textColor} />}
                popperPlacement="bottom"
              />
            )}
            {schedule && (
              <Box>
                <Text fontSize="sm" color={textColor} mt="10px">
                  Fréquence d'envoi
                </Text>
                <Select
                  placeholder="Récurrence"
                  value={recurrence}
                  onChange={e => setRecurrence(e.target.value)}
                  bg={cardColor}
                  color={textColor}
                  borderRadius="md"
                  focusBorderColor="blue.500"
                >
                  <option value="none">Aucune</option>
                  <option value="daily">Quotidienne</option>
                  <option value="weekly">Hebdomadaire</option>
                  <option value="monthly">Mensuelle</option>
                </Select>
              </Box>
            )}

            <Flex justify="space-between" w="100%">
              <Button
                leftIcon={<Icon as={MdSave} />}
                colorScheme="gray"
                onClick={handleSaveDraft}
                borderRadius="md"
                variant="outline"
              >
                Enregistrer Brouillon
              </Button>

              <Button
                leftIcon={<Icon as={schedule ? MdSchedule : MdSend} />}
                variant={'brand'}
                onClick={handleSendNotification}
                borderRadius="md"
              >
                {schedule ? "Programmer l'envoi" : 'Envoyer maintenant'}
              </Button>
            </Flex>
          </Stack>
        </Box>

        {/* Preview Section (Right) */}
        <Box
          w={{ base: '100%', md: '50%' }}
          //p="20px"
          borderRadius="lg"
          //boxShadow={cardShadow}
          borderColor={cardColor}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          {/* Mobile Mockup */}
          <Box
            position="relative"
            w="100%"
            maxW="350px"
            h="500px"
            bg={cardColor}
            borderRadius="20px"
            overflow="hidden"
            boxShadow="0px 0px 15px rgba(0, 0, 0, 0.1)"
          >
            {/* Top Bar */}
            <Box
              h="50px"
              bg="gray.200"
              borderBottom="1px solid rgba(0,0,0,0.1)"
              borderTopRadius="20px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="sm" color="gray.600">
                Aperçu Android
              </Text>
            </Box>

            {/* Notification Content */}
            <Box
              p="15px"
              position="absolute"
              top="55px"
              left="10px"
              right="10px"
              borderRadius="10px"
              bg={cardColor}
              boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            >
              {/* Notification Header with Icon */}
              <Flex alignItems="center" mb="8px">
                <Image src={Jindoo} w={6} h={6} borderRadius="7px" mr="10px" />

                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  {title || 'Titre de la Notification'}
                </Text>
              </Flex>

              {/* Notification Message */}
              <Text fontSize="md" color={textColor}>
                {message || 'Contenu du message de notification...'}
              </Text>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Card>
  );
}
