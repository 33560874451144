import {
  Text,
  Box,
  Grid,
  Flex,
  useColorModeValue,
  Button,
  Icon,
} from '@chakra-ui/react';
import { t } from 'helpers/TransWrapper';
import { NavLink, useHistory } from 'react-router-dom';

// Custom components
import Banner from 'views/admin/profile/components/Banner';
import BannerShop from './components/BannerShop';
import General from 'views/admin/profile/components/General';
import Notifications from 'views/admin/profile/components/Notifications';
import Projects from 'views/admin/profile/components/Projects';
import Storage from 'views/admin/profile/components/Storage';
import Upload from 'views/admin/profile/components/Upload';
import Followers from 'views/admin/profile/components/Followers';
import Following from 'views/admin/profile/components/Following';
import { useColorMode } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
// Assets
import bannerShop from 'assets/img/shopBanner.png';
import Card from 'components/card/Card.js';

import banner from 'assets/img/auth/buySell.jpg';
import bannerSeller from 'assets/img/auth/sell.jpg';
import bannerBuyer from 'assets/img/auth/buy.jpg';
import avatar from 'assets/img/avatars/avatar4.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdStore } from 'react-icons/md'; // Import the icon you want to use
import { Information } from 'views/admin/profile/components/Information';
import { Dropzone } from 'views/admin/profile/components/Dropzone';
const user = JSON.parse(localStorage.getItem('user-token'));
const PremiumAccessOverlay = () => {
  const subscribeText =
    "Pour créer votre boutique, abonnez-vous dès aujourd'hui.";
  const history = useHistory();

  const handleSubscribe = () => {
    // Functionality for subscribing goes here
    // You can add redirection, API calls, or any necessary logic
    history.push('/admin/memberships');
  };

  return (
    <Box position="relative" borderRadius="15px">
      {/* Your existing BannerShop component */}
      <BannerShop
        banner={bannerShop}
        avatar="sample-avatar-url"
        name="Sample Shop Name"
        userId="sample-user-id"
        createdAt="2023-01-01"
      />

      {/* Overlay with stripes */}
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        borderRadius="15px"
        overflow="hidden"
      >
        <Box
          position="absolute"
          width="50%"
          height="50%"
          top="-25%"
          left="-25%"
          transform="rotate(-25deg)"
          background="linear-gradient(45deg, rgba(255, 255, 255, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent)"
        />
        <Flex
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.7)"
          alignItems="center"
          justifyContent="center"
          borderRadius="15px"
        >
          {/* Overlay Content */}
          <Card
            w={400}
            p={6}
            borderRadius="10px"
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
          >
            {/* Text */}
            <Text fontSize="lg" textAlign="center" mb={4}>
              {subscribeText}
            </Text>

            {/* Subscribe Button */}
            <Button
              colorScheme="yellow"
              onClick={handleSubscribe}
              boxShadow="0px 4px 8px rgba(255, 179, 0, 0.5)"
            >
              {t(`S'abonner`)}
            </Button>
          </Card>
        </Flex>
      </Box>
    </Box>
  );
};
export default function Overview() {
  const [count, setCount] = useState([]);
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [followersCount, setFollowersCount] = useState();
  const [followingCount, setFollowingCount] = useState();
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shop, setShop] = useState('');
  const [owner, setOwner] = useState('');
  const history = useHistory();
  // const fetchFollowers = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API}/users/${currentUser._id}/followers`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('token')}`,
  //         },
  //       }
  //     );
  //     const followers = response.data;
  //     setFollowersCount(followers.length);

  //     setFollowers(followers);
  //
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchFollowers();
  // }, [currentUser._id]);
  // const fetchFollowing = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API}/users/${currentUser._id}/following`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('token')}`,
  //         },
  //       }
  //     );
  //     const following = response.data;
  //     setFollowingCount(following.length);
  //
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchFollowing();
  // }, [currentUser._id]);
  const fetchShopById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/${currentUser.idShop}`
      );
      const shopData = response.data;
      console.log('shopData', shopData);
      if (shopData || shopData.length > 0) {
        setShop(shopData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchShopById();
  }, [currentUser.idShop]);
  useEffect(() => {
    const fetchOwner = async () => {
      try {
        if (shop && shop.owner) {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/users/${shop.owner}`
          );
          const owner = response.data;
          setOwner(owner);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOwner();
  }, [shop]);
  const fetchUserAds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${currentUser._id}/count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setCount(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserAds();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const handleCreateShop = () => {
    history.push('/shop/create');
  };
  const items = Array.from({ length: 1 });

  const { colorMode } = useColorMode();
  const reviews = shop?.reviews || [];
  const positiveReviews = reviews.filter(review => review.rating >= 4);
  const totalReviews = reviews.length;
  const totalPositiveReviews = positiveReviews.length;

  // Calculate positive reviews percentage, considering the possibility of division by zero
  const positiveReviewsPercentage =
    totalReviews === 0 ? 'N/A' : (totalPositiveReviews / totalReviews) * 100;
  const bgColor = colorMode === 'dark' ? 'rgb(17, 28, 68)' : 'gray.200';
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {' '}
      {/* Main Fields */}{' '}
      <Grid
        mb={{ base: '20px', md: '0', xl: '0' }}
        templateColumns={{
          base: '1fr',
          lg: '1.5fr 1.25fr 1.25fr',
        }}
        templateRows={{
          base: 'repeat(3, 1fr)',
          lg: '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        {isLoading === false ? (
          <Banner
            gridArea="1 / 1 / 1 / 2"
            banner={
              user.UserType.includes('Vendre') &&
              user.UserType.includes('Acheter')
                ? banner
                : user.UserType.includes('Acheter')
                ? bannerBuyer
                : user.UserType.includes('Vendre')
                ? bannerSeller
                : ''
            }
            avatar={user.profilePicture}
            name={user.firstName + ' ' + user.lastName}
            job={user.email}
            propart={user.ProPart}
            posts={count.adCount}
            nbrAds={user.nbrAds}
          />
        ) : (
          <Box
            borderRadius="15px"
            overflow="hidden"
            bg={bgColor}
            position="relative"
            mb="20px"
          >
            <Skeleton
              height={{ base: '200px', md: '250px' }}
              width="420px"
              startColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
              endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
            />
            <Box
              position="absolute"
              top="40%"
              left="50%"
              transform="translate(-50%, -50%)"
            >
              <Skeleton
                isLoaded={!isLoading}
                borderRadius="full"
                height="150px"
                width="150px"
              ></Skeleton>
            </Box>
            <Box p="6">
              <Box
                mt="6"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
                color={textColor}
              >
                <Skeleton
                  height="10px"
                  width={{ base: '50%', md: '30%' }}
                  mb={2}
                  startColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
                  endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                />
                <Box bg={bgColor} height="20px" width="50%" mb={2}></Box>
                <Skeleton
                  height="10px"
                  width={{ base: '20%', md: '10%' }}
                  mr={2}
                  startColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
                  endColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                />
              </Box>
              <Box
                d="flex"
                alignItems="baseline"
                color={textColor}
                fontSize="sm"
              >
                <Box bg={bgColor} height="10px" width="20%" mr={2}></Box>
                <Box bg={bgColor} height="10px" width="30%" mr={2}></Box>
              </Box>
              <Box d="flex" alignItems="baseline">
                <Button
                  isLoading
                  loadingText={t('Modifier mon profile...')}
                  height="40px"
                  width="100%"
                  mb={2}
                  bg="rgb(25, 7, 146)"
                  color="white"
                />
                <Button
                  isLoading
                  loadingText={t('Se déconnecter...')}
                  height="40px"
                  width="100%"
                  colorScheme="red"
                />
              </Box>
            </Box>
          </Box>
        )}
        <Followers
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
          currentUserId={currentUser._id}
        />{' '}
        {/* <Storage
                                  gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
                                  used={25}
                                  total={50}
                                /> */}{' '}
        <Following
          gridArea={{
            base: '3 / 1 / 4 / 2',
            lg: '1 / 3 / 2 / 4',
          }}
          currentUserId={currentUser._id}
        />
      </Grid>
      <Grid
        mb="15px"
        templateColumns={{
          base: '1fr',
          lg: '1.5fr 1.5fr',
        }}
        templateRows={{
          base: 'repeat(2, 1fr)',
          lg: '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        <Storage />
        {!shop || currentUser.idShop === '' ? (
          <>
            {currentUser.memberShip === 'Gratuit' || currentUser.memberShip === '' ? (
              <PremiumAccessOverlay />
            ) : (
              <Card
                mb={{ base: '0px', lg: '5px' }}
                justifyContent="center"
                align="center"
                shadow="lg"
              >
                <Box
                  p={0}
                  bg={`url(${bannerShop})`}
                  bgSize="cover"
                  borderRadius="15px"
                  h="160px"
                  w="100%"
                  mb={7}
                />
                <Button
                  variant="darkBrand"
                  size="md"
                  onClick={handleCreateShop}
                >
                  {t('Créer ma boutique')}
                </Button>
              </Card>
            )}
          </>
        ) : (
          <BannerShop
          shopId={shop?._id}
            avatar={shop?.logo}
            name={shop?.name}
            userId={shop?.owner}
            banner={bannerShop}
            memberShip={owner?.memberShip}
            positiveReviews={positiveReviewsPercentage}
            createdAt={shop?.createdAt}
            visibility={shop?.visibility}
          />
        )}
      </Grid>
      <Projects />
    </Box>
  );
}
