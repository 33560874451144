// LinkedInCallback.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Spinner, Box, Center, Text } from '@chakra-ui/react';

const LinkedInCallback = () => {
  const history = useHistory(); // Get the history object for redirection
  const [loading, setLoading] = useState(true); // Loading state

  const handleCallback = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/auth/oauth/linkedin/callback`, {
          code,
          redirectUri: 'https://jindoo.ma/auth/linkedIn-callback',
          isMobile: false,
        });

        const { token, user } = response.data;

        // Save the token and user data
        localStorage.setItem('token', token);
        console.log('User logged in:', user);
        const currentUser = user;
        localStorage.setItem('user-token', JSON.stringify(currentUser));

        // Redirect to the home page
        history.push('/');
        
      } catch (error) {
        console.error('LinkedIn login error:', error);
      } finally {
        setLoading(false); // Set loading state to false once done
      }
    } else {
      setLoading(false); // Set loading state to false if no code
    }
  };

  // Trigger callback handling after component mount
  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <Center height="100vh">
      {loading ? (
        <Box textAlign="center">
          <Spinner size="xl" color="teal.500" />
          <Text mt={4}>Logging in...</Text>
        </Box>
      ) : (
        <Box textAlign="center">
          <Text>Login failed or completed successfully. Redirecting...</Text>
        </Box>
      )}
    </Center>
  );
};

export default LinkedInCallback;
